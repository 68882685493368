import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import { __ } from "services/Translator";
import JackpotTicker from "elements/JackpotTicker";

const GameItem = ({ game, isAuthenticated, className, gameType, index, isMobileDevice }) => {
  // const __ = (text) => text;
  const [visible, setVisible] = useState(true);
  const defaultImage = require("assets/images/logos/game-loader.gif").default;
  const [gameSnapshotUrl, setGameSnapshotUrl] = useState(defaultImage);
  const [processed, setProcessed] = useState(false);
  // console.log('processedprocessed', processed)
  const hideNoImageFoundGames = () => {
    // setVisible(false);
  };

  const [isFavorite, setIsFavorite] = useState(game.is_favorite_game);

  const loadGameImage = () => {
    setGameSnapshotUrl(game.game_snapshot_url);
  };

  const toggleFavoriteGame = async () => {
    var response = await api.post("/toggle-favorite-game", { gameId: game.id });
    if (response.status === 200) {
      if (response.data.status === "added") {
        setIsFavorite(true);
      } else {
        setIsFavorite(false);
      }
    }
  };

  useEffect(() => {
    setVisible(true);
    setGameSnapshotUrl(defaultImage);
    setIsFavorite(game.is_favorite_game);
    setProcessed(false);
  }, [game.id, isAuthenticated]);

  const fixImageRatio = (event) => {
    if (!processed) {
      setGameSnapshotUrl(game.game_snapshot_url);
      var img = event.target;
      console.log(img.width);
      var width = img.width;
      var height = width * 1;
      img.height = height;
      setTimeout(() => {
        //Sometimes the width is read wrong the first time
        //This is the fallback to fix it
        var width = img.width;
        var height = width * 1;
        img.height = height;
      }, 1000);
      setProcessed(true);
    }
  };

  const [imageSelection, setImageSelection] = useState(0);
  useEffect(() => {
    if (gameType == "custom") {
      getBigImage();
    }
  }, [gameType]);

  const [bigImage, setBigImage] = useState(null);

  const getBigImage = async (id) => {
    // alert(isMobileDevice)
    var image = 0;
    if (index == 0) {
      image = 0;
    } else if (index == 6) {
      image = 1;
    } else if (index == 11) {
      image = 2;
    }
    var response = await api.get("/get-big-game-image", {
      imageSelection: image,
    });
    if (response.status === 200) {
      // const game = response;
      setBigImage(response.data.bigGames);
      setImageSelection(imageSelection + 1);
    }
    console.log("getBigImage", response);
  };

  const BigGameImage = ({ game }) => {
    return (
      <>
        <div className={"single_game  " + (visible ? "" : "d-none") + (" " + (className ? className : ""))}>
          <div className="games_image">
            <img src={bigImage.image} onError={hideNoImageFoundGames} onLoad={fixImageRatio} />
            <a
              className="common_btn_v1 fit_content small game_play_button"
              href={bigImage.link + (isAuthenticated ? "" : "/demo")}
            >
              {__("Play")}
            </a>
            {!!bigImage.gameData?.rtp && <div className="rtp_amount">{bigImage.gameData?.rtp}% RTP</div>}
          </div>
          <div className="game_overlay"></div>
        </div>
      </>
    );
  };
  const RegularGameImage = ({ game }) => {
    return (
      <>
        <div className={"single_game  " + (visible ? "" : "d-none")}>
          <div className="games_image">
            <img src={gameSnapshotUrl} onError={hideNoImageFoundGames} onLoad={fixImageRatio} />
            {game.has_jackpot != 0 && game.jackpot_amount != 0 && (
              <div className="jackpot_amount">
                <JackpotTicker width={15} height={20} jackpotAmount={game.jackpot_amount_not_formatted} />
              </div>
            )}
            {!!game.rtp && <div className="rtp_amount">{game.rtp}% RTP</div>}
            <Link
              className="common_btn_v1 fit_content small game_play_button"
              to={"/play/" + game.slug + (isAuthenticated ? "" : "/demo")}
            >
              {__("Play")}
            </Link>
          </div>
          <div className="game_name">{game.game_name}</div>
          <div className="provider_name">{game.provider?.name ?? "N/A"}</div>

          <div className="games_rating">
            <div className="star">
              {/* RTP:{" "}
          <span className="white">{game.rtp ? game.rtp + "%" : "N/A"}</span> */}
              {isAuthenticated && (
                <div onClick={toggleFavoriteGame} className="games_badge">
                  <i className={(isFavorite ? "fas" : "far") + " fa-heart"}></i>
                </div>
              )}
            </div>
            {/* <div className="view">
          <i className="fas fa-eye"></i>{" "}
          <span className="count">{game.views}</span>
        </div> */}

            {/* <div className="provider_name">{game.provider?.name ?? "N/A"}</div> */}
          </div>
          <div className="game_overlay"></div>
        </div>
      </>
    );
  };

  return (
    <>
      {gameType == "custom" && (
        <>
          {index === 11 ? (
            <>
              <RegularGameImage game={game} />
              {!isMobileDevice && bigImage && <BigGameImage game={game} />}
            </>
          ) : (
            <>
              {!isMobileDevice && bigImage && <BigGameImage game={game} />}
              <RegularGameImage game={game} />
            </>
          )}
        </>
      )}

      {gameType == "regular" && <RegularGameImage game={game} />}
    </>
  );
};

export default GameItem;
