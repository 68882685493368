import React, { useEffect, useState } from "react";
import { Button, Modal, Table, OverlayTrigger, Tooltip } from "react-bootstrap";

import { Link } from "react-router-dom";
import NoRecordsFound from "elements/NoRecordsFound";
import api from "services/api";
import moment from "moment";
import DOMPurify from "dompurify";
import { __ } from "services/Translator";
import RemainintTime from "elements/RemainingTime";

export default function Raffles(props) {
  const [showDetails, setShowDetails] = useState(false);
  const [campaignTab, setCampaignTab] = useState("active");

  const [campaigns, setCampaigns] = useState([]);
  const [campaignDetails, setCampaignDetails] = useState("");

  const getCampaigns = async (status) => {
    var response = await api.get("/rewards/raffle-campaigns/" + status);
    if (response.status === 200) {
      setCampaigns(response.data?.campaigns);
    }
  };

  useEffect(() => {
    getCampaigns(campaignTab);
  }, [campaignTab]);

  const renderHTML = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(rawHTML) },
    });

  const openDetails = async (campaign) => {
    setShowDetails(true);
    setCampaignDetails(campaign);
  };

  const st = (position) => {
    switch (position) {
      case 1:
        return "1st";
      case 2:
        return "2nd";
      case 3:
        return "3rd";
      default:
        return position + "nd";
    }
  };

  return (
    <>
      <section className="rakeback_block raffle_block">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="title">{__("Raffle Tickets")}</div>
            <div className="dsc">
              {__(
                "Raffle tickets are rewarded against deposits. The more you deposit, the more you will receive tickets. At the end of the campaign, winners will be picked by random selection."
              )}
            </div>
          </div>
        </div>
        <br />

        <div className="container-md">
          <section className="winner_section margin_bottom_0 d-flex justify-content-center">
            <div className="d-flex justify-content-center" style={{ width: 200 }}>
              <Link
                onClick={() => {
                  setCampaignTab("active");
                }}
                className={campaignTab === "active" ? "common_btn medium" : "common_btn gray medium"}
              >
                {__("In Progress")}
              </Link>
              <Link
                onClick={() => {
                  setCampaignTab("inactive");
                }}
                className={campaignTab === "inactive" ? "common_btn medium" : "common_btn gray medium"}
              >
                {__("Ended")}
              </Link>
            </div>
          </section>

          <div className="raffle_list promotions">
            {campaigns.length ? (
              <>
                {campaigns.map((item, index) => (
                  <section className="promotions_banner_v1">
                    <div className="banner_img">
                      <img
                        className="promotions_banner_img desktopBanner"
                        src={require("assets/images/v1/races/banner-bg.svg").default}
                        alt="Promotions Banner"
                      />
                      <img
                        className="promotions_banner_img mobileBanner"
                        src={require("assets/images/v1/races/race-item-bg-mobile.png").default}
                        alt="Promotions Banner"
                      />
                    </div>
                    <div className="races_content">
                      <h1 className="banner_heading">{item.lang?.title}</h1>
                      <p style={{ color: "white" }}>{item.lang?.campaign_information}</p>
                      <div className="races_conut_down">
                        <RemainintTime timestamp={item.ending_in} />
                      </div>
                      <Link
                        onClick={() => {
                          openDetails(item);
                        }}
                        className="common_btn_v1"
                      >
                        {__("View Details")}
                      </Link>
                    </div>
                  </section>
                ))}
              </>
            ) : (
              <>
                <br />
                {campaignTab === "active" ? (
                  <NoRecordsFound
                    relativePosition={true}
                    content={__(
                      "No raffle ticket campaign is live at this moment. Don't forget to check back this page and our promotions page."
                    )}
                  />
                ) : (
                  <NoRecordsFound relativePosition={true} content={__("No past raffle ticket campaigns found.")} />
                )}
              </>
            )}
          </div>
        </div>
      </section>
      <Modal size="lg" show={showDetails}>
        <Modal.Body className="cashier_modal">
          <div
            onClick={() => {
              setShowDetails(false);
            }}
            className="modal_close_reg"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.68964 12.2073L0.9655 4.78928C0.551706 4.39188 0.275845 3.79579 0.275845 3.1997C0.275845 2.60361 0.551706 2.00752 0.9655 1.61012C1.86205 0.7491 3.37929 0.7491 4.27584 1.61012L12 9.02815L19.7241 1.61012C20.6207 0.7491 22.1379 0.7491 23.0345 1.61012C23.4483 2.00752 23.7241 2.60361 23.7241 3.1997C23.7241 3.79579 23.4483 4.39188 23.0345 4.78928L15.3103 12.2073L23.0345 19.6253C23.4483 20.0227 23.7241 20.6188 23.7241 21.2149C23.7241 21.811 23.4483 22.4071 23.0345 22.8045C22.6207 23.2019 22 23.4668 21.3793 23.4668C20.7586 23.4668 20.1379 23.2019 19.7241 22.8045L12 15.3865L4.27584 22.8045C3.37929 23.6655 1.86205 23.6655 0.9655 22.8045C0.551706 22.4071 0.275845 21.811 0.275845 21.2149C0.275845 20.6188 0.551706 20.0227 0.9655 19.6253L8.68964 12.2073Z"
                fill="#10294F"
              />
            </svg>
          </div>

          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(campaignDetails?.lang?.description),
              }}
            ></div>

            <h5>{__("Your tickets")}</h5>

            <Table className="tickets">
              <tr>
                <th>Ticket</th>
                <th>Result</th>
              </tr>
              {!!campaignDetails.tickets?.length &&
                campaignDetails.tickets.map((item, index) => (
                  <tr key={index}>
                    <td>{item.ticket}</td>
                    <td>{item.win ? st(item.win.winner_place) + " prize" : ""}</td>
                  </tr>
                ))}
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
