import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { __ } from "services/Translator";

const HomeBanner = (props) => {
  // const menuOpen = props.menuOpen;
  const [searchVal, setSearchVal] = useState("");

  const updateInput = (ev) => {
    var target = ev.target;
    // var field = target.name;
    var value = target.value;
    setSearchVal(value);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 10000,
  };

  const [bannerPath, setBannerPath] = useState("");

  return (
    <>
      <section className="header_banner_area promotion_banner">
        <Slider {...settings}>
          <div className="single_banner_area">
            <img
              className="banner_bg desktop_banner"
              src={
                require("../assets/images/banners/promotion-banner-one-desktop.png")
                  .default
              }
            />

            <img
              className="banner_bg tab_banner"
              src={
                require("../assets/images/banners/promotion-banner-one-tab.png")
                  .default
              }
            />

            <img
              className="banner_bg mobile_banner"
              src={
                require("../assets/images/banners/promotion-banner-one-mobile.png")
                  .default
              }
            />

            <div className="container-md d-none">
              <div className="banner_text_section ">
                <div className="banner_headline">{__("Welcome Offer")}</div>
                <div className="banner_headline_colored">
                  100% <span>{__("Welcome Offer")}</span> 1BTC
                </div>
                <div className="banner_description">
                  {__("T&C Applies, read bonus terms for more details")}
                </div>

                {props.isAuthenticated ? (
                  <Link
                    to="/games/live-casino"
                    onClick={() => {
                      props.setModalSignUpPropsData(true);
                      setTimeout(function () {
                        props.setModalSignUpPropsData(false);
                      }, 500);
                    }}
                    className="common_btn float-left"
                  >
                    {__("Play Now!")}
                  </Link>
                ) : (
                  <>
                    <Link
                      to=""
                      onClick={() => {
                        props.setModalSignUpPropsData(true);
                        setTimeout(function () {
                          props.setModalSignUpPropsData(false);
                        }, 500);
                      }}
                      className="common_btn float-left"
                    >
                      {__("Play Now!")}
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
          {/*<div className="single_banner_area">
            <img
              className="banner_bg desktop_banner"
              src={
                require("../assets/images/banners/promotion-banner-two-desktop.png")
                  .default
              }
            />

            <img
              className="banner_bg tab_banner"
              src={
                require("../assets/images/banners/promotion-banner-two-tab.png")
                  .default
              }
            />

            <img
              className="banner_bg mobile_banner"
              src={
                require("../assets/images/banners/promotion-banner-two-mobile.png")
                  .default
              }
            />

            <div className="container d-none">
              <div className="banner_text_section ">
                <div className="banner_headline">{__("Second Deposit")}</div>
                <div className="banner_headline_colored">
                  50% <span>{__("UP TO")}</span> 1.2BTC
                </div>
                <div className="banner_headline_colored" style={{fontSize: '26px'}}>
                  <span>+</span> 50 Freespins
                </div>
                

                {props.isAuthenticated ? (
                  <Link
                    to="/games/live-casino"
                    onClick={() => {
                      props.setModalSignUpPropsData(true);
                      setTimeout(function () {
                        props.setModalSignUpPropsData(false);
                      }, 500);
                    }}
                    className="common_btn float-left"
                  >
                    {__("Play Now!")}
                  </Link>
                ) : (
                  <>
                    <Link
                      to=""
                      onClick={() => {
                        props.setModalSignUpPropsData(true);
                        setTimeout(function () {
                          props.setModalSignUpPropsData(false);
                        }, 500);
                      }}
                      className="common_btn float-left"
                    >
                      {__("Play Now!")}
                    </Link>
                  </>
                )}
                <div className="clearfix"></div>
                <div className="banner_description">
                  {__("T&C Applies, read bonus terms for more details")}
                </div>
              </div>
            </div>
                    </div>*/}
        </Slider>
      </section>
    </>
  );
};

export default HomeBanner;
