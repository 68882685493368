import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Dropdown, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import AppContext from "contexts/AppContext";
import auth from "services/auth";
import { __ } from "services/Translator";
import moneyFormatter from "services/moneyFormatter";
import LeftSidebarMobile from "elements/LeftSidebarMobile";
import LeftSidebarDesktop from "elements/LeftSidebarDesktop";
import ls from "services/ls";

export default function LeftSidebar(props) {
  const params = useParams();
  const { updateUserObj } = React.useContext(AppContext);
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [showSidebar, setShowSidebar] = useState(false);

  const logout = async () => {
    await auth.logout();
    await updateUserObj();
    setIsAuthenticated(auth.isAuthenticated);
  };

  useEffect(() => {
    if (props.showLeftSidebar) {
      setShowSidebar(props.showLeftSidebar);
    } else {
      setShowSidebar(props.showLeftSidebar);
    }
  }, [props]);

  const [showBalanceAsFiat, setShowBalanceAsFiat] = useState(
    ls.get("showBalanceAsFiat") ? ls.get("showBalanceAsFiat") : false
  );

  const [fiatCurrencyToDisplay, setFiatCurrencyToDisplay] = useState(
    ls.get("fiatCurrencyToDisplay") ? ls.get("fiatCurrencyToDisplay") : "USD"
  );

  useEffect(() => {
    setShowBalanceAsFiat(ls.get("showBalanceAsFiat") ? ls.get("showBalanceAsFiat") : false);
    setFiatCurrencyToDisplay(ls.get("fiatCurrencyToDisplay") ? ls.get("fiatCurrencyToDisplay") : "USD");
  }, [props.trigger]);

  return (
    <>
      <div className="sidebar_container left_sidebar menu_left_bar ">
        <div
          className={
            "sidebar_area menu_left_bar  " +
            (showSidebar === true
              ? "show_bar extendedMenu"
              : (props.isMobileDevice || !props.isAuthenticated ? "" : "") + " ")
          }
        >
          <div className="button_close">
            {/* <svg
              width="28"
              height="28"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>close</title>
              <path d="M18.984 6.422L13.406 12l5.578 5.578-1.406 1.406L12 13.406l-5.578 5.578-1.406-1.406L10.594 12 5.016 6.422l1.406-1.406L12 10.594l5.578-5.578z"></path>
            </svg> */}
            {/* <img
              alt=""
              className="maximizeSidebar"
              src={
                require("../assets/images/common/maximize-sidebar-icon.png")
                  .default
              }
              onClick={() => {
                setShowSidebar(true);
                props.setShowLeftSidebar(true);
              }}
            /> */}
            {props.isMobileDevice && (
              <img
                alt=""
                className="minimizeSidebar"
                src={require("../assets/images/common/minimize-sidebar-icon.png").default}
                onClick={() => {
                  // setShowSidebar(false);
                  props.setShowLeftSidebar(false);
                }}
              />
            )}
          </div>

          <div className="left_side">
            <div className="logo">
              <Link to="/">
                <img
                  src={require("../assets/images/logos/blizz-casino-logo-gradient.svg").default}
                  alt="Blizz Casino Logo"
                />
              </Link>
            </div>
            {props.isMobileDeviceByViewPort ? <LeftSidebarMobile {...props} /> : <LeftSidebarDesktop {...props} />}
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}
