import React, { useRef } from "react";

export default function CheckBox({ children, type, ...rest }) {
  const newType = type ? type : "checkbox";

  return (
    <div className="checking">
      <div className="checkbox_with_text">
        <div className="checkbox_container">
          <label>
            <input type={newType} {...rest} />
            {newType === "checkbox" ? (
              <>
                <span className="checked fa fa-check-square"></span>
                <span className="unchecked fa fa-square"></span>
              </>
            ) : (
              <>
                <span className="checked fa fa-circle-check"></span>
                <span className="unchecked fa fa-circle"></span>
              </>
            )}
          </label>
        </div>
        <div className="children">{children}</div>
        <div className="clearfix"></div>
      </div>
    </div>
  );
}
