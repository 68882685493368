import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link, useLocation } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import config from "configs/config";
import api from "services/api";
import moneyFormatter from "services/moneyFormatter";
import { isMobile, cauculateLandScape } from "services/isMobile";
import VipLevelText, { VipIcon, NextVipLevelText } from "elements/VipLevelText";
import { __ } from "services/Translator";

const RightSidebar = (props) => {
  const { updateUserObj } = React.useContext(AppContext);
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const userBalance = props.userBalance;
  const userDetails = props.userDetails;
  const accountProgress = props.accountProgress;
  const location = useLocation();
  const notificationCount = props.notificationCount;

  // const __ = (text) => text;

  const logout = async () => {
    await auth.logout();
    await updateUserObj();
    setIsAuthenticated(auth.isAuthenticated);
  };

  const [collapsesOpen, setCollapsesOpen] = useState({
    progress: false,
    userMenu: true,
  });

  const openVault = () => {
    hideRightSidebar();
    props.setShowVaultModal(true);
  };

  const toggleCollapse = (ev) => {
    var id = ev.target.dataset.collapse;
    setCollapsesOpen({ ...collapsesOpen, [id]: !collapsesOpen[id] });
  };

  const hideRightSidebar = (ev) => {
    if (props.rightSidebarOpen) {
      props.toggleRightSidebar();
    }
  };

  useEffect(() => {
    hideRightSidebar();
  }, [location.pathname]);

  return isAuthenticated ? (
    <>
      {props.rightSidebarOpen && (
        <div
          onClick={() => hideRightSidebar()}
          className="invisible_overlay"
        ></div>
      )}
      <nav
        id="left_sidebar"
        className={
          "logged_in_sidebar " + (props.rightSidebarOpen ? "active" : "")
        }
      >
        <div id="left_dismiss" onClick={props.toggleRightSidebar}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </div>
        {/* <div className="side_bar_menu_text">{__("Profile")}</div> */}
        <ul className="account_menu">
          {/* <li className="single_slide_menu_item">
            {__("Total Balance")} :
            <span className="total_balance">
              {" " + userBalance.currency + userBalance.total_balance}
            </span>
            <span className="caret"></span>
          </li> */}
          {/* <li>
            {__("Cash")} :
            <span>
              <span className="cash_balance">
                {" " + userBalance.currency + userBalance.cash_balance}
              </span>
            </span>
          </li>
          <li>
            {__("Bonuses")} :
            <span>
              <span className="bonus_balance">
                {" " + userBalance.currency + userBalance.bonus_balance}
              </span>
            </span>
          </li> */}

          <li className="player_vip_level_info hasDropdownMenu">
            <a
              className="has_menu_action"
              href="#"
              data-collapse="progress"
              onClick={toggleCollapse}
            >
              <VipIcon identity={userDetails.identity} noFadedIcons={true} />{" "}
              {__("VIP Level")} <VipLevelText identity={userDetails.identity} />
              <span className="caret"></span>
              <div className="pbar">
                <div className="progress">
                  <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    style={{ width: accountProgress + "%" }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              {/* <i className="icon fas fa-angle-down"></i> */}
            </a>
            <Collapse in={collapsesOpen.progress}>
              <ul className="next_vip_level">
                <li>
                  {__("Next VIP Level")}:{" "}
                  <NextVipLevelText identity={userDetails.identity} /> (
                  {parseInt(accountProgress)}%)
                </li>
              </ul>
            </Collapse>
          </li>

          <li className={"notification_icon_container "}>
            <Link
              to="#"
              onClick={() => props.setIsChatVisible(true)}
              className="notification_icon"
            >
              <i className="far fa-comments"></i>
            </Link>
          </li>
          <li className={"notification_icon_container "}>
            <Link
              to="/dashboard/notifications"
              className={
                "notification_icon " + (!!notificationCount ? "active" : "")
              }
            >
              <i className="far fa-bell"></i>
              <span className="notifiction_counter">{notificationCount}</span>
            </Link>
          </li>

          <li className="hasDropdownMenu">
            {/* <a
              className="has_menu_action"
              href="#"
              data-collapse="userMenu"
              onClick={toggleCollapse}
            >
              {userDetails.first_name} {userDetails.last_name}
              {collapsesOpen.userMenu ? (
                <i className="icon fas fa-angle-up"></i>
              ) : (
                <i className="icon fas fa-angle-down"></i>
              )}
            </a> */}
            <Collapse in={collapsesOpen.userMenu}>
              <ul>
                <li>
                  <Link to="/dashboard/account-details">
                    {__("My Account")}
                  </Link>
                </li>
                <li>
                  <Link to="/dashboard/cashier">{__("Deposit")}</Link>
                </li>
                <li>
                  <Link to="/dashboard/cashier">{__("Withdraw")}</Link>
                </li>
                <li>
                  <Link to="/dashboard/my-games">{__("My Games")}</Link>
                </li>
                <li>
                  <Link to="#" onClick={openVault}>
                    {__("Vault")}
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>

          <li className="single_slide_menu_item">
            <a onClick={logout} href="#">
              {__("Log Out")}
            </a>
            <i className="icon fas fa-sign-out-alt"></i>
          </li>
        </ul>
      </nav>
    </>
  ) : (
    <></>
  );
};

export default RightSidebar;
