import { Accordion } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import SelectBox from "elements/SelectBox";
import InputBox from "elements/InputBox";
import Pagination from "elements/Pagination";
import DateSelector from "elements/DateSelector";
import NoRecordsFound from "elements/NoRecordsFound";
import config from "configs/config.js";
import api from "services/api";
import auth from "services/auth";
import { Overlay, Tooltip } from "react-bootstrap";
import { __ } from "services/Translator";

export default function ReferAndEarn(props) {
  const defaultForm = {
    page: 1,
    triggerSearch: true,
  };
  const [userDetails, setUserDetails] = useState(auth.getUser);
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    props.setSpinner(true);
    var response = await api.get("/get-referred-player");
    props.setSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }    
  };

  useEffect(() => {
    getResults();
  }, []);

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  const [appUrl, setAppUrl] = useState(config.appUrl);
  const [referralUrl, setReferralUrl] = useState(
    appUrl + "/refer/" + userDetails.referralCode
  );

  const [show, setShow] = useState(false);
  const target = useRef(null);
  const copyReferralUrl = () => {
    var copyText = document.getElementById("referralUrl");
    // var copyText = document.getElementById("myInput");

    /* Select the text field */
    // copyText.select();
    // copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);

    setShow(true);
    const timer = setTimeout(() => {
      setShow(false);
    }, 2000);
    return () => clearTimeout(timer);
  };

  return (
    <div className="dashboard_content history_area_full referal_area">
      <div className="right_area table_area">
        <div className="left_area">
          <h3 className="tabName">{__("Refer And Earn")}</h3>
        </div>

        <div className="referal_url_area">
          <div className="input_label">{__("Copy URL and send it to friends")}</div>
          <div className="copy_url_area">
            <input
              className="form-controll input_box"
              type="text"
              readonly="readonly"
              name="referralUrl"
              id="referralUrl"
              value={referralUrl}
            />
            <a
              className="in_input_icon"
              onClick={copyReferralUrl}
              href="javascript:"
              ref={target}
              // onClick={() => setShow(!show)}
            >
              <i class="fas fa-copy"></i>
            </a>
            <Overlay target={target.current} show={show} placement="top">
              {(props) => (
                <Tooltip id="overlay-example" className="tooltip" {...props}>
                  {__("Copied")}
                </Tooltip>
              )}
            </Overlay>
          </div>
        </div>
        {/* <div className="refer_label">Copy URL and send it to friends</div>
        <div className="refer_copy_input">
          <input
            type="text"
            readonly="readonly"
            name="referralUrl"
            id="referralUrl"
            value={referralUrl}
          />
          <a
            className="in_input_icon"
            onClick={copyReferralUrl}
            href="javascript:"
            ref={target}
            // onClick={() => setShow(!show)}
          >
            <i class="fas fa-copy"></i>
          </a>
          <Overlay target={target.current} show={show} placement="top">
            {(props) => (
              <Tooltip id="overlay-example" className="tooltip" {...props}>
                Copied
              </Tooltip>
            )}
          </Overlay>
        </div> */}

        <div className="d-none d-sm-block">
          <div className="heading_row game_history">
            <div className="item">{__("Player Nickname")}</div>
            
          </div>
          {results.length ? (
            <>
              {results.map((item, index) => (
                <div key={index} className="data_row">
                  <div className="item">
                    <span className="action_icon">
                      <i className="far fa-check"></i>
                    </span>
                    {item.player_details.player_nickname}
                  </div>
                  
                </div>
              ))}

             
            </>
          ) : (
            <NoRecordsFound />
          )}
        </div>

        <div className="mobile_accordion d-block d-sm-none">
          {results.length ? (
            <>
              <Accordion>
                {results.map((item, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{item.game?.game_name}</Accordion.Header>
                    <Accordion.Body>
                      <div className="accordion_content">
                        <div className="item">
                          <div className="input_label">{__("Game")}</div>
                          <div className="input_box bg_grey">
                            {item.game?.game_name}
                          </div>
                        </div>
                        <div className="item">
                          <div className="input_label">{__("Type")}</div>
                          <div className="input_box bg_grey">{item.type}</div>
                        </div>
                        <div className="item">
                          <div className="input_label">{__("Balance Type")}</div>
                          <div className="input_box bg_grey">
                            {item.using_bonus}
                          </div>
                        </div>
                        <div className="item">
                          <div className="input_label">{__("Freeround")}</div>
                          <div className="input_box bg_grey">
                            {item.is_freeround}
                          </div>
                        </div>
                        <div className="item">
                          <div className="input_label">{__("Amount")}</div>
                          <div className="input_box bg_grey">{item.amount}</div>
                        </div>

                        <div className="item">
                          <div className="input_label">{__("Cash Balance Before")}</div>
                          <div className="input_box bg_grey">
                            {item.balance_before}
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>

              <br />
              <br />
              <div className="pagination">
                <Pagination data={pagination} onPageChange={onPageChange} />
              </div>
            </>
          ) : (
            <NoRecordsFound />
          )}
        </div>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
