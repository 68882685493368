import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link, useHistory } from "react-router-dom";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import moneyFormatter from "services/moneyFormatter";
import ls from "services/ls";
import config from "configs/config";
import { isMobile } from "services/isMobile";
import { Modal, Button, Alert, Dropdown, Form, Overlay, Tooltip, Accordion } from "react-bootstrap";
import Translator, { __ } from "services/Translator";
import swal from "sweetalert";
import SelectBox from "elements/SelectBox";

function CashierModals(props) {
  const history = useHistory();
  const userBalance = props.userBalance;
  const getUserBalance = props.getUserBalance;
  const setUsersWallet = props.setUsersWallet;
  const showInPlay = false;
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const wageringTooltipRef = useRef(null);
  const [wageringTooltipShow, setWageringTooltipShow] = useState(false);
  const tagRef = useRef(null);
  const [tagShow, setTagShow] = useState(false);
  const fiatMethods = ["USD"];
  const isLunuAvailable = false;
  const lunuSupportedCryptos = ["USDTE"];

  // crypto popup
  const [cryptoPopUp, setCryptoPopUp] = useState(false);

  const minDeposits = {
    BTC: 0.0001,
    ETH: 0.002,
    BNB: 0.01,
    "BNB-BSC": 0.0001,
    LTC: 0.01,
    DOGE: 1,
    USDTE: 5,
    USDTT: 2,
    TRX: 10,
    XRP: 0.001,
    USDTB: 5,
  };
  const confirmationsRequired = {
    BTC: 1,
    ETH: 10,
    BNB: 1,
    "BNB-BSC": 25,
    LTC: 6,
    DOGE: 6,
    USDTE: 10,
    USDTT: 19,
    TRX: 19,
    XRP: 3,
    USDTB: 25,
  };

  useEffect(() => {
    var timeout;
    if (wageringTooltipShow) {
      timeout = setTimeout(() => {
        setWageringTooltipShow(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [wageringTooltipShow]);

  const copyDepositAddress = () => {
    var copyText = document.getElementById("deposit_address");
    navigator.clipboard.writeText(copyText.value);
    setShow(true);
    const timer = setTimeout(() => {
      setShow(false);
    }, 2000);
    return () => clearTimeout(timer);
  };

  const copyDepositTag = () => {
    var copyText = document.getElementById("deposit_tag");
    navigator.clipboard.writeText(copyText.value);
    setTagShow(true);
    const timer = setTimeout(() => {
      setTagShow(false);
    }, 2000);
    return () => clearTimeout(timer);
  };

  // walletModal
  const [depositWallets, setDepositWallets] = useState([]);
  const [selectedDepositWallet, setSelectedDepositWallet] = useState("BTC");

  const [selectedWithdrawalWallet, setSelectedWithdrawalWallet] = useState("BTC");
  const [withdrawalWalletAddress, setWithdrawalWalletAddress] = useState("");
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [withdrawalTag, setWithdrawalTag] = useState("");

  const [showWalletModal, setShowWalletModal] = useState(false);
  const openWalletModal = async () => {
    if (userBalance.wallets.length) {
      setShowWalletModal(true);

      setWithdrawalMessage(null);
      setWithdrawalMessageType("success");

      if (fiatMethods.indexOf(userBalance.wallets[0].currency) === -1) {
        setSelectedDepositWallet(userBalance.wallets[0].currency);
        setSelectedWithdrawalWallet(userBalance.wallets[0].currency);
      }

      var response = await api.get("/deposit/wallets");
      if (response.status === 200) {
        setDepositWallets(response.data);
      }
      getPlayerAcceptBonuses();
    }
  };

  const closeWalletModal = () => {
    setShowWalletModal(false);
    props.setOpenWalletModalTab(null);
  };

  const [showBalanceAsFiat, setShowBalanceAsFiat] = useState(
    ls.get("showBalanceAsFiat") ? ls.get("showBalanceAsFiat") : false
  );
  const [hideEmptyWallets, setHideEmptyWallets] = useState(
    ls.get("hideEmptyWallets") ? ls.get("hideEmptyWallets") : false
  );
  const [fiatCurrencyToDisplay, setFiatCurrencyToDisplay] = useState(
    ls.get("fiatCurrencyToDisplay") ? ls.get("fiatCurrencyToDisplay") : "USD"
  );
  const [showFiatChooserModal, setShowFiatChooserModal] = useState(false);
  const toggleShowBalanceAsFiat = () => {
    if (!showBalanceAsFiat) {
      setShowFiatChooserModal(true);
    }
    ls.set("showBalanceAsFiat", !showBalanceAsFiat);
    setShowBalanceAsFiat(!showBalanceAsFiat);
    props.setTrigger(Math.random());
  };
  const toggleHideEmptyWallets = () => {
    ls.set("hideEmptyWallets", !hideEmptyWallets);
    setHideEmptyWallets(!hideEmptyWallets);
  };
  const fiatToDisplaySelected = (ev) => {
    ls.set("fiatCurrencyToDisplay", ev.target.value);
    setFiatCurrencyToDisplay(ev.target.value);
    props.setTrigger(Math.random());
  };
  const closeFiatChooserModal = () => {
    setShowFiatChooserModal(false);
  };

  const [withdrawalMessage, setWithdrawalMessage] = useState(null);
  const [withdrawalMessageType, setWithdrawalMessageType] = useState("success");

  const withdraw = async () => {
    if (profile.emailVerified) {
      if (!withdrawalWalletAddress) {
        setWithdrawalMessage("Please enter the " + selectedWithdrawalWallet + " address for withdrawal");
        setWithdrawalMessageType("danger");
        return false;
      }

      if (depositWallets[selectedWithdrawalWallet]?.tag && !withdrawalTag) {
        setWithdrawalMessage("Please enter the tag for withdrawal");
        setWithdrawalMessageType("danger");
        return false;
      }

      var amount = parseFloat(withdrawalAmount);
      if (!amount || isNaN(amount)) {
        setWithdrawalMessage("Please enter a valid amount for withdrawal");
        setWithdrawalMessageType("danger");
        return false;
      } else if (parseFloat(amount) < 50) {
        setWithdrawalMessage("Minimum amount for withdrawal is $50.00");
        setWithdrawalMessageType("danger");
        return false;
      }
      var postData = {
        amount: amount,
        method: "COINSPAID:" + selectedWithdrawalWallet,
        accNumber: withdrawalWalletAddress,
        tag: withdrawalTag,
      };
      var response = await api.post("/withdraw", {
        ...postData,
        twofa_code: twoFaCode,
      });
      if (response.status === 200) {
        setWithdrawalAmount("");
        setWithdrawalWalletAddress("");
        setWithdrawalTag("");
        setTwoFaCode("");
        setWithdrawalMessage("Your withdrawal request has been received");
        setWithdrawalMessageType("success");

        setTimeout(() => {
          setWithdrawalMessage(null);
        }, 3000);
      } else {
        props.showAlert("Warning!!", response.data.message, true);
      }
    } else {
      swal({
        title: __("Attention!"),
        text: __("Please verify your e-mail address before requesting a withdraw. We have send you an email for this."),
        icon: "warning",
      }).then(function () {
        // quickLogin(newRegForm.email, newRegForm.password);
        // setShowWalletModal(false);
        // history.push("/dashboard/verify");
        resendActivationMail();
      });
    }
  };

  // wallet tabs
  const [activeWalletTab, setActiveWalletTab] = useState("deposit"); //deposit,withdrawal
  const [activeDepositTab, setActiveDepositTab] = useState("crypto"); //crypto,fiat
  const [activeFiatTab, setActiveFiatTab] = useState(null); //onramper,lunu,payop,noda
  const [lunuMethod, setLunuMethod] = useState("LUNU");
  const [activeBuyCryptoTab, setActiveBuyCryptoTab] = useState(null);

  const toggleWalletTab = (tabName) => {
    setActiveWalletTab(tabName);
  };

  useEffect(() => {
    if (props.openWalletModalTab) {
      if (props.openWalletModalTab === "deposit") {
        openWalletModal();
        setActiveWalletTab("deposit");
        setActiveDepositTab("crypto");
      } else if (props.openWalletModalTab === "withdraw") {
        openWalletModal();
        setActiveWalletTab("withdraw");
      }
    }
  }, [props.openWalletModalTab]);

  useEffect(() => {
    setShowBalanceAsFiat(ls.get("showBalanceAsFiat") ? ls.get("showBalanceAsFiat") : false);
    setFiatCurrencyToDisplay(ls.get("fiatCurrencyToDisplay") ? ls.get("fiatCurrencyToDisplay") : "USD");
  }, [props.trigger]);

  const [playerAcceptBonuses, setPlayerAcceptBonuses] = useState(false);

  const getPlayerAcceptBonuses = async () => {
    var response = await api.get("/player/accept-bonuses");
    if (response.status === 200) {
      setPlayerAcceptBonuses(response.data);
    }
  };

  const togglePlayerAcceptBonuses = async () => {
    var response = await api.post("/player/accept-bonuses");
    if (response.status === 200) {
      setPlayerAcceptBonuses(response.data);
    }
  };

  const [level1Verification, setLevel1Verification] = useState(null);
  const [level2Verification, setLevel2Verification] = useState(false);

  const userDetails = props.userDetails;
  const [profile, setProfile] = useState({});
  const [twoFaCode, setTwoFaCode] = useState("");
  const getProfile = async () => {
    var response = await api.get("/profile");
    if (response.status === 200) {
      setProfile(response.data);
      if (
        response.data.firstName &&
        response.data.lastName &&
        response.data.bday &&
        response.data.bmonth &&
        response.data.byear &&
        response.data.playerCountry &&
        response.data.address &&
        response.data.city &&
        response.data.zip
      ) {
        setLevel1Verification(true);
      } else {
        setLevel1Verification(false);
      }
    }
  };
  const getIdUploads = async () => {
    var response = await api.get("/id-uploads");
    console.log("id-uploadsid-uploads", response);
    if (response.status === 200 && response.data) {
      if (response.data.poif && response.data.poib) {
        if (response.data.poif.approved == "approved" && response.data.poib.approved == "approved") {
          setLevel2Verification(true);
        } else {
          setLevel2Verification(false);
        }
      }
    }
  };

  const resendActivationMail = async (ev) => {
    props.setSpinner(true);
    var response = await api.post("/resend-activation-mail", {
      force: true,
      email: props.userDetails.email,
    });
    props.setSpinner(false);
    if (response.status == 200) {
      // alert("sent");
    } else {
    }
  };

  const resend2FaCode = async (ev) => {
    props.setSpinner(true);
    var response = await api.post("/resend-twofa");
    props.setSpinner(false);
    if (response.status == 200) {
      swal({
        className: "custom_swal",
        title: __("2FA code sent"),
        text: response.data.message,
        icon: "success",
      });
    }
  };

  useEffect(() => {
    getProfile();
  }, [showWalletModal]);

  const [onRamperWidgetUrl, setOnRamperWidgetUrl] = useState("https://widget.onramper.com?color=266677&apiKey=");

  const updateOnRamperWidgetUrl = () => {
    var url = "https://buy.onramper.com?themeName=light";
    url += "&apiKey=" + config.onRamperApiKey;
    url += "&onlyGateways=Xanpool,Itez,Moonpay,Payfura";
    url += "&supportSell=false";
    url += "&isAddressEditable=true";
    url += "&defaultCrypto=" + selectedDepositWallet;

    var cryptos = userBalance.wallets.map((item, index) => item.currency);
    var wallets = Object.entries(depositWallets)
      .filter((item) => fiatMethods.indexOf(item.currency) === -1)
      .map((item, index) => item[0] + ":" + item[1].address)
      .join(",");
    console.log(wallets);
    url += "&onlyCryptos=" + cryptos;
    url += "&wallets=" + wallets;
    setOnRamperWidgetUrl(url);
  };

  useEffect(() => {
    updateOnRamperWidgetUrl();
  }, [activeWalletTab, activeDepositTab, depositWallets, selectedDepositWallet]);

  useEffect(() => {
    setBankDepositUrl(null);
    setActiveFiatTab(null);
  }, [activeWalletTab, activeDepositTab]);

  const [lunuWidgetUrl, setLunuWidgetUrl] = useState(null);
  const [fiatCurrencies, setFiatCurrencies] = useState([{ label: "USD", value: "USD" }]);

  const getFiatCurrencies = async () => {
    var response = await api.get("/currencies/true");
    var tmpCurrencies = [{ label: __("Select Currency"), value: "" }];
    if (response.status === 200) {
      response.data.map((item) => {
        tmpCurrencies.push({ label: item.currency_code, value: item.currency_code });
      });
    }
    setFiatCurrencies(tmpCurrencies);
  };

  const [bankDepositCurrency, setBankDepositCurrency] = useState("");
  const [bankDepositAmount, setBankDepositAmount] = useState("");
  const [bankDepositUrl, setBankDepositUrl] = useState(null);

  useEffect(() => {
    setLunuWidgetUrl(null);
  }, [activeWalletTab, activeFiatTab, selectedDepositWallet, bankDepositAmount]);

  const initPayopDeposit = async () => {
    if (
      !bankDepositAmount ||
      isNaN(parseFloat(bankDepositAmount)) ||
      !parseFloat(bankDepositAmount) ||
      parseFloat(bankDepositAmount) <= 0
    ) {
      swal({
        title: __("Warning!"),
        text: __("Please enter an amount."),
        icon: "warning",
      });
      return;
    }
    if (!bankDepositCurrency) {
      swal({
        title: __("Warning!"),
        text: __("Please select a currency for deposit."),
        icon: "warning",
      });
      return;
    }
    var url = "/cashier/payop/invoice/create";
    var data = { amount: bankDepositAmount, currency: bankDepositCurrency };
    var response = await api.post(url, data);

    if (response.status === 200) {
      // var bankUrl = "https://checkout.payop/" + response.data.invoiceId;
      var bankUrl = "https://checkout.payop.com/en/payment/" + response.data.invoiceId;
      window.location.href = bankUrl;
      setBankDepositUrl(bankUrl);
    }
  };

  const [isNodaAvailable, setIsNodaAvailable] = useState(false);
  const [isUTPayAvailable, setIsUTPayAvailable] = useState(false);

  const checkIfNodaAvailable = async () => {
    var url = "/cashier/noda/is-available";
    var response = await api.get(url);
    if (response.status === 200) {
      setIsNodaAvailable(response.data.available);
    } else {
      setIsNodaAvailable(false);
    }
  };

  const checkIfUTPayAvailable = async () => {
    var url = "/cashier/utpay/is-available";
    var response = await api.get(url);
    if (response.status === 200) {
      setIsUTPayAvailable(response.data.available);
    } else {
      setIsUTPayAvailable(false);
    }
  };

  useEffect(() => {
    getFiatCurrencies();
    checkIfNodaAvailable();
    checkIfUTPayAvailable();
  }, []);

  const initNodaDeposit = async () => {
    if (
      !bankDepositAmount ||
      isNaN(parseFloat(bankDepositAmount)) ||
      !parseFloat(bankDepositAmount) ||
      parseFloat(bankDepositAmount) <= 0
    ) {
      swal({
        title: __("Warning!"),
        text: __("Please enter an amount."),
        icon: "warning",
      });
      return;
    }
    props.setSpinner(true);
    var url = "/cashier/noda/invoice/create";
    var data = { amount: bankDepositAmount };
    var response = await api.post(url, data);
    props.setSpinner(false);
    if (response.status === 200) {
      var bankUrl = response.data.url;
      window.location.href = bankUrl;
      // setBankDepositUrl(bankUrl);
    }
  };

  const initLunuDeposit = async () => {
    props.setSpinner(true);
    var url = "/cashier/lunu/invoice/create";
    var data = { amount: bankDepositAmount, crypto: "USDTE", method: lunuMethod };
    var response = await api.post(url, data);
    props.setSpinner(false);
    if (response.status === 200) {
      window.location.href = response.data.widget_url;
      // console.log(response.data);
      // var widgetData = response.data.params;
      // (function (d, t, widgetData) {
      //   if (typeof window.Lunu !== "undefined") {
      //     const widget = new window.Lunu.widgets.Replenishment({
      //       baseUrl: "https://f2c.lunu.io", // widget base url; default: https://f2c.lunu.io
      //       params: widgetData,
      //       onClose() {
      //         widget.remove();
      //       },
      //     });
      //   } else {
      //     var n = d.getElementsByTagName(t)[0],
      //       s = d.createElement(t);
      //     s.type = "text/javascript";
      //     s.charset = "utf-8";
      //     s.async = true;
      //     s.src = "https://f2c.lunu.io/iframe.js?t=" + 1 * new Date();
      //     s.onload = function () {
      //       // Widget initialization
      //       const widget = new window.Lunu.widgets.Replenishment({
      //         baseUrl: "https://f2c.lunu.io", // widget base url; default: https://f2c.lunu.io
      //         params: widgetData,
      //         onClose() {
      //           widget.remove();
      //         },
      //       });
      //     };
      //     n.parentNode.insertBefore(s, n);
      //   }
      // })(document, "script", widgetData);
    }
  };

  const [changellyProviders, setChangellyProviders] = useState(false);
  const [activeChangellyProvider, setActiveChangellyProvider] = useState(null);
  const getChangellyProviders = async () => {
    var url = "/cashier/changelly/providers";
    var response = await api.get(url);
    if (response.status === 200) {
      setChangellyProviders(response.data.methods);
      setActiveChangellyProvider(response.data.methods[0].code);
    }
  };

  const selectChangellyProvider = (provider, forBuyCryptoTab) => {
    if (forBuyCryptoTab) {
      setActiveBuyCryptoTab("changelly");
    } else {
      setActiveFiatTab("changelly");
    }
    setActiveChangellyProvider(provider);
  };

  const initChangellyDeposit = async () => {
    var url = "/cashier/changelly/init-deposit";
    var postData = { amount: bankDepositAmount, provider: activeChangellyProvider };
    var response = await api.post(url, postData);
    if (response.status === 200) {
      console.log(response.data);
      window.location.href = response.data.redirectUrl;
    } else {
      swal({
        title: __("Warning!"),
        text: response.data.message,
        icon: "warning",
      });
    }
  };

  const initUTPayDeposit = async () => {
    var url = "/cashier/utpay/init-deposit";
    if (!userBalance?.wallets[0]?.currency || fiatMethods.includes(userBalance?.wallets[0]?.currency)) {
      swal({
        title: __("Warning!"),
        text: __("Please set a crypto wallet as your active wallet for UTORG deposit."),
        icon: "warning",
      });
      return false;
    }

    var postData = { amount: bankDepositAmount };
    var response = await api.post(url, postData);
    if (response.status === 200) {
      console.log(response.data);
      // alert(response.data.redirectUrl);
      window.location.href = response.data.redirectUrl;
    } else {
      swal({
        title: __("Warning!"),
        text: response.data.message,
        icon: "warning",
      });
    }
  };

  useEffect(() => {
    getChangellyProviders();
  }, []);

  return (
    <>
      <div className="deposit_block common_btn_v1 inactive padding_0_8 d-flex">
        <Dropdown>
          <Dropdown.Toggle className="right wallets_dropdown" id="dropdown-basic1">
            {!showInPlay && (
              <div className="deposit_amount">
                {!!userBalance.wallets?.length
                  ? showBalanceAsFiat
                    ? moneyFormatter.convertCurrencyToFiat(
                        userBalance.wallets[0].balance + userBalance.wallets[0].bonus_balance_crypto,
                        userBalance.wallets[0].currency,
                        fiatCurrencyToDisplay
                      )
                    : parseFloat(userBalance.wallets[0].balance + userBalance.wallets[0].bonus_balance_crypto).toFixed(
                        8
                      )
                  : ""}
              </div>
            )}

            {showInPlay && (
              <div onClick={() => getUserBalance()} className="deposit_amount">
                {__("In Play")}
              </div>
            )}

            <div className="deposit_icon">
              {" "}
              {!!userBalance.wallets?.length && moneyFormatter.currencyIcon(userBalance.wallets[0].currency)}
            </div>
            <div className="wallet_caret">
              <i className="fal downarrow fa-caret-down"></i>
              <i className="fal uparrow fa-caret-up"></i>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className="wallets_menu desktop">
            <div className="modal_top_arrow"></div>
            <div>
              <div className="wallets_head row">
                <div className="col-6"></div>
                <div className="col-6 text-right">{__("Crypto")}</div>
              </div>

              <div className="wallets_menu_body">
                {!!userBalance.wallets?.length &&
                  userBalance.wallets.map((item, index) => {
                    if (!item.balance && hideEmptyWallets && index !== 0) {
                      return null;
                    }
                    return (
                      <Dropdown.Item as="button" key={index}>
                        <div className="wallets_balance row" key={index} onClick={() => setUsersWallet(item.currency)}>
                          <div className="col-6">
                            {showBalanceAsFiat
                              ? moneyFormatter.convertCurrencyToFiat(item.balance, item.currency, fiatCurrencyToDisplay)
                              : parseFloat(item.balance).toFixed(8)}
                          </div>
                          <div className="col-6 currency">{moneyFormatter.currencyWithIcon(item.currency)}</div>
                        </div>
                      </Dropdown.Item>
                    );
                  })}

                {userBalance.bonus_balance && (
                  <Dropdown.Item as="button">
                    <div className="wallets_balance row">
                      <div className="col-6">
                        {userBalance.currency + "" + parseFloat(userBalance.bonus_balance).toFixed(2)}
                      </div>

                      <div
                        ref={wageringTooltipRef}
                        onMouseOver={() => setWageringTooltipShow(true)}
                        onMouseOut={() => setWageringTooltipShow(false)}
                        className="col-6 text-right currency"
                      >
                        <span className="currency_icon bonusIcon">
                          <img
                            style={{ width: "20px" }}
                            src={require("../../assets/images/icons/star-icon.svg").default}
                            alt=""
                          />
                        </span>
                        <span className="currency_name">{__("BONUS")}</span>

                        <br />
                      </div>
                      <div className="progress_bar">
                        <div className="progress">
                          <div
                            style={{
                              width: userBalance.wagering_percentage + "%",
                            }}
                            className="progress-bar"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </Dropdown.Item>
                )}
              </div>

              <div className="wallets_bottom row">
                <div className="wallets_switch">
                  <div className="col-11 offset-1">
                    <Form.Check
                      checked={showBalanceAsFiat}
                      type="switch"
                      onChange={toggleShowBalanceAsFiat}
                      label="Show as fiat"
                      id="show_as_fiat"
                    />
                  </div>
                </div>

                <div className="wallets_switch">
                  <div className="col-11 offset-1">
                    <Form.Check
                      checked={hideEmptyWallets}
                      type="switch"
                      label="Hide empty wallets"
                      id="hide_empty_wallets"
                      onChange={toggleHideEmptyWallets}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="deposit_button d-md-none">
                    <a onClick={openWalletModal} className="deposit_btn" href="#">
                      {__("Deposit")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>

        <div className="deposit_button d-none d-md-block">
          <a onClick={openWalletModal} className="deposit_btn" href="#">
            {__("Deposit")}
          </a>
        </div>
      </div>
      <Modal show={showFiatChooserModal} onHide={closeFiatChooserModal}>
        <Modal.Body className="cashier_modal fiat_selectors_modal">
          <div onClick={closeFiatChooserModal} className="modal_close_reg small">
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.26872 8.99995L1.05492 3.59305C0.775612 3.3034 0.589405 2.86892 0.589405 2.43443C0.589405 1.99995 0.775612 1.56547 1.05492 1.27581C1.66009 0.648226 2.68423 0.648226 3.2894 1.27581L8.5032 6.68271L13.717 1.27581C14.3222 0.648226 15.3463 0.648226 15.9515 1.27581C16.2308 1.56547 16.417 1.99995 16.417 2.43443C16.417 2.86892 16.2308 3.3034 15.9515 3.59305L10.7377 8.99995L15.9515 14.4068C16.2308 14.6965 16.417 15.131 16.417 15.5655C16.417 15.9999 16.2308 16.4344 15.9515 16.7241C15.6722 17.0137 15.2532 17.2068 14.8342 17.2068C14.4153 17.2068 13.9963 17.0137 13.717 16.7241L8.5032 11.3172L3.2894 16.7241C2.68423 17.3517 1.66009 17.3517 1.05492 16.7241C0.775612 16.4344 0.589405 15.9999 0.589405 15.5655C0.589405 15.131 0.775612 14.6965 1.05492 14.4068L6.26872 8.99995Z"
                fill="#10294F"
              />
            </svg>
          </div>

          <div className="modal_headline">{__("Information")}</div>
          <>
            <div className="privacy_text">
              {__("Please note that these are currency approximations.")}
              <br />
              <br />
              {__("All bets & transactions will be settled in the crypto equivalent.")}
              {__("For further details feel free to contact our live support.")}
            </div>

            <div className="form_area">
              <div className="form_row fiat_selectors">
                {["USD", "EUR"].map((currency, index) => (
                  <div className="fiat_select" key={index}>
                    <input
                      type="radio"
                      value={currency}
                      checked={fiatCurrencyToDisplay === currency}
                      id={"fiat_selector" + currency}
                      onChange={fiatToDisplaySelected}
                    />
                    <label htmlFor={"fiat_selector" + currency} className="radio_area">
                      <div className="radio_check_and_text_area">
                        <div className="radio_box">
                          <div className="radio_checked_box"></div>
                        </div>
                        <div className="radio_text">
                          {moneyFormatter.currencyIcon(currency)} <span className="currency">{currency}</span>
                        </div>
                      </div>
                    </label>
                  </div>
                ))}
              </div>

              <div className="login_button">
                <Link to="#" onClick={closeFiatChooserModal} className="common_btn">
                  {__("Play Now")}
                </Link>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>

      <Modal size="lg" show={showWalletModal} onHide={closeWalletModal}>
        <Modal.Body className="cashier_modal">
          <div onClick={closeWalletModal} className="modal_close_reg small">
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.26872 8.99995L1.05492 3.59305C0.775612 3.3034 0.589405 2.86892 0.589405 2.43443C0.589405 1.99995 0.775612 1.56547 1.05492 1.27581C1.66009 0.648226 2.68423 0.648226 3.2894 1.27581L8.5032 6.68271L13.717 1.27581C14.3222 0.648226 15.3463 0.648226 15.9515 1.27581C16.2308 1.56547 16.417 1.99995 16.417 2.43443C16.417 2.86892 16.2308 3.3034 15.9515 3.59305L10.7377 8.99995L15.9515 14.4068C16.2308 14.6965 16.417 15.131 16.417 15.5655C16.417 15.9999 16.2308 16.4344 15.9515 16.7241C15.6722 17.0137 15.2532 17.2068 14.8342 17.2068C14.4153 17.2068 13.9963 17.0137 13.717 16.7241L8.5032 11.3172L3.2894 16.7241C2.68423 17.3517 1.66009 17.3517 1.05492 16.7241C0.775612 16.4344 0.589405 15.9999 0.589405 15.5655C0.589405 15.131 0.775612 14.6965 1.05492 14.4068L6.26872 8.99995Z"
                fill="#10294F"
              />
            </svg>
          </div>

          <div className="modal_headline">{__("Wallet")}</div>
          <br />
          <div className="modal_header_v1">
            <div className="login_mode in_deposit_modal">
              <Link
                to="#"
                onClick={() => toggleWalletTab("deposit")}
                className={activeWalletTab === "deposit" ? "mode_btn active" : "mode_btn"}
              >
                {__("Deposit")}
              </Link>
              <Link
                to="#"
                onClick={() => toggleWalletTab("withdraw")}
                className={activeWalletTab === "withdraw" ? "mode_btn active" : "mode_btn"}
              >
                {__("Withdraw")}
              </Link>
              <Link
                to="#"
                onClick={() => toggleWalletTab("buy_crypto")}
                className={activeWalletTab === "buy_crypto" ? "mode_btn active" : "mode_btn"}
              >
                {__("Buy Crypto")}
              </Link>
              <Link onClick={() => props.setShowVaultModal(true)} className="mode_btn">
                <svg width="14" height="30" viewBox="0 0 14 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.00011 19.2486C10.8624 19.2522 13.9964 16.1241 14 12.2618C14.002 10.1348 13.0359 8.12247 11.3748 6.79391V4.37472C11.3748 1.95859 9.4162 0 7.00011 0C4.58403 0 2.62547 1.95859 2.62547 4.37468V6.79387C-0.390752 9.20632 -0.880192 13.6072 1.53226 16.6234C2.86081 18.2844 4.87311 19.2505 7.00011 19.2486ZM4.37533 4.37468C4.37533 2.92504 5.55048 1.74986 7.00015 1.74986C8.44983 1.74986 9.62498 2.92501 9.62498 4.37468V5.76497C7.94254 5.0779 6.0578 5.0779 4.37537 5.76497V4.37468H4.37533ZM7.00011 6.99947C9.89939 6.99947 12.2497 9.3498 12.2497 12.2491C12.2497 15.1483 9.89939 17.4987 7.00011 17.4987C4.10084 17.4987 1.75051 15.1484 1.75051 12.2491C1.75342 9.35101 4.10205 7.00238 7.00011 6.99947Z"
                    fill="#4DE8FF"
                  />
                  <path
                    d="M6.12523 12.8817V14.8739C6.12523 15.3571 6.51694 15.7489 7.00016 15.7489C7.48339 15.7489 7.87509 15.3571 7.87509 14.8739V12.8817C8.71205 12.3985 8.99881 11.3283 8.51559 10.4913C8.03236 9.65437 6.96219 9.3676 6.12523 9.85083C5.28828 10.3341 5.00151 11.4043 5.48474 12.2412C5.63833 12.5072 5.85923 12.7281 6.12523 12.8817Z"
                    fill="#4DE8FF"
                  />
                </svg>{" "}
                {__("Vault")}
              </Link>
              <Link onClick={() => props.setShowTipModal(true)} className="mode_btn">
                {__("Tip")}
              </Link>
            </div>
          </div>

          {activeWalletTab === "deposit" && (
            <>
              <div className="form_area">
                <div className="modal_header_v1">
                  <div className="login_mode in_deposit_modal">
                    {activeDepositTab === "fiat" && activeFiatTab !== null ? (
                      <>
                        <Link to="#" onClick={() => setActiveFiatTab(null)} className="mode_btn">
                          &lArr;{__("Back")}
                        </Link>
                        {false && activeFiatTab === "lunu" && (
                          <>
                            <Link
                              hrefLang={Translator.getSelectedLanguage().code}
                              to="#"
                              onClick={() => setLunuMethod("LUNU:sofort")}
                              className={lunuMethod === "LUNU:sofort" ? "mode_btn active" : "mode_btn"}
                            >
                              {__("Sofort")}
                            </Link>
                            <Link
                              hrefLang={Translator.getSelectedLanguage().code}
                              to="#"
                              onClick={() => setLunuMethod("LUNU:giropay")}
                              className={lunuMethod === "LUNU:giropay" ? "mode_btn active" : "mode_btn"}
                            >
                              {__("GiroPay")}
                            </Link>
                            <Link
                              hrefLang={Translator.getSelectedLanguage().code}
                              to="#"
                              onClick={() => setLunuMethod("LUNU:bancontact")}
                              className={lunuMethod === "LUNU:bancontact" ? "mode_btn active" : "mode_btn"}
                            >
                              {__("Bancontact")}
                            </Link>
                            <Link
                              hrefLang={Translator.getSelectedLanguage().code}
                              to="#"
                              onClick={() => setLunuMethod("LUNU:creditCard")}
                              className={lunuMethod === "LUNU:creditCard" ? "mode_btn active" : "mode_btn"}
                            >
                              {__("Card")}
                            </Link>
                          </>
                        )}
                      </>
                    ) : (
                      <div className="depositModeButtons">
                        <Link
                          to="#"
                          onClick={() => setActiveDepositTab("crypto")}
                          className={activeDepositTab === "crypto" ? "mode_btn active" : "mode_btn"}
                        >
                          <div className="logo">
                            <img src={require("assets/images/logos/bitcoin.svg").default} />
                          </div>
                          <div className="text"> {__("Deposit with crypto")}</div>
                        </Link>
                        <Link
                          to="#"
                          onClick={() => setActiveDepositTab("fiat")}
                          className={activeDepositTab === "fiat" ? "mode_btn active" : "mode_btn"}
                        >
                          <div className="logo">
                            <img src={require("assets/images/logos/dollar.svg").default} />
                          </div>
                          <div className="text">{__("Deposit with fiat")}</div>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
                {activeDepositTab === "crypto" && (
                  <>
                    <div className="input_item">
                      <div className="input_label">{__("Cryptocurrency")}</div>

                      <Dropdown className="currency_select_menu">
                        <Dropdown.Toggle variant="success" className="right select_crypto" id="dropdown-basic1">
                          <span className="dipwid vaultCryptoIcon">
                            {moneyFormatter.currencyFullWithIcon(selectedDepositWallet)}
                          </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="wallets_menu">
                          {!!userBalance.wallets?.length &&
                            userBalance.wallets
                              .filter((item) => fiatMethods.indexOf(item.currency) === -1)
                              .map((item, index) => {
                                return (
                                  <Dropdown.Item as="button" key={index}>
                                    <div
                                      className="wallets_balance"
                                      onClick={() => setSelectedDepositWallet(item.currency)}
                                    >
                                      {moneyFormatter.currencyFullWithIcon(item.currency)}
                                    </div>
                                  </Dropdown.Item>
                                );
                              })}
                        </Dropdown.Menu>
                      </Dropdown>
                      <div className="clearfix"></div>
                    </div>

                    <div className="privacy_text bordered_text">
                      {__("The minimum deposit amount for")} {moneyFormatter.currencyName(selectedDepositWallet)} is{" "}
                      {moneyFormatter.currencyIcon(selectedDepositWallet)} {minDeposits[selectedDepositWallet]} ($
                      {Math.ceil(
                        moneyFormatter.getFiatValue(minDeposits[selectedDepositWallet], selectedDepositWallet, "USD")
                      ).toFixed(2)}{" "}
                      {__("approximately")}).{" "}
                      {__("Depositing a lower amount may result in a full loss of the transferred amount.")}
                      <br />
                      {__("Only send")} {selectedDepositWallet} {__("to this address")},{" "}
                      {confirmationsRequired[selectedDepositWallet]} {__("confirmation")}
                      {confirmationsRequired[selectedDepositWallet] > 1 ? "s" : ""} {__("requried")}.{" "}
                      {__("We do not accept BEP20 from Binance.")}
                    </div>

                    <div className="input_item walletAddress">
                      <div className="input_label">
                        {selectedDepositWallet} {__("Address")}
                        <span className="requried_icon">*</span>
                      </div>
                      <input
                        type="text"
                        name="btc"
                        id="deposit_address"
                        value={
                          depositWallets[selectedDepositWallet] ? depositWallets[selectedDepositWallet].address : ""
                        }
                        className="form-control name_value"
                      />

                      <a className="in_input_icon" onClick={copyDepositAddress} href="javascript:" ref={target}>
                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M16.0915 0H5.65821V5.93965H0V22.6502H16.491V16.7105H22.1493V6.35904L16.0915 0ZM16.3511 1.58759L20.6369 6.08658H16.3511V1.58759ZM15.6051 21.7201H0.885957V6.86968H9.8069V12.9563H15.6051V21.7201ZM10.6929 12.0262V7.52724L14.9787 12.0262H10.6929ZM21.2633 15.7804H16.491V12.2987L10.4333 5.93965H6.54422V0.930025H15.4651V7.0166H21.2633V15.7804Z"
                            fill="white"
                          />
                        </svg>
                      </a>
                      <Overlay target={target.current} show={show} placement="top">
                        {(props) => (
                          <Tooltip id="overlay-example" className="tooltip" {...props}>
                            {__("Copied")}
                          </Tooltip>
                        )}
                      </Overlay>
                    </div>

                    {depositWallets[selectedDepositWallet]?.tag && (
                      <div className="input_item walletAddress">
                        <div className="input_label">
                          {selectedDepositWallet} {__("Tag/Memo")}
                          <span className="requried_icon">*</span>
                        </div>
                        <input
                          type="text"
                          name="deposit_tag"
                          id="deposit_tag"
                          value={depositWallets[selectedDepositWallet].tag}
                          className="form-control name_value"
                        />

                        <a className="in_input_icon" onClick={copyDepositTag} href="javascript:" ref={tagRef}>
                          <svg
                            width="23"
                            height="23"
                            viewBox="0 0 23 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.0915 0H5.65821V5.93965H0V22.6502H16.491V16.7105H22.1493V6.35904L16.0915 0ZM16.3511 1.58759L20.6369 6.08658H16.3511V1.58759ZM15.6051 21.7201H0.885957V6.86968H9.8069V12.9563H15.6051V21.7201ZM10.6929 12.0262V7.52724L14.9787 12.0262H10.6929ZM21.2633 15.7804H16.491V12.2987L10.4333 5.93965H6.54422V0.930025H15.4651V7.0166H21.2633V15.7804Z"
                              fill="white"
                            />
                          </svg>
                        </a>
                        <Overlay target={tagRef.current} show={tagShow} placement="top">
                          {(props) => (
                            <Tooltip id="overlay-example" className="tooltip" {...props}>
                              {__("Copied")}
                            </Tooltip>
                          )}
                        </Overlay>
                      </div>
                    )}

                    <div className="">
                      <Form.Check
                        checked={playerAcceptBonuses}
                        type="switch"
                        onChange={togglePlayerAcceptBonuses}
                        label={__("Accept bonuses")}
                        id="accept_bonuses"
                      />
                    </div>

                    <div className="input_item">
                      <div className="qr_code in_wallet_modal">
                        <img
                          className="wallet_qr"
                          src={
                            config.apiUrl +
                            "/qr-code/" +
                            (depositWallets[selectedDepositWallet] ? depositWallets[selectedDepositWallet].qr_text : "")
                          }
                        />
                      </div>
                    </div>
                  </>
                )}

                {activeDepositTab === "fiat" && (
                  <>
                    {activeFiatTab === null && (
                      <div className="modal_header_v2">
                        <div className="login_mode in_deposit_modal narrow">
                          <Link
                            hrefLang={Translator.getSelectedLanguage().code}
                            to="#"
                            onClick={() => {
                              selectChangellyProvider("moonpay");
                            }}
                            className={
                              activeFiatTab === "changelly" && activeChangellyProvider === "moonpay"
                                ? "mode_btn active"
                                : "mode_btn"
                            }
                          >
                            <div className="big_method_button">
                              <div className="method_logo">
                                <img
                                  style={{ minHeight: "60px", marginTop: "17px" }}
                                  src={require("assets/images/logos/payment_method/moonpay.svg").default}
                                />
                              </div>
                              <div className="method_main_text">
                                {__("Moonpay")}
                                <div className="subtext">{__("Buy crypto for players worldwide")}</div>
                              </div>
                              <div className="method_descriptions">
                                <ul>
                                  <li>{__("Countries: Worldwide")}</li>
                                  <li>{__("Verification needed: Only with first purchase")}</li>
                                  <li>{__("Processing time: 5 to 15 minutes")}</li>
                                  <li>{__("Payment methods: Visa, Mastercard")}</li>
                                </ul>
                              </div>
                            </div>
                          </Link>

                          {isUTPayAvailable && (
                            <>
                              <Link
                                hrefLang={Translator.getSelectedLanguage().code}
                                to="#"
                                onClick={() => {
                                  setActiveFiatTab("utpay");
                                }}
                                className={activeFiatTab === "utpay" ? "mode_btn active" : "mode_btn"}
                              >
                                <div className="big_method_button">
                                  <div className="method_logo">
                                    <img
                                      style={{ height: "40px", marginTop: "27px" }}
                                      src={require("assets/images/logos/payment_method/utorg.png").default}
                                    />
                                  </div>
                                  <div className="method_main_text">
                                    {__("UTORG")}
                                    <div className="subtext">{__("Buy crypto for players worldwide")}</div>
                                  </div>
                                  <div className="method_descriptions">
                                    <ul>
                                      <li>{__("No verification needed up to 700 EUR*")}</li>
                                      <li>{__("Processing time: immediately")}</li>
                                      <li>
                                        {__(
                                          "Payment Methods: Skrill, Neteller, Paysafecard, PIX, Multibanco, MB Way, Trustly, P24 and many more"
                                        )}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </Link>
                            </>
                          )}

                          <Link
                            hrefLang={Translator.getSelectedLanguage().code}
                            to="#"
                            onClick={() => {
                              selectChangellyProvider("banxa");
                            }}
                            className={
                              activeFiatTab === "changelly" && activeChangellyProvider === "banxa"
                                ? "mode_btn active"
                                : "mode_btn"
                            }
                          >
                            <div className="big_method_button">
                              <div className="method_logo">
                                <img
                                  style={{ minHeight: "60px", marginTop: "17px" }}
                                  src={require("assets/images/logos/payment_method/banxa.svg").default}
                                />
                              </div>
                              <div className="method_main_text">
                                {__("Banxa")}
                                <div className="subtext">{__("Buy crypto for players worldwide")}</div>
                              </div>
                              <div className="method_descriptions">
                                <ul>
                                  <li>{__("Countries: Worldwide")}</li>
                                  <li>{__("Verification needed: Only with first purchase")}</li>
                                  <li>{__("Processing time: 5 to 15 minutes")}</li>
                                  <li>{__("Payment methods: Visa, Mastercard")}</li>
                                </ul>
                              </div>
                            </div>
                          </Link>

                          <Link
                            hrefLang={Translator.getSelectedLanguage().code}
                            to="#"
                            onClick={() => setActiveFiatTab("payop")}
                            className={activeFiatTab === "payop" ? "mode_btn active" : "mode_btn"}
                          >
                            <div className="big_method_button">
                              <div className="method_logo">
                                <img src={require("assets/images/logos/payment_method/payop.svg").default} />
                              </div>
                              <div className="method_main_text">
                                {__("Payop")}
                                <div className="subtext">{__("Deposit options for players worldwide")}</div>
                              </div>
                              <div className="method_descriptions">
                                <ul>
                                  <li>{__("Countries: Worldwide")}</li>
                                  <li>{__("Verification needed: None")}</li>
                                  <li>{__("Processing time: Instant or 5 - 15 minutes")}</li>
                                  <li>{__("Payment methods: 460 methods in 170 countries")}</li>
                                </ul>
                              </div>
                            </div>
                          </Link>

                          {isLunuAvailable && (
                            <Link
                              hrefLang={Translator.getSelectedLanguage().code}
                              to="#"
                              onClick={() => setActiveFiatTab("lunu")}
                              className={activeFiatTab === "lunu" ? "mode_btn active" : "mode_btn"}
                            >
                              <div className="big_method_button">
                                <div className="method_logo">
                                  <img src={require("assets/images/logos/payment_method/lunulogo.png").default} />
                                </div>
                                <div className="method_main_text">
                                  {__("Lunu")}
                                  <div className="subtext">{__("Buy crypto for players worldwide")}</div>
                                </div>
                                <div className="method_descriptions">
                                  <ul>
                                    <li>{__("Countries: Europe")}</li>
                                    <li>{__("Verification needed: Only with first purchase")}</li>
                                    <li>{__("Processing time: 5 - 15 minutes")}</li>
                                    <li>{__("Payment methods: Sofort, Giropay, EPS, Bancontact, Visa, MC")}</li>
                                  </ul>
                                </div>
                              </div>
                            </Link>
                          )}

                          {isNodaAvailable && (
                            <Link
                              hrefLang={Translator.getSelectedLanguage().code}
                              to="#"
                              onClick={() => setActiveFiatTab("noda")}
                              className={activeFiatTab === "noda" ? "mode_btn active" : "mode_btn"}
                            >
                              <div className="big_method_button">
                                <div className="method_logo">
                                  <img
                                    style={{ minHeight: "48px" }}
                                    src={require("assets/images/logos/payment_method/nodepay.png").default}
                                  />
                                </div>
                                <div className="method_main_text">
                                  {__("Noda")}
                                  <div className="subtext">{__("Instant banking for players from Europe")}</div>
                                </div>
                                <div className="method_descriptions">
                                  <ul>
                                    <li>{__("Countries: Europe")}</li>
                                    <li>{__("Verification needed: None")}</li>
                                    <li>{__("Processing time: Instant (and no fees)")}</li>
                                    <li>{__("Payment methods: Direct online banking in Europe")}</li>
                                  </ul>
                                </div>
                              </div>
                            </Link>
                          )}

                          <Link
                            hrefLang={Translator.getSelectedLanguage().code}
                            to="#"
                            onClick={() => setActiveFiatTab("onramper")}
                            className={activeFiatTab === "onramper" ? "mode_btn active" : "mode_btn"}
                          >
                            <div className="big_method_button">
                              <div className="method_logo">
                                <img
                                  style={{ minHeight: "48px", marginTop: "21px" }}
                                  src={require("assets/images/logos/payment_method/onramper.png").default}
                                />
                              </div>
                              <div className="method_main_text">
                                {__("Onramper")}
                                <div className="subtext">{__("Buy crypto for players worldwide")}</div>
                              </div>
                              <div className="method_descriptions">
                                <ul>
                                  <li>{__("Countries: Worldwide")}</li>
                                  <li>{__("Verification needed: Only with first purchase")}</li>
                                  <li>{__("Processing time: 5 to 15 minutes")}</li>
                                  <li>{__("Payment methods: Visa, Mastercard")}</li>
                                </ul>
                              </div>
                            </div>
                          </Link>

                          {/* <Link
                            hrefLang={Translator.getSelectedLanguage().code}
                            to="#"
                            onClick={() => setActiveFiatTab("LUNU:giropay")}
                            className={activeFiatTab === "LUNU:giropay" ? "mode_btn active" : "mode_btn"}
                          >
                            {__("GiroPay")}
                          </Link>
                          <Link
                            hrefLang={Translator.getSelectedLanguage().code}
                            to="#"
                            onClick={() => setActiveFiatTab("LUNU:bancontact")}
                            className={activeFiatTab === "LUNU:bancontact" ? "mode_btn active" : "mode_btn"}
                          >
                            {__("Bancontact")}
                          </Link>
                          <Link
                            hrefLang={Translator.getSelectedLanguage().code}
                            to="#"
                            onClick={() => setActiveFiatTab("LUNU:creditCard")}
                            className={activeFiatTab === "LUNU:creditCard" ? "mode_btn active" : "mode_btn"}
                          >
                            {__("Card")}
                          </Link> */}
                        </div>
                      </div>
                    )}

                    {activeFiatTab === "onramper" && (
                      <>
                        <div className="form_area">
                          <div
                            style={{
                              justifyContent: "center",
                              padding: "15",
                              width: "800px",
                              maxWidth: "90vw",
                            }}
                          >
                            <iframe
                              style={{
                                borderRadius: 10,
                                boxShadow: "0 2px 10px 0 rgba(0,0,0,.20)",
                                margin: "auto",
                                width: "100%",
                                maxWidth: "90vw",
                                height: "660px",
                              }}
                              src={onRamperWidgetUrl}
                              title="Onramper widget"
                              frameBorder="0"
                              allow="accelerometer; autoplay; camera; gyroscope; payment"
                            ></iframe>
                          </div>
                        </div>
                      </>
                    )}

                    {activeFiatTab === "lunu" && lunuMethod !== null && (
                      <>
                        {lunuWidgetUrl ? (
                          <>
                            <div className="privacy_text bordered_text">&nbsp;</div>
                            <div className="form_area">
                              <div
                                style={{
                                  justifyContent: "center",
                                  padding: "15",
                                  width: "800px",
                                  maxWidth: "90vw",
                                }}
                              >
                                <iframe
                                  style={{
                                    borderRadius: 10,
                                    boxShadow: "0 2px 10px 0 rgba(0,0,0,.20)",
                                    margin: "auto",
                                    width: "100%",
                                    maxWidth: "90vw",
                                    height: "660px",
                                  }}
                                  src={lunuWidgetUrl}
                                  frameBorder="0"
                                ></iframe>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="form_area">
                            <div className="input_item">
                              <div className="input_label">
                                {__("Amount")}
                                <span className="requried_icon">*</span>
                              </div>
                              <input
                                type="number"
                                min="0"
                                step="1"
                                value={bankDepositAmount}
                                onChange={(ev) => setBankDepositAmount(ev.target.value)}
                                className="form-control name_value"
                              />
                              {/* <i className="fal fa-copy in_input_icon"></i> */}
                            </div>

                            <br />
                            {/* <div className="input_item">
                              <div className="input_label">{__("Cryptocurrency")}</div>

                              <Dropdown className="currency_select_menu">
                                <Dropdown.Toggle variant="success" className="right select_crypto" id="dropdown-basic1">
                                  <span className="dipwid vaultCryptoIcon">
                                    {moneyFormatter.currencyFullWithIcon(
                                      lunuSupportedCryptos.indexOf(selectedDepositWallet) !== -1
                                        ? selectedDepositWallet
                                        : lunuSupportedCryptos[0]
                                    )}
                                  </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="wallets_menu">
                                  {!!userBalance.wallets?.length &&
                                    userBalance.wallets
                                      // .filter((item) => fiatMethods.indexOf(item.currency) === -1)
                                      .filter((item) => lunuSupportedCryptos.indexOf(item.currency) !== -1) //Only these currencies supported
                                      .map((item, index) => {
                                        return (
                                          <Dropdown.Item as="button" key={index}>
                                            <div
                                              className="wallets_balance"
                                              onClick={() => setSelectedDepositWallet(item.currency)}
                                            >
                                              {moneyFormatter.currencyFullWithIcon(item.currency)}
                                            </div>
                                          </Dropdown.Item>
                                        );
                                      })}
                                </Dropdown.Menu>
                              </Dropdown>
                              <div className="clearfix"></div>
                            </div>
                            <br /> */}
                            <div className="login_button">
                              <Link onClick={initLunuDeposit} className="common_btn_v1 with_shadow margin_auto">
                                {__("Deposit")}
                              </Link>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {activeFiatTab === "payop" && (
                      <>
                        {bankDepositUrl ? (
                          <>
                            <div className="privacy_text bordered_text">&nbsp;</div>
                            <div className="form_area">
                              <div
                                style={{
                                  justifyContent: "center",
                                  padding: "15",
                                  width: "800px",
                                  maxWidth: "90vw",
                                }}
                              >
                                <iframe
                                  style={{
                                    borderRadius: 10,
                                    boxShadow: "0 2px 10px 0 rgba(0,0,0,.20)",
                                    margin: "auto",
                                    width: "100%",
                                    maxWidth: "90vw",
                                    height: "660px",
                                  }}
                                  src={bankDepositUrl}
                                  frameBorder="0"
                                ></iframe>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="form_area">
                            <div className="input_item">
                              <div className="input_label">
                                {__("Amount")}
                                <span className="requried_icon">*</span>
                              </div>
                              <input
                                type="number"
                                min="0"
                                step="1"
                                value={bankDepositAmount}
                                onChange={(ev) => setBankDepositAmount(ev.target.value)}
                                className="form-control name_value"
                              />
                            </div>

                            <div className="input_item">
                              <div className="input_label">
                                {__("Deposit Currency")}
                                <span className="requried_icon">*</span>
                              </div>

                              <SelectBox
                                className="form-control name_value"
                                style={{ appearance: "auto" }}
                                placeholder={__("Select Deposit Currency")}
                                value={bankDepositCurrency}
                                onChange={(itemValue) => setBankDepositCurrency(itemValue)}
                                items={fiatCurrencies}
                              />
                              <div style={{ fontSize: 12 }}>
                                {__("Kindly choose the currency that aligns with your country's banking system.")}
                                <br />
                                {__(
                                  "Please note that opting for a different currency may incur additional exchange fees."
                                )}
                              </div>
                            </div>
                            <br />
                            <div className="login_button">
                              <Link
                                onClick={initPayopDeposit}
                                className={
                                  "common_btn_v1 with_shadow margin_auto " +
                                  (bankDepositAmount && bankDepositCurrency ? "" : "inactive")
                                }
                              >
                                {__("Deposit")}
                              </Link>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {activeFiatTab === "noda" && isNodaAvailable && (
                      <>
                        <div className="form_area">
                          <div className="input_item">
                            <div className="input_label">
                              {__("Amount")} (&euro;)
                              <span className="requried_icon">*</span>
                            </div>
                            <input
                              type="number"
                              min="0"
                              step="1"
                              value={bankDepositAmount}
                              onChange={(ev) => setBankDepositAmount(ev.target.value)}
                              className="form-control name_value"
                            />
                            <div className="small">
                              {__(
                                "* Please note that all amounts entered in Euros (EUR) will be considered and converted to US Dollars (USD) accordingly by our system."
                              )}
                            </div>
                          </div>

                          <br />
                          <div className="login_button">
                            <Link
                              onClick={initNodaDeposit}
                              className={
                                "common_btn_v1 with_shadow margin_auto " +
                                (bankDepositAmount && bankDepositCurrency ? "" : "inactive")
                              }
                            >
                              {__("Deposit")}
                            </Link>
                          </div>
                        </div>
                      </>
                    )}

                    {activeFiatTab === "changelly" && (
                      <>
                        <div className="form_area">
                          <div className="input_item">
                            <div className="input_label">
                              {__("Amount")} ($)
                              <span className="requried_icon">*</span>
                            </div>
                            <input
                              type="number"
                              min="0"
                              step="1"
                              value={bankDepositAmount}
                              onChange={(ev) => setBankDepositAmount(ev.target.value)}
                              className="form-control name_value"
                            />
                          </div>

                          <br />

                          <div className="login_button">
                            <Link
                              onClick={initChangellyDeposit}
                              className={
                                "common_btn_v1 with_shadow margin_auto " +
                                (bankDepositAmount && bankDepositCurrency ? "" : "inactive")
                              }
                            >
                              {__("Deposit")}
                            </Link>
                          </div>
                        </div>
                      </>
                    )}

                    {activeFiatTab === "utpay" && (
                      <>
                        <div className="form_area">
                          <div className="input_item">
                            <div className="input_label">
                              {__("Amount")} ($)
                              <span className="requried_icon">*</span>
                            </div>
                            <input
                              type="number"
                              min="0"
                              step="1"
                              value={bankDepositAmount}
                              onChange={(ev) => setBankDepositAmount(ev.target.value)}
                              className="form-control name_value"
                            />
                          </div>

                          <br />

                          <div className="login_button">
                            <Link
                              onClick={initUTPayDeposit}
                              className={
                                "common_btn_v1 with_shadow margin_auto " +
                                (bankDepositAmount && bankDepositCurrency ? "" : "inactive")
                              }
                            >
                              {__("Deposit")}
                            </Link>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          {activeWalletTab === "withdraw" && (
            <>
              <div className="form_area">
                <div className="input_item">
                  <div className="input_label">{__("Cryptocurrency")}</div>

                  {withdrawalMessage && <Alert variant={withdrawalMessageType}>{withdrawalMessage}</Alert>}

                  <Dropdown className="currency_select_menu">
                    <Dropdown.Toggle variant="success" className="right select_crypto" id="dropdown-basic1">
                      <span className="dipwid vaultCryptoIcon">
                        {moneyFormatter.currencyFullWithIcon(selectedWithdrawalWallet)}
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="wallets_menu">
                      {!!userBalance.wallets?.length &&
                        userBalance.wallets
                          .filter((item) => fiatMethods.indexOf(item.currency) === -1)
                          .map((item, index) => {
                            return (
                              <Dropdown.Item as="button" key={index}>
                                <div
                                  className="wallets_balance"
                                  onClick={() => setSelectedWithdrawalWallet(item.currency)}
                                >
                                  {moneyFormatter.currencyFullWithIcon(item.currency)}
                                </div>
                              </Dropdown.Item>
                            );
                          })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="clearfix"></div>
                </div>

                <div className="privacy_text bordered_text">{__("Minimum withdrawal is $50.00.")}</div>
                <div className="input_item">
                  <div className="input_label">
                    {selectedWithdrawalWallet} {__("Address")}
                    <span className="requried_icon">*</span>
                  </div>
                  <input
                    type="text"
                    value={withdrawalWalletAddress}
                    onChange={(ev) => setWithdrawalWalletAddress(ev.target.value)}
                    className="form-control name_value"
                  />
                  {/* <i className="fal fa-copy in_input_icon"></i> */}
                </div>
                <div className="input_item">
                  <div className="input_label">
                    {__("Amount")}
                    <span className="requried_icon">*</span>
                  </div>
                  <div className="input-group in_addon_area">
                    <input
                      type="number"
                      className="form-control name_value"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={withdrawalAmount}
                      onChange={(ev) => setWithdrawalAmount(ev.target.value)}
                    />
                    <div className="currency_name">USD</div>
                    <div className="input-group-append">
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setWithdrawalAmount(userBalance.wallets[0].balance_fiat.replace(",", ""));
                        }}
                        className="input-group-text max_lavel"
                        id="basic-addon2"
                      >
                        {__("MAX")}
                      </span>
                    </div>
                  </div>
                </div>

                {depositWallets[selectedWithdrawalWallet]?.tag && (
                  <div className="input_item">
                    <div className="input_label">
                      {__("Tag/Memo")}
                      <span className="requried_icon">*</span>
                    </div>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control name_value"
                        value={withdrawalTag}
                        onChange={(ev) => setWithdrawalTag(ev.target.value)}
                      />
                    </div>
                  </div>
                )}

                {profile.twoFaEnabled && (
                  <div className="input_item">
                    <div className="input_label">
                      {__("Two FA Code")}
                      <span className="requried_icon">*</span>
                      <br />
                      <span>{__("Please enter the twofa code sent to your registered email.")}</span>
                    </div>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control name_value"
                        value={twoFaCode}
                        onChange={(ev) => setTwoFaCode(ev.target.value)}
                      />
                    </div>

                    <a style={{ color: "white" }} href="#" onClick={resend2FaCode}>
                      {__("Get 2FA Code")}
                    </a>
                  </div>
                )}
                <br />
                <div className="login_button">
                  <Link
                    disabled={withdrawalAmount < 50 || !withdrawalWalletAddress}
                    onClick={withdraw}
                    className={
                      withdrawalAmount < 50 || !withdrawalWalletAddress
                        ? "common_btn_v1 margin_auto inactive"
                        : "common_btn_v1 margin_auto"
                    }
                  >
                    {withdrawalAmount >= 50 ? "Withdraw $" + withdrawalAmount : "Min amount is $50.00"}
                  </Link>
                </div>
              </div>
            </>
          )}

          {activeWalletTab === "buy_crypto" && (
            <>
              <div className="form_area">
                <div className="modal_header_v1">
                  <div className="login_mode in_deposit_modal">
                    {activeBuyCryptoTab !== null && (
                      <>
                        <Link to="#" onClick={() => setActiveBuyCryptoTab(null)} className="mode_btn">
                          &lArr;{__("Back")}
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              </div>

              {activeBuyCryptoTab === null && (
                <div className="modal_header_v2">
                  <div className="login_mode in_deposit_modal narrow">
                    <Link
                      hrefLang={Translator.getSelectedLanguage().code}
                      to="#"
                      onClick={() => {
                        selectChangellyProvider("moonpay", true);
                      }}
                      className={
                        activeBuyCryptoTab === "changelly" && activeChangellyProvider === "moonpay"
                          ? "mode_btn active"
                          : "mode_btn"
                      }
                    >
                      <div className="big_method_button">
                        <div className="method_logo">
                          <img
                            style={{ minHeight: "60px", marginTop: "17px" }}
                            src={require("assets/images/logos/payment_method/moonpay.svg").default}
                          />
                        </div>
                        <div className="method_main_text">
                          {__("Moonpay")}
                          <div className="subtext">{__("Buy crypto for players worldwide")}</div>
                        </div>
                        <div className="method_descriptions">
                          <ul>
                            <li>{__("Countries: Worldwide")}</li>
                            <li>{__("Verification needed: Only with first purchase")}</li>
                            <li>{__("Processing time: 5 to 15 minutes")}</li>
                            <li>{__("Payment methods: Visa, Mastercard")}</li>
                          </ul>
                        </div>
                      </div>
                    </Link>

                    {isUTPayAvailable && (
                      <>
                        <Link
                          hrefLang={Translator.getSelectedLanguage().code}
                          to="#"
                          onClick={() => {
                            setActiveBuyCryptoTab("utpay");
                          }}
                          className={activeBuyCryptoTab === "utpay" ? "mode_btn active" : "mode_btn"}
                        >
                          <div className="big_method_button">
                            <div className="method_logo">
                              <img
                                style={{ height: "40px", marginTop: "27px" }}
                                src={require("assets/images/logos/payment_method/utorg.png").default}
                              />
                            </div>
                            <div className="method_main_text">
                              {__("UTORG")}
                              <div className="subtext">{__("Buy crypto for players worldwide")}</div>
                            </div>
                            <div className="method_descriptions">
                              <ul>
                                <li>{__("No verification needed up to 700 EUR*")}</li>
                                <li>{__("Processing time: immediately")}</li>
                                <li>
                                  {__(
                                    "Payment Methods: Skrill, Neteller, Paysafecard, PIX, Multibanco, MB Way, Trustly, P24 and many more"
                                  )}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Link>
                      </>
                    )}

                    <Link
                      hrefLang={Translator.getSelectedLanguage().code}
                      to="#"
                      onClick={() => {
                        selectChangellyProvider("banxa", true);
                      }}
                      className={
                        activeBuyCryptoTab === "changelly" && activeChangellyProvider === "banxa"
                          ? "mode_btn active"
                          : "mode_btn"
                      }
                    >
                      <div className="big_method_button">
                        <div className="method_logo">
                          <img
                            style={{ minHeight: "60px", marginTop: "17px" }}
                            src={require("assets/images/logos/payment_method/banxa.svg").default}
                          />
                        </div>
                        <div className="method_main_text">
                          {__("Banxa")}
                          <div className="subtext">{__("Buy crypto for players worldwide")}</div>
                        </div>
                        <div className="method_descriptions">
                          <ul>
                            <li>{__("Countries: Worldwide")}</li>
                            <li>{__("Verification needed: Only with first purchase")}</li>
                            <li>{__("Processing time: 5 to 15 minutes")}</li>
                            <li>{__("Payment methods: Visa, Mastercard")}</li>
                          </ul>
                        </div>
                      </div>
                    </Link>

                    {isLunuAvailable && (
                      <Link
                        hrefLang={Translator.getSelectedLanguage().code}
                        to="#"
                        onClick={() => setActiveBuyCryptoTab("lunu")}
                        className={activeBuyCryptoTab === "lunu" ? "mode_btn active" : "mode_btn"}
                      >
                        <div className="big_method_button">
                          <div className="method_logo">
                            <img src={require("assets/images/logos/payment_method/lunulogo.png").default} />
                          </div>
                          <div className="method_main_text">
                            {__("Lunu")}
                            <div className="subtext">{__("Buy crypto for players worldwide")}</div>
                          </div>
                          <div className="method_descriptions">
                            <ul>
                              <li>{__("Countries: Europe")}</li>
                              <li>{__("Verification needed: Only with first purchase")}</li>
                              <li>{__("Processing time: 5 - 15 minutes")}</li>
                              <li>{__("Payment methods: Sofort, Giropay, EPS, Bancontact, Visa, MC")}</li>
                            </ul>
                          </div>
                        </div>
                      </Link>
                    )}

                    <Link
                      hrefLang={Translator.getSelectedLanguage().code}
                      to="#"
                      onClick={() => setActiveBuyCryptoTab("onramper")}
                      className={activeBuyCryptoTab === "onramper" ? "mode_btn active" : "mode_btn"}
                    >
                      <div className="big_method_button">
                        <div className="method_logo">
                          <img src={require("assets/images/logos/payment_method/onramper.png").default} />
                        </div>
                        <div className="method_main_text">
                          {__("Onramper")}
                          <div className="subtext">{__("Buy crypto for players worldwide")}</div>
                        </div>
                        <div className="method_descriptions">
                          <ul>
                            <li>{__("Countries: Worldwide")}</li>
                            <li>{__("Verification needed: Only with first purchase")}</li>
                            <li>{__("Processing time: 5 to 15 minutes")}</li>
                            <li>{__("Payment methods: Visa, Mastercard")}</li>
                          </ul>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              )}

              {activeBuyCryptoTab === "onramper" && (
                <div className="form_area">
                  <div
                    style={{
                      justifyContent: "center",
                      padding: "15",
                      width: "800px",
                      maxWidth: "90vw",
                    }}
                  >
                    <iframe
                      style={{
                        borderRadius: 10,
                        boxShadow: "0 2px 10px 0 rgba(0,0,0,.20)",
                        margin: "auto",
                        width: "100%",
                        maxWidth: "90vw",
                        height: "660px",
                      }}
                      src={onRamperWidgetUrl}
                      title="Onramper widget"
                      frameBorder="0"
                      allow="accelerometer; autoplay; camera; gyroscope; payment"
                    ></iframe>
                  </div>
                </div>
              )}

              {activeBuyCryptoTab === "lunu" && lunuMethod !== null && (
                <>
                  {lunuWidgetUrl ? (
                    <>
                      <div className="privacy_text bordered_text">&nbsp;</div>
                      <div className="form_area">
                        <div
                          style={{
                            justifyContent: "center",
                            padding: "15",
                            width: "800px",
                            maxWidth: "90vw",
                          }}
                        >
                          <iframe
                            style={{
                              borderRadius: 10,
                              boxShadow: "0 2px 10px 0 rgba(0,0,0,.20)",
                              margin: "auto",
                              width: "100%",
                              maxWidth: "90vw",
                              height: "660px",
                            }}
                            src={lunuWidgetUrl}
                            frameBorder="0"
                          ></iframe>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="form_area">
                      <div className="input_item">
                        <div className="input_label">
                          {__("Amount")}
                          <span className="requried_icon">*</span>
                        </div>
                        <input
                          type="number"
                          min="0"
                          step="1"
                          value={bankDepositAmount}
                          onChange={(ev) => setBankDepositAmount(ev.target.value)}
                          className="form-control name_value"
                        />
                        {/* <i className="fal fa-copy in_input_icon"></i> */}
                      </div>

                      <br />

                      <div className="login_button">
                        <Link onClick={initLunuDeposit} className="common_btn_v1 with_shadow margin_auto">
                          {__("Deposit")}
                        </Link>
                      </div>
                    </div>
                  )}
                </>
              )}

              {activeBuyCryptoTab === "utpay" && (
                <>
                  <div className="form_area">
                    <div className="input_item">
                      <div className="input_label">
                        {__("Amount")} ($)
                        <span className="requried_icon">*</span>
                      </div>
                      <input
                        type="number"
                        min="0"
                        step="1"
                        value={bankDepositAmount}
                        onChange={(ev) => setBankDepositAmount(ev.target.value)}
                        className="form-control name_value"
                      />
                    </div>

                    <br />

                    <div className="login_button">
                      <Link
                        onClick={initUTPayDeposit}
                        className={
                          "common_btn_v1 with_shadow margin_auto " +
                          (bankDepositAmount && bankDepositCurrency ? "" : "inactive")
                        }
                      >
                        {__("Deposit")}
                      </Link>
                    </div>
                  </div>
                </>
              )}

              {activeBuyCryptoTab === "changelly" && (
                <>
                  <div className="form_area">
                    <div className="input_item">
                      <div className="input_label">
                        {__("Amount")} ($)
                        <span className="requried_icon">*</span>
                      </div>
                      <input
                        type="number"
                        min="0"
                        step="1"
                        value={bankDepositAmount}
                        onChange={(ev) => setBankDepositAmount(ev.target.value)}
                        className="form-control name_value"
                      />
                    </div>

                    <br />

                    <div className="login_button">
                      <Link
                        onClick={initChangellyDeposit}
                        className={
                          "common_btn_v1 with_shadow margin_auto " +
                          (bankDepositAmount && bankDepositCurrency ? "" : "inactive")
                        }
                      >
                        {__("Deposit")}
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CashierModals;
