import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import { Badge, Table } from "react-bootstrap";

import CategoryGameSlider from "elements/games_elements/CategoryGameSlider";
import GamesProviders from "elements/GamesProviders";
import Banner from "elements/Banner";
import HomeBanner from "elements/HomeBanner";
import CategoryGames from "elements/games_elements/CategoryGames";
import GamesFilter from "elements/games_elements/GamesFilter";
import { __ } from "services/Translator";

import LeaderBoard from "../elements/LeaderBoard.js";
import ThrillingBanner from "../elements/ThrillingBanner";
import Winners from "../elements/Winners";

export default function Landing(props) {
  const history = useHistory();
  const location = useLocation();
  const [gameList, setGameList] = useState([]);
  const [gameSorting, setGameSorting] = useState(null);
  const [pagination, setPagination] = useState(null);
  // const [perPage, setPerPage] = useState(18);
  const [gameLoaded, setGameLoaded] = useState(0);
  const [totalGame, setTotalGame] = useState(0);
  const [pageNum, setPageNum] = useState(1);

  const getGameList = async (page) => {
    setPageNum(page);
    var perPage = 12;
    if (page > 1) {
      perPage = 24;
    }

    if (window.innerWidth < 992) {
      perPage = 24;
    }

    if (window.innerWidth < 768) {
      perPage = 21;
    }
    if (window.innerWidth < 576) {
      perPage = 18;
    }

    props.setSpinner(true);
    var response = await api.post("/games/by-category", {
      per_page: perPage,
      game_types: ["popular"],
      page: page,
      game_sorting: gameSorting,
      providers: props.selectedProviders,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      setGameList(response.data.games?.popular?.gamelist ?? []);

      if (page === 1) {
        setGameList(response.data.games?.popular?.gamelist ?? []);
      } else {
        var gamesClone = JSON.parse(JSON.stringify(gameList));
        console.log("gamesClone", gamesClone);
        gamesClone = [...gamesClone, ...response.data.games["popular"].gamelist];
        setGameList(gamesClone);
      }

      if (response.data.paginationData) {
        setPagination(response.data.paginationData);
      } else {
        setPagination(null);
      }

      setGameLoaded(page * perPage);
      setTotalGame(response.data.gameCount);
    }
  };

  useEffect(() => {
    getGameList(1);
  }, [gameSorting, props.selectedProviders]);

  const onPageChange = (page) => {
    getGameList(page);
  };

  useEffect(() => {
    if (location.pathname === "/signup") {
      props.setModalSignUpPropsData(true);
      setTimeout(function () {
        props.setModalSignUpPropsData(false);
      }, 500);
    }

    if (location.pathname === "/login") {
      props.setModalLoginPropsData(true);
      props.setModalForgotPasswordPropsData(false);
      setTimeout(function () {
        props.setModalLoginPropsData(false);
      }, 500);
    }

    if (location.pathname === "/forgot-password") {
      props.setModalForgotPasswordPropsData(true);
      setTimeout(function () {
        props.setModalForgotPasswordPropsData(false);
      }, 500);
    }

    if (location.pathname == "/claim_cash") {
      props.setModalLoginPropsData(true);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{__("Blizz Casino | Sign Up today for a 100% of up to 1BTC!")}</title>
        <meta
          name="description"
          content={__(
            "Blizz Casino! Sign Up today and receive bonuses on your first 2 Deposits totalling 2.5 BTC - Double your Coins on us!"
          )}
        />
      </Helmet>
      {props.isAuthenticated || <HomeBanner {...props} />}

      <GamesFilter {...props} />
      <CategoryGames
        providers={props.providers}
        selectedProviders={props.selectedProviders}
        setSelectedProviders={props.setSelectedProviders}
        gameList={gameList}
        categoryName="Games"
        category="popular"
        setGameSorting={setGameSorting}
        gameSorting={gameSorting}
        isAuthenticated={props.isAuthenticated}
        gameLoaded={gameLoaded}
        totalGame={totalGame}
        showPagination={true}
        pagination={pagination}
        onPageChange={onPageChange}
        setSearchStr={props.setSearchStr}
        className="langding_games"
        pageNum={pageNum}
        calledFrom="home"
        {...props}
      />
      {/* <CategoryGameSlider {...props} /> */}

      <LeaderBoard {...props} />

      <ThrillingBanner {...props} />

      <Winners {...props} />
    </>
  );
}
