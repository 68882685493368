import React, { useState, useEffect } from "react";
import api from "services/api";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { __ } from "services/Translator";

const HomeBanner = (props) => {
  // const menuOpen = props.menuOpen;
  const [searchVal, setSearchVal] = useState("");

  const updateInput = (ev) => {
    var target = ev.target;
    // var field = target.name;
    var value = target.value;
    setSearchVal(value);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 10000,
  };

  const [homeBanners, setHomeBanners] = useState([]);
  const getHomeBanners = async () => {
    var response = await api.get("/banners");
    if (response.status === 200) {
      setHomeBanners(response.data);
    }
  };

  useEffect(() => {
    getHomeBanners();
  }, []);

  const formatSubtitle = (text) => {
    try {
      var textParts = text.split("**");
      return (
        <>
          {textParts.map((part, index) => {
            if (index % 2 == 1) {
              return <span>{part}</span>;
            } else {
              return part;
            }
          })}
        </>
      );
    } catch (ex) {
      return text;
    }
  };

  return (
    <>
      <section className="header_banner_area">
        <div className="container-md">
          <div className="banner_v1">
            <div className="row">
              <div className="col-12 col-md-9">
                <div className="single_item">
                  <div className="banner_img">
                    {props.isMobileDevice ? (
                      <img
                        src={
                          require("assets/images/v1/banners/banner1-mobile.png")
                            .default
                        }
                        alt="Blizz Casino "
                      />
                    ) : (
                      <img
                        src={
                          require("assets/images/v1/banners/banner1.svg")
                            .default
                        }
                        alt="Blizz Casino "
                      />
                    )}
                  </div>
                  <div className="overlay_content">
                    <div className="banner_text_section">
                      <div className="banner_headline">
                        {__("Welcome Offer")}
                      </div>
                      <div className="banner_headline_colored">
                        {__("100% Welcome Offer 1BTC")}
                      </div>
                      <div className="banner_description">
                        {__("T&C Applies, read bonus terms for more details")}
                      </div>
                      <div className="banner_link">
                        {props.isAuthenticated ? (
                          <Link
                            to="/games/live-casino"
                            onClick={() => {
                              props.setModalSignUpPropsData(true);
                              setTimeout(function () {
                                props.setModalSignUpPropsData(false);
                              }, 500);
                            }}
                            className="common_btn_v1 large uppercase with_shadow"
                          >
                            {__("Play Now!")}
                          </Link>
                        ) : (
                          <>
                            <Link
                              to=""
                              onClick={() => {
                                props.setModalSignUpPropsData(true);
                                setTimeout(function () {
                                  props.setModalSignUpPropsData(false);
                                }, 500);
                              }}
                              className="common_btn_v1 large uppercase with_shadow"
                            >
                              {__("Get The Deal!")}
                            </Link>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-3">
                <div style={{marginTop: '40px'}} className="single_item small margin_top_20_mobile">
                  <div className="banner_img">
                  {props.isMobileDevice ? (
                      <img
                        src={
                          require("assets/images/v1/banners/banner2-mobile.png")
                            .default
                        }
                        alt="Blizz Casino "
                      />
                    ) : (
                      <img
                        src={
                          require("assets/images/v1/banners/banner2.png")
                            .default
                        }
                        alt="Blizz Casino "
                      />
                    )}
                  </div>
                  <div className="overlay_content">
                    <div className="banner_text_section">
                      <div className="banner_headline small">
                        {__("75% Bonus on Deposit")}
                      </div>

                      <div className="banner_link small">
                        {props.isAuthenticated ? (
                          <Link
                            to="/games/live-casino"
                            onClick={() => {
                              props.setModalSignUpPropsData(true);
                              setTimeout(function () {
                                props.setModalSignUpPropsData(false);
                              }, 500);
                            }}
                            className="common_btn_v1 fit_content small_banner blue_bg_white_text"
                          >
                            {__("Play Now!")}
                          </Link>
                        ) : (
                          <>
                            <Link
                              to=""
                              onClick={() => {
                                props.setModalSignUpPropsData(true);
                                setTimeout(function () {
                                  props.setModalSignUpPropsData(false);
                                }, 500);
                              }}
                              className="common_btn_v1 fit_content small_banner blue_bg_white_text"
                            >
                              {__("Get The Deal!")}
                            </Link>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{marginTop: '10px'}} className="single_item small">
                  <div className="banner_img">
                  {props.isMobileDevice ? (
                      <img
                        src={
                          require("assets/images/v1/banners/banner3-mobile.png")
                            .default
                        }
                        alt="Blizz Casino "
                      />
                    ) : (
                      <img
                        src={
                          require("assets/images/v1/banners/banner3.png")
                            .default
                        }
                        alt="Blizz Casino "
                      />
                    )}
                  </div>
                  <div className="overlay_content">
                    <div className="banner_text_section">
                      <div className="banner_headline small">
                        {__("100% up to 1 BTC")}
                      </div>

                      <div className="banner_link small">
                        {props.isAuthenticated ? (
                          <Link
                            to="/games/live-casino"
                            onClick={() => {
                              props.setModalSignUpPropsData(true);
                              setTimeout(function () {
                                props.setModalSignUpPropsData(false);
                              }, 500);
                            }}
                            className="common_btn_v1 fit_content small_banner blue_bg_white_text"
                          >
                            {__("Play Now!")}
                          </Link>
                        ) : (
                          <>
                            <Link
                              to=""
                              onClick={() => {
                                props.setModalSignUpPropsData(true);
                                setTimeout(function () {
                                  props.setModalSignUpPropsData(false);
                                }, 500);
                              }}
                              className="common_btn_v1 fit_content small_banner blue_bg_white_text"
                            >
                              {__("Get The Deal!")}
                            </Link>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 
        <Slider {...settings}>
          {homeBanners.map((banner, index) => (
            <div className="single_banner_area" key={index}>
              <img className="banner_bg desktop_banner" src={banner.banner} />

              <img className="banner_bg tab_banner" src={banner.medium} />

              <img className="banner_bg mobile_banner" src={banner.small} />

              <div className="container">
                <div className="banner_text_section ">
                  <div className="banner_headline">{banner.title}</div>
                  <div className="banner_headline_colored">
                    {formatSubtitle(banner.sub_title)}
                  </div>
                  <div className="banner_description">
                    {__("T&C Applies, read bonus terms for more details")}
                  </div>

                  {props.isAuthenticated ? (
                    <Link
                      to="/games/live-casino"
                      onClick={() => {
                        props.setModalSignUpPropsData(true);
                        setTimeout(function () {
                          props.setModalSignUpPropsData(false);
                        }, 500);
                      }}
                      className="common_btn float-left"
                    >
                      {__("Play Now!")}
                    </Link>
                  ) : (
                    <>
                      <Link
                        to=""
                        onClick={() => {
                          props.setModalSignUpPropsData(true);
                          setTimeout(function () {
                            props.setModalSignUpPropsData(false);
                          }, 500);
                        }}
                        className="common_btn float-left"
                      >
                        {__("Play Now!")}
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </Slider> */}
      </section>
    </>
  );
};

export default HomeBanner;
