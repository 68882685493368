import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useHistory, useLocation } from "react-router-dom";
import { __ } from "services/Translator";
import { Table, Modal } from "react-bootstrap";
import api from "services/api";
import { flipInX } from "react-animations";
import Radium, { StyleRoot } from "radium";

export default function Winners(props) {
  const location = useLocation();

  const userBalance = props.userBalance;
  console.log("userBalanceuserBalanceuserBalanceuserBalance", userBalance);
  const socket = props.socket;
  const [winnerTab, setWinnerTab] = useState("Recent Winners");
  const [recentWinners, setRecentWinners] = useState([]);
  const getRecentWinners = async () => {
    var response = await api.get("/games/recent-winners", { limit: 9 });
    if (response.status === 200) {
      setRecentWinners(response.data.data);
      // console.log('response.data', response.data);
    }
  };

  const [topWinners, setTopWinners] = useState([]);
  const getTopWinners = async () => {
    var response = await api.get("/games/top-winners", { limit: 9 });
    if (response.status === 200) {
      setTopWinners(response.data.data);
    }
  };
  const checkIframe = () => {
    if (window.parent.parent.location.href != window.location.href) {
      window.parent.location.href = window.location.href;
    } else {
    }
  };

  const getRecentWinsFromSocket = (data) => {
    var winners = JSON.parse(JSON.stringify(recentWinners));
    winners.unshift(JSON.parse(data));
    if (winners.length > 10) {
      winners.splice(10, winners.length - 10);
    }
    setRecentWinners(winners);
  };

  useEffect(async () => {
    getTopWinners();
    getRecentWinners();
    checkIframe();

    var timeout;

    timeout = setInterval(() => {
      getTopWinners();
      getRecentWinners();
    }, 15000);

    return () => {
      clearInterval(timeout);
    };
  }, []);

  const styles = {
    flipInX: {
      animation: "x 2s",
      animationName: Radium.keyframes(flipInX, "flipInX"),
      animationIterationCount: 1,
    },
  };

  return (
    <>
      <div className="sports_help_banner">
        <div className="container-md">
          <section className="winner_section">
            <br />
            <div className="winer_heading">
              <Link
                onClick={() => {
                  setWinnerTab("Recent Winners");
                }}
                className={
                  winnerTab === "Recent Winners"
                    ? "common_btn_v1 fit_content with_shadow"
                    : "common_btn_v1 fit_content inactive"
                }
              >
                <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M28.7333 3.22299C28.1413 2.59397 27.3014 2.23133 26.4355 2.23133H23.6789C23.6901 1.7355 23.6974 1.22492 23.6974 0.699463H5.91838C5.91838 1.22485 5.92576 1.7355 5.93688 2.23133H3.1618C2.29595 2.23133 1.45606 2.59397 0.864037 3.22299C0.264575 3.85576 -0.0461771 4.72153 0.00556668 5.59851C0.501397 13.8646 4.76766 19.0521 11.5314 19.8218L10.2216 24.3803C8.66384 24.3803 7.40212 25.642 7.40212 27.1997V30.3005H22.2174V27.1997C22.2174 25.642 20.9557 24.3803 19.3979 24.3803L18.0881 19.8218C24.8371 19.0447 29.0996 13.8609 29.5918 5.59851C29.6435 4.72529 29.3328 3.85945 28.7333 3.22299ZM2.96198 5.42087C2.95829 5.3395 2.99161 5.284 3.02116 5.25437C3.07666 5.19518 3.1433 5.19518 3.1618 5.19518H6.07752C6.51785 11.0155 7.80176 14.316 9.04874 16.1808C4.30146 14.1901 3.16917 8.87311 2.96198 5.42087ZM20.5672 16.1697C21.8141 14.3012 23.0944 11.0044 23.5346 5.19149H26.4392C26.4614 5.19149 26.5244 5.19149 26.5799 5.25068C26.6095 5.28031 26.6427 5.33581 26.639 5.41718C26.4282 8.86942 25.2996 14.1716 20.5672 16.1697Z"
                    fill="#040F46"
                  />
                </svg>
                {__("Recent Winners")}
              </Link>
              <Link
                onClick={() => {
                  setWinnerTab("Top Winners");
                }}
                className={
                  winnerTab === "Top Winners"
                    ? "common_btn_v1 fit_content with_shadow"
                    : "common_btn_v1 fit_content inactive"
                }
              >
                <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M28.7333 3.22299C28.1413 2.59397 27.3014 2.23133 26.4355 2.23133H23.6789C23.6901 1.7355 23.6974 1.22492 23.6974 0.699463H5.91838C5.91838 1.22485 5.92576 1.7355 5.93688 2.23133H3.1618C2.29595 2.23133 1.45606 2.59397 0.864037 3.22299C0.264575 3.85576 -0.0461771 4.72153 0.00556668 5.59851C0.501397 13.8646 4.76766 19.0521 11.5314 19.8218L10.2216 24.3803C8.66384 24.3803 7.40212 25.642 7.40212 27.1997V30.3005H22.2174V27.1997C22.2174 25.642 20.9557 24.3803 19.3979 24.3803L18.0881 19.8218C24.8371 19.0447 29.0996 13.8609 29.5918 5.59851C29.6435 4.72529 29.3328 3.85945 28.7333 3.22299ZM2.96198 5.42087C2.95829 5.3395 2.99161 5.284 3.02116 5.25437C3.07666 5.19518 3.1433 5.19518 3.1618 5.19518H6.07752C6.51785 11.0155 7.80176 14.316 9.04874 16.1808C4.30146 14.1901 3.16917 8.87311 2.96198 5.42087ZM20.5672 16.1697C21.8141 14.3012 23.0944 11.0044 23.5346 5.19149H26.4392C26.4614 5.19149 26.5244 5.19149 26.5799 5.25068C26.6095 5.28031 26.6427 5.33581 26.639 5.41718C26.4282 8.86942 25.2996 14.1716 20.5672 16.1697Z"
                    fill="#040F46"
                  />
                </svg>
                {__("Big Winners")}
              </Link>
            </div>
            <div className="winner_table">
              <div className="row">
                {winnerTab === "Recent Winners" && (
                  <>
                    {recentWinners.length > 0 &&
                      recentWinners.map((value, index) => (
                        <div className="col-12 col-md-6 col-lg-4" key={value.tr_id}>
                          <div className="single_winer">
                            <div className="winner_img">
                              <StyleRoot>
                                <Link to={"/play/" + value.game?.slug + (props.isAuthenticated ? "" : "/demo")}>
                                  <img style={styles.flipInX} src={value.game?.game_snapshot_url} />
                                </Link>
                              </StyleRoot>
                            </div>

                            <div className="winner_text">
                              <div className="game_name">
                                <Link
                                  to={"/play/" + value.game?.slug + (props.isAuthenticated ? "" : "/demo")}
                                  style={{ color: "white" }}
                                >
                                  {value.game?.game_name}
                                </Link>
                              </div>
                              <div className="game_amount">
                                {__("Bet:")} ${value.bet_amount}
                                <br />
                                {__("Win:")} ${value.win_amount}
                              </div>
                              <div className="game_user">
                                <a onClick={() => props.getPlayerStatData(value.tr_id, "game_transaction_id")}>
                                  {value.player?.player_nickname ? value.player?.player_nickname : __("Anonymous")}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </>
                )}
                {winnerTab === "Top Winners" && (
                  <>
                    {topWinners.length > 0 &&
                      topWinners.map((value, index) => (
                        <div className="col-12 col-md-6 col-lg-4" key={value.tr_id}>
                          <div className="single_winer">
                            <div className="winner_img">
                              {/* <img src={value.game?.game_snapshot_url} /> */}
                              <StyleRoot>
                                <Link
                                  className="single_winer"
                                  to={"/play/" + value.game?.slug + (props.isAuthenticated ? "" : "/demo")}
                                >
                                  <img style={styles.flipInX} src={value.game?.game_snapshot_url} />
                                </Link>
                              </StyleRoot>
                            </div>

                            <div className="winner_text">
                              <div className="game_name">
                                <Link
                                  className="single_winer"
                                  to={"/play/" + value.game?.slug + (props.isAuthenticated ? "" : "/demo")}
                                  style={{ color: "white" }}
                                >
                                  {value.game?.game_name}
                                </Link>
                              </div>
                              <div className="game_amount">
                                {__("Bet:")} ${value.bet_amount}
                                <br />
                                {__("Win:")} ${value.win_amount}
                              </div>
                              <div className="game_user">
                                <a onClick={() => props.getPlayerStatData(value.tr_id, "game_transaction_id")}>
                                  {value.player?.player_nickname ? value.player?.player_nickname : __("Anonymous")}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </>
                )}
              </div>
            </div>
            <div className="text-center margin_top_20 d-none">
              <Link to="#" className="common_btn_v1 fit_content with_shadow uppercase margin_auto">
                {__("Load More")}
              </Link>
            </div>
          </section>
        </div>
      </div>

      <div className="sports_help_banner d-none">
        <div className="container-md">
          <section className="winner_section">
            <br />
            <div className="winner_toggle">
              <Link
                onClick={() => {
                  setWinnerTab("Recent Winners");
                }}
                className={winnerTab === "Recent Winners" ? "toggle_btn common_btn active" : "toggle_btn"}
              >
                {__("Recent Winners")}
              </Link>
              <Link
                onClick={() => {
                  setWinnerTab("Top Winners");
                }}
                className={winnerTab === "Top Winners" ? "toggle_btn common_btn active" : "toggle_btn"}
              >
                {__("Big Winners")}
              </Link>
            </div>
            <div className="winner_table">
              <Table>
                <thead>
                  {!props.isMobileDeviceByViewPort ? (
                    <tr>
                      <td>{__("Game")}</td>
                      <td>{__("User")}</td>
                      <td>{__("Bet")}</td>
                      <td>{__("Win")}</td>
                      <td>{__("Profit")}</td>
                    </tr>
                  ) : (
                    <tr>
                      <td>{__("Game and Player")}</td>
                      <td>{__("Amounts")}</td>
                    </tr>
                  )}
                </thead>

                <tbody>
                  {winnerTab === "Recent Winners" && (
                    <>
                      {recentWinners.length > 0 &&
                        recentWinners.map((value, index) => (
                          <React.Fragment key={index}>
                            {props.isMobileDeviceByViewPort ? (
                              <tr>
                                <td>
                                  <Link
                                    to={"/play/" + value.game?.slug + (props.isAuthenticated ? "" : "/demo")}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <img src={value.game?.game_snapshot_url} />

                                    <div>
                                      {value.game?.game_name} <hr />
                                      {value.player?.player_nickname ? value.player?.player_nickname : __("Anonymous")}
                                    </div>
                                  </Link>
                                </td>
                                <td>
                                  Bet: ${value.bet_amount}
                                  <hr />
                                  Win: ${value.win_amount}
                                </td>
                              </tr>
                            ) : (
                              <tr>
                                <td>
                                  <Link to={"/play/" + value.game?.slug + (props.isAuthenticated ? "" : "/demo")}>
                                    <img src={value.game?.game_snapshot_url} />
                                    {props.isMobileDeviceByViewPort
                                      ? value.game?.game_name.substring(0, 9) + "..."
                                      : value.game?.game_name}
                                  </Link>
                                </td>
                                <td>
                                  {value.player?.player_nickname ? value.player?.player_nickname : __("Anonymous")}
                                </td>
                                <td>${value.bet_amount}</td>
                                <td>${value.win_amount}</td>
                                <td>${value.profit}</td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                    </>
                  )}
                  {winnerTab === "Top Winners" && (
                    <>
                      {topWinners.length > 0 &&
                        topWinners.map((value, index) => (
                          <React.Fragment key={index}>
                            {props.isMobileDeviceByViewPort ? (
                              <tr>
                                <td>
                                  <Link
                                    to={"/play/" + value.game?.slug + (props.isAuthenticated ? "" : "/demo")}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <img src={value.game?.game_snapshot_url} />
                                    <div>
                                      {value.game?.game_name} <hr />
                                      {value.player?.player_nickname ? value.player?.player_nickname : __("Anonymous")}
                                    </div>
                                  </Link>
                                </td>
                                <td>
                                  Bet: ${value.bet_amount}
                                  <hr />
                                  Win: ${value.win_amount}
                                </td>
                              </tr>
                            ) : (
                              <tr>
                                <td>
                                  <Link to={"/play/" + value.game?.slug + (props.isAuthenticated ? "" : "/demo")}>
                                    <img src={value.game?.game_snapshot_url} />
                                    {props.isMobileDeviceByViewPort
                                      ? value.game?.game_name.substring(0, 9) + "..."
                                      : value.game?.game_name}
                                  </Link>
                                </td>
                                <td>
                                  {value.player?.player_nickname ? value.player?.player_nickname : __("Anonymous")}
                                </td>
                                <td>${value.bet_amount}</td>
                                <td>${value.win_amount}</td>
                                <td>${value.profit}</td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                    </>
                  )}
                </tbody>
              </Table>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
