import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { __ } from "services/Translator";
import ProgressBar from "react-bootstrap/ProgressBar";
import WheelOfFortune from "elements/WheelOfFortune";
import api from "services/api";
import RemainintTime from "elements/RemainingTime";
import Raffles from "elements/Raffles";

export default function RewardSystem(props) {
  const [rakebackStatus, setRakebackStatus] = useState({});
  const [cashbackStatus, setCashbackStatus] = useState({});
  const [wheelWagerStatus, setWheelWagerStatus] = useState({});
  const [fortuneWheel, setFortuneWheel] = useState(null); //THe database object of the wheel data
  const [requestOngoing, setRequestOngoing] = useState(false);

  const getRakebackStatus = async () => {
    var response = await api.get("/rewards/rakeback/status");
    // console.log(response);
    setRakebackStatus(response.data);
  };

  const getCashbackStatus = async () => {
    var response = await api.get("/rewards/cashback/status");
    // console.log(response);
    setCashbackStatus(response.data);
  };

  const getWheelOfFortune = async () => {
    var response = await api.get("/rewards/fortune-wheel/status");
    if (response.status === 200) {
      setFortuneWheel(response.data.wheel);
      setWheelWagerStatus(response.data.wagerStatus);
    }
  };

  useEffect(() => {
    // getRakebackStatus();
    getCashbackStatus();
    getWheelOfFortune();
  }, []);

  const claimCashback = async () => {
    if (!requestOngoing) {
      setRequestOngoing(true);
      var response = await api.get("/rewards/cashback/claim");
      if (response.status === 200) {
        props.showAlert(__("Success!!"), response.data.message, false, () => {
          // alert(44);
          getCashbackStatus();
          setRequestOngoing(false);
        });
      } else {
        // alert(response.data.message);
        props.showAlert(__("Warning!!"), response.data.message, true);
      }
    }
  };

  const claimRakeback = async () => {
    if (!requestOngoing) {
      setRequestOngoing(true);
      var response = await api.get("/rewards/rakeback/claim");
      if (response.status === 200) {
        props.showAlert(__("Success!!"), response.data.message, false, () => {
          getRakebackStatus();
          setRequestOngoing(false);
        });
      } else {
        // alert(response.data.message);
        props.showAlert(__("Warning!!"), response.data.message, true);
      }
    }
  };

  return (
    <div className="reward_system">
      <section className="reawards_banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="headline">{__("Blizz Casino Rewards")}</div>
              {/* <div className="headline_colored">{__("Reward system")}</div> */}
              <div className="dsc">
                {__(
                  "Rewards are our little way of saying…you’re valued. We hope you know that, this is us proving it. Get Daily Rakeback every day for the previous days gameplay, Weekly Cashback if you finish $250 or more behind for the week and plenty more, only at Blizz!"
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        {/* Wheel of Fortune Block */}
        <section className="lucky_wheel">
          <WheelOfFortune pendingWheelStatus={wheelWagerStatus} wheel={fortuneWheel} usePropsWheel={true} {...props} />

          <div className="wheel_text_area">
            <div className="headline_colored">{__("Blizz Daily Rakeback Wheel")}</div>
            <div className="dsc">
              {__(
                "Spin the wheel each day for a chance to win between 2% and 50% Rakeback from your previous days play – A cash prize is guaranteed! Every day you finish behind, you will get a cash prize the next day by spinning the wheel."
              )}
            </div>
            <div className="progress_bar">
              {props.isMobileDevice && (
                <>
                  <div className="progressLabelMobile">{wheelWagerStatus.message}</div>
                </>
              )}

              <ProgressBar
                now={wheelWagerStatus.progress}
                label={<span className="progressLabel">{!props.isMobileDevice ? wheelWagerStatus.message : ""}</span>}
              />
              <div className="row">
                <div className="col-md-2 progress_subtitle">
                  {wheelWagerStatus.wheel_text ? wheelWagerStatus.wheel_text : ""}
                </div>
                <div className="col-md-8">&nbsp;</div>
                <div className="col-md-2 text-right progress_subtitle">
                  {wheelWagerStatus.next_wheel_text ? wheelWagerStatus.next_wheel_text : ""}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Daily Rakeback Block */}
        {/* <section className="rakeback_block">
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="rekeblock_thumb">
                <img
                  src={
                    require("../assets/images/rewards/rakeback-1.png").default
                  }
                />
              </div>
            </div>
            <div className="col-lg-9">
              <div className="title">{__("Daily Rakeback")}</div>
              <div className="dsc">
                {__(
                  "Get up to 10% cashback from your previous days play by spinning the Blizz Cashback wheel. Every day you play and finish behind, you are guaranteed some funds back the next day!"
                )}
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            {rakebackStatus.status === "error" ? (
              <>
                <div className="col-lg-12">
                  <div className="progress_bar" style={{ textAlign: "center" }}>
                    {props.isMobileDevice && (
                      <>
                        <div className="progressLabelMobile">
                          {rakebackStatus.message}
                        </div>
                      </>
                    )}
                    <ProgressBar
                      now={0}
                      label={
                        <span className="progressLabel">
                          {!props.isMobileDevice ? rakebackStatus.message : ""}
                        </span>
                      }
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-lg-9">
                  <div className="progress_bar" style={{ textAlign: "center" }}>
                    {props.isMobileDevice && (
                      <>
                        <div className="progressLabelMobile">
                          {rakebackStatus.message}
                        </div>
                      </>
                    )}
                    <ProgressBar
                      now={rakebackStatus.progress}
                      label={
                        <span className="progressLabel">
                          {!props.isMobileDevice ? rakebackStatus.message : ""}
                        </span>
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="claim_button">
                    {rakebackStatus.claimable ? (
                      <button to="#" onClick={claimRakeback}>
                        {__("Claim")}
                      </button>
                    ) : (
                      <Link
                        className="disabled"
                        title={__("Claim at ") + rakebackStatus.claimableAt}
                      >
                        {__("Claim")}
                      </Link>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  {rakebackStatus.claimable ? (
                    <div className="claim_countdown">
                      <span>{__("Claim within:")} </span>
                      <RemainintTime
                        timestamp={rakebackStatus.notClaimableAfter}
                      />
                    </div>
                  ) : (
                    <div className="claim_countdown">
                      <span>{__("Can be claimed after:")} </span>
                      <RemainintTime
                        timestamp={rakebackStatus.claimableAfter}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </section> */}

        {/* Weekly Cashback Block */}
        <section className="rakeback_block">
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="rekeblock_thumb">
                <img src={require("../assets/images/rewards/rakeback-2.png").default} />
              </div>
            </div>
            <div className="col-lg-9">
              <div className="title">{__("Weekly Cashback")}</div>
              <div className="dsc">
                {__(
                  "Finish the week $250 or more down and get 10% cashback automatically once a week. Level up enough to become a VIP and the 10% cashback will increase the more you play!"
                )}
                {__("Weekly Cashback is for all players that finish the week $250 or more behind.")}
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            {cashbackStatus.status === "error" ? (
              <>
                <div className="col-lg-12">
                  <div className="progress_bar" style={{ textAlign: "center" }}>
                    {props.isMobileDevice && (
                      <>
                        <div className="progressLabelMobile">{cashbackStatus.message}</div>
                      </>
                    )}
                    <ProgressBar
                      now={0}
                      label={
                        <span className="progressLabel">{!props.isMobileDevice ? cashbackStatus.message : ""}</span>
                      }
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-lg-9">
                  <div className="progress_bar" style={{ textAlign: "center" }}>
                    {props.isMobileDevice && (
                      <>
                        <div className="progressLabelMobile">{cashbackStatus.message}</div>
                      </>
                    )}
                    <ProgressBar
                      now={cashbackStatus.progress}
                      label={
                        <span className="progressLabel">{!props.isMobileDevice ? cashbackStatus.message : ""}</span>
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="claim_button">
                    {cashbackStatus.claimable ? (
                      <button to="#" onClick={claimCashback}>
                        {__("Claim")}
                      </button>
                    ) : (
                      <Link className="disabled" title={__("Claim at ") + cashbackStatus.claimableAt}>
                        {__("Claim")}
                      </Link>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  {cashbackStatus.claimable ? (
                    <div className="claim_countdown">
                      <span>{__("Can be claimed notClaimableAfter:")} </span>
                      <RemainintTime timestamp={cashbackStatus.notClaimableAfter} />
                    </div>
                  ) : (
                    <div className="claim_countdown">
                      <span>{__("Can be claimed after:")} </span>
                      <RemainintTime timestamp={cashbackStatus.claimableAfter} />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </section>

        {/* Monthly Cashback Block */}
        {/* <section className="rakeback_block">
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="rekeblock_thumb">
                <img src={require("../assets/images/rewards/rakeback-3.png").default} />
              </div>
            </div>
            <div className="col-lg-9">
              <div className="title">{__("Monthly Bonus")}</div>
              <div className="dsc">
                {__(
                  "Play enough and get a guaranteed monthly bonus based on your play each and every month you are behind. Just another extra as a thank you for your custom at Blizz!"
                )}
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-lg-9">
              <div className="progress_bar">
                <ProgressBar
                  now={90}
                  label={<span className="progressLabel">{__("Wager $900 for next level")}</span>}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="claim_button">
                <Link to="#">{__("Claim")}</Link>
              </div>
            </div>
          </div>
        </section> */}

        <Raffles />

        <section className="rakeback_block">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="dsc">
                {__("Check out all our great Promotions at")}{" "}
                <Link style={{ color: "white" }} to="/promotions">
                  https://blizz.io/en/promotions
                </Link>
                <br />
                {__("Become a VIP and get all the great rewards that come with it – find out more here: ")}{" "}
                <Link style={{ color: "white" }} to="/vip">
                  https://blizz.io/en/vip
                </Link>
                <br />
                {__("Get more $$$s for your play with our Races, check out the latest at")}{" "}
                <Link style={{ color: "white" }} to="/races">
                  https://blizz.io/en/races
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
