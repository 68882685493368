import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SelectBox from "elements/SelectBox";
import InputBox from "elements/InputBox";
import Pagination from "elements/Pagination";
import DateSelector from "elements/DateSelector";
import NoRecordsFound from "elements/NoRecordsFound";
import GameImage from "elements/games_elements/GameImage";
import api from "services/api";
import { __ } from "services/Translator";

export default function GamingHistory(props) {
  const defaultForm = {
    startDate: null,
    endDate: null,
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    props.setSpinner(true);
    var response = await api.post("/history/gaming", form);
    props.setSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const doReset = () => {
    setForm(defaultForm);
  };

  const [exportModal, setExportModal] = useState(false);
  const toggleExportModal = () => {
    setExportModal(!exportModal);
  };

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  const d = new Date();
  let year = d.getFullYear();

  return (
    <>
      <h3 className="tabName white">
        <svg
          width="23"
          height="25"
          viewBox="0 0 23 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.0809 1.26441C21.9194 -0.451895 20.1138 0.123457 19.7955 0.0227641C18.4965 0.0228128 16.1418 0.0228128 13.522 0.0228128C0.974149 0.108299 0.746639 -0.321472 0.179037 0.654554C-0.144778 1.34273 0.0827797 2.07882 0.0185431 3.90206C0.0477858 6.06174 -0.0643114 6.28593 0.122209 7.53192C0.445682 9.52852 2.02469 11.6352 3.97187 11.9455C4.77347 13.7627 6.25456 15.2152 8.09169 15.9791V19.0544C6.18243 19.0739 5.73458 18.79 5.82284 20.5153V24.2351C5.94483 25.2773 6.86194 24.9116 7.44655 24.9797C8.3231 24.9797 9.72202 24.9797 11.1186 24.9797C15.0282 24.8951 16.2797 25.3521 16.2794 24.2058C16.1864 18.5027 16.9134 19.0736 14.0106 19.0544V15.979C15.8478 15.2151 17.329 13.7625 18.1305 11.9451C20.0582 11.6476 21.6322 9.53388 21.9642 7.60244C22.1881 6.74719 22.042 2.18683 22.0809 1.26441ZM2.50506 9.37217C1.42931 8.00799 1.45724 6.63007 1.48083 5.33998C1.48078 3.91127 1.48078 1.4869 1.48078 1.48495H3.31801C3.35953 2.5026 3.20469 9.38177 3.4397 10.2061C3.10853 10.0085 2.78759 9.72542 2.50506 9.37217ZM14.8172 23.5175H7.28493V20.5165H14.8172V23.5175ZM12.5484 19.0543H9.55383V16.4221C10.4971 16.6127 11.5838 16.6173 12.5484 16.4221V19.0543ZM13.1687 14.7376C13.0819 14.7508 13.0001 14.7786 12.9262 14.8195C11.7699 15.1949 10.3505 15.201 9.17611 14.8196C9.10213 14.7786 9.0202 14.7508 8.93335 14.7376C5.18306 13.2238 4.62657 10.1934 4.78058 7.35905C4.78117 5.2254 4.78229 1.48705 4.78229 1.48495L17.3219 1.48583C17.3221 1.48622 17.3219 6.89584 17.322 8.41334C17.3153 8.84068 17.3393 8.6179 17.3038 9.31456C17.1311 11.7714 15.4701 13.91 13.1687 14.7376ZM20.6188 6.35621C20.627 7.84959 19.9197 9.42588 18.6627 10.2043C18.899 9.363 18.7415 2.48842 18.7841 1.48495H20.6188V6.35621Z"
            fill="white"
          />
          <path
            d="M14.713 4.48311L12.6348 4.18113C12.4696 3.92774 11.7146 2.09159 11.4358 2.00065C10.6654 1.62098 10.4012 2.25004 10.1477 2.79756C9.87152 3.35717 9.4667 4.17733 9.46485 4.18113C9.21907 4.24522 7.20721 4.43213 7.06587 4.61237C6.3965 5.20166 6.92692 5.71233 7.38048 6.11891C7.8273 6.55453 8.48225 7.19294 8.48532 7.19587C8.46972 7.47504 8.01568 9.4192 8.16267 9.63574C8.53459 10.4247 9.15132 10.0814 9.68422 9.77703C10.2366 9.48665 11.0462 9.06102 11.0499 9.05907C11.3358 9.16849 13.0176 10.2065 13.3016 10.1184C14.1512 9.97349 13.9903 9.30763 13.8752 8.71659C13.7697 8.10152 13.6151 7.20006 13.6144 7.19587L15.1182 5.73007C15.3175 5.53585 15.3892 5.24533 15.3032 4.98068C15.2171 4.71598 14.9884 4.52308 14.713 4.48311ZM12.3186 6.41708C11.8942 6.76907 12.2438 7.62525 12.2778 8.05278C10.8012 7.22194 11.0467 7.44677 9.82181 8.05278C10.1534 6.39525 10.0249 6.70011 9.06286 5.71701C10.7481 5.51709 10.407 5.49798 11.0499 4.27339C11.7607 5.81439 11.6735 5.484 13.0368 5.71701L12.3186 6.41708Z"
            fill="white"
          />
        </svg>{" "}
        {__("Gaming History")}
      </h3>
      <div className="user_blocks">
        <div className="single_block filtering_block">
          <div className="item">
            <div className="input_label">
              <i className="far fa-calendar-alt"></i>
              {__("Start Day")}
            </div>
            <DateSelector
              className="filter_group"
              value={form.startDate}
              startYear={2022}
              endYear={year}
              onChange={(itemValue) => updateForm(itemValue, "startDate")}
            />
          </div>

          <div className="item">
            <div className="input_label">
              <i className="far fa-calendar-alt"></i>
              {__("End Day")}
            </div>
            <DateSelector
              className="filter_group"
              value={form.endDate}
              startYear={2022}
              endYear={year}
              onChange={(itemValue) => updateForm(itemValue, "endDate")}
            />
          </div>
        </div>

        <div className="filter_btns_v1">
          <Link className="btn">{__("Export")}</Link>
          <Link onClick={doReset} className="btn">
            {__("Reset")}
          </Link>
          <Link onClick={getResults} className="btn search">
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.43038 0.401962C11.4787 0.401962 14.7722 3.69549 14.7722 7.74384C14.7722 9.14045 14.3801 10.4471 13.7004 11.5598C17.5536 14.5884 17.9793 15.014 18.1229 15.1575L19.1938 16.2285C20.0978 17.1325 20.0978 18.6033 19.1938 19.5073C18.7419 19.9593 18.1481 20.1853 17.5544 20.1853C16.9608 20.1853 16.367 19.9593 15.9151 19.5073L14.8441 18.4363C14.7013 18.2936 14.2746 17.8667 11.264 14.0029C10.1474 14.6893 8.83442 15.0857 7.43038 15.0857C6.32725 15.0857 5.26639 14.8469 4.27729 14.3758C3.89192 14.1923 3.72831 13.7311 3.91182 13.3458C4.09537 12.9604 4.55655 12.7967 4.94184 12.9804C5.72174 13.3518 6.55898 13.5401 7.43038 13.5401C10.6264 13.5401 13.2266 10.9399 13.2266 7.74384C13.2266 4.5478 10.6264 1.94762 7.43038 1.94762C4.23436 1.94762 1.63419 4.5478 1.63419 7.74384C1.63419 8.79067 1.916 9.81621 2.44921 10.7096C2.66796 11.076 2.54817 11.5505 2.18166 11.7693C1.8151 11.988 1.3407 11.8682 1.12196 11.5017C0.445848 10.3689 0.0884933 9.06951 0.0884933 7.74384C0.0885334 3.69549 3.38209 0.401962 7.43038 0.401962ZM15.937 17.3434L17.0079 18.4144C17.3093 18.7157 17.7996 18.7157 18.1009 18.4144C18.4022 18.113 18.4022 17.6228 18.1009 17.3215L17.0299 16.2505C16.8384 16.059 16.0237 15.3521 12.7608 12.7872C12.6725 12.8805 12.5819 12.9716 12.4889 13.0602C15.0453 16.3409 15.7472 17.1536 15.937 17.3434ZM3.52958 6.05312C3.35967 6.44467 3.53936 6.89979 3.93091 7.06974C4.03107 7.11321 4.13548 7.13376 4.23815 7.13376C4.53677 7.13376 4.82105 6.95972 4.94749 6.66837C5.37702 5.67853 6.3516 5.03893 7.43038 5.03893C7.85722 5.03893 8.20321 4.69294 8.20321 4.26611C8.20321 3.83927 7.85722 3.49328 7.43038 3.49328C5.7355 3.49328 4.20434 4.49807 3.52958 6.05312Z"
                fill="#10294F"
              />
            </svg>
          </Link>
        </div>
        <div className="width_100 results_v1">
          {results.length ? (
            <>
              {results.map((item, index) => (
                <>
                  <div
                    key={index}
                    className="single_block transaction_history_result_v1"
                  >
                    {!props.isMobileDevice && (
                      <>
                        <div className="item">
                          <div className="history_games">
                            <GameImage src={item.game?.game_snapshot_url} />
                          </div>
                        </div>
                        <div className="item">
                          <div className="game_name">
                            {" "}
                            {item.game?.game_name}
                          </div>
                          <div className="game_name provider">
                            {item.game?.provider?.name}
                          </div>
                          <div className="datetime">{item.record_date}</div>
                        </div>
                      </>
                    )}
                    {props.isMobileDevice && (
                      <>
                        <div className="item">
                          <div
                            className="history_games"
                            style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr",
                              alignItems: "center",
                            }}
                          >
                            <GameImage src={item.game?.game_snapshot_url} />
                            <div className="item">
                              <div className="game_name">
                                {" "}
                                {item.game?.game_name}
                              </div>
                              <div className="game_name provider">
                                {item.game?.provider?.name}
                              </div>
                              <div className="datetime">{item.record_date}</div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="item">
                      <div className="input_label">
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.87005 0H1.96589C0.881908 0 0 0.881908 0 1.96589V5.87005C0 6.95403 0.881908 7.83594 1.96589 7.83594H5.87005C6.95403 7.83594 7.83594 6.95403 7.83594 5.87005V1.96589C7.83594 0.881908 6.95403 0 5.87005 0ZM6.50781 5.87005C6.50781 6.2217 6.2217 6.50781 5.87005 6.50781H1.96589C1.61424 6.50781 1.32812 6.2217 1.32812 5.87005V1.96589C1.32812 1.61424 1.61424 1.32812 1.96589 1.32812H5.87005C6.2217 1.32812 6.50781 1.61424 6.50781 1.96589V5.87005Z"
                            fill="white"
                            fill-opacity="0.91"
                          />
                          <path
                            d="M15.0078 0H11.1562C10.0578 0 9.16406 0.893695 9.16406 1.99219V5.84375C9.16406 6.94224 10.0578 7.83594 11.1562 7.83594H15.0078C16.1063 7.83594 17 6.94224 17 5.84375V1.99219C17 0.893695 16.1063 0 15.0078 0ZM15.6719 5.84375C15.6719 6.20991 15.374 6.50781 15.0078 6.50781H11.1562C10.7901 6.50781 10.4922 6.20991 10.4922 5.84375V1.99219C10.4922 1.62602 10.7901 1.32812 11.1562 1.32812H15.0078C15.374 1.32812 15.6719 1.62602 15.6719 1.99219V5.84375Z"
                            fill="white"
                            fill-opacity="0.91"
                          />
                          <path
                            d="M5.87005 9.16406H1.96589C0.881908 9.16406 0 10.046 0 11.13V15.0341C0 16.1181 0.881908 17 1.96589 17H5.87005C6.95403 17 7.83594 16.1181 7.83594 15.0341V11.13C7.83594 10.046 6.95403 9.16406 5.87005 9.16406ZM6.50781 15.0341C6.50781 15.3858 6.2217 15.6719 5.87005 15.6719H1.96589C1.61424 15.6719 1.32812 15.3858 1.32812 15.0341V11.13C1.32812 10.7783 1.61424 10.4922 1.96589 10.4922H5.87005C6.2217 10.4922 6.50781 10.7783 6.50781 11.13V15.0341Z"
                            fill="white"
                          />
                          <path
                            d="M15.0078 9.16406H11.1562C10.0578 9.16406 9.16406 10.0578 9.16406 11.1562V15.0078C9.16406 16.1063 10.0578 17 11.1562 17H15.0078C16.1063 17 17 16.1063 17 15.0078V11.1562C17 10.0578 16.1063 9.16406 15.0078 9.16406ZM15.6719 15.0078C15.6719 15.374 15.374 15.6719 15.0078 15.6719H11.1562C10.7901 15.6719 10.4922 15.374 10.4922 15.0078V11.1562C10.4922 10.7901 10.7901 10.4922 11.1562 10.4922H15.0078C15.374 10.4922 15.6719 10.7901 15.6719 11.1562V15.0078Z"
                            fill="white"
                          />
                        </svg>
                        {__("Type")}
                      </div>
                      <input
                        disabled={true}
                        type="text"
                        className="form-controll input_box active"
                        value={item.type}
                      />
                    </div>
                    <div className="item">
                      <div className="input_label">
                        <svg
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.5 0C4.26169 0 0 4.26169 0 9.5C0 14.7383 4.26169 19 9.5 19C14.7383 19 19 14.7383 19 9.5C19 4.26169 14.7383 0 9.5 0V0ZM9.5 17.3268C5.18427 17.3268 1.67315 13.8157 1.67315 9.5C1.67315 5.18427 5.18427 1.67315 9.5 1.67315C13.8157 1.67315 17.3268 5.18427 17.3268 9.5C17.3268 13.8157 13.8157 17.3268 9.5 17.3268Z"
                            fill="white"
                            fill-opacity="0.91"
                          />
                          <path
                            d="M9.50007 6.78161C10.0189 6.78161 10.441 7.20369 10.441 7.72253H12.1141C12.1141 6.57369 11.3689 5.59629 10.3366 5.24655V3.88688H8.66349V5.24655C7.63126 5.59629 6.88599 6.57369 6.88599 7.72253C6.88599 9.16396 8.05864 10.3366 9.50007 10.3366C10.0189 10.3366 10.441 10.7587 10.441 11.2775C10.441 11.7964 10.0189 12.2185 9.50007 12.2185C8.98122 12.2185 8.55914 11.7964 8.55914 11.2775H6.88599C6.88599 12.4264 7.63126 13.4038 8.66349 13.7535V15.1132H10.3366V13.7535C11.3689 13.4038 12.1141 12.4264 12.1141 11.2775C12.1141 9.83612 10.9415 8.66346 9.50007 8.66346C8.98122 8.66346 8.55914 8.24138 8.55914 7.72253C8.55914 7.20369 8.98122 6.78161 9.50007 6.78161Z"
                            fill="white"
                            fill-opacity="0.91"
                          />
                        </svg>

                        {__("Amount")}
                      </div>
                      <input
                        disabled={true}
                        type="text"
                        className="form-controll input_box active"
                        value={item.amount}
                      />
                    </div>
                    <div className="item">
                      <div className="input_label">
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.87005 0H1.96589C0.881908 0 0 0.881908 0 1.96589V5.87005C0 6.95403 0.881908 7.83594 1.96589 7.83594H5.87005C6.95403 7.83594 7.83594 6.95403 7.83594 5.87005V1.96589C7.83594 0.881908 6.95403 0 5.87005 0ZM6.50781 5.87005C6.50781 6.2217 6.2217 6.50781 5.87005 6.50781H1.96589C1.61424 6.50781 1.32812 6.2217 1.32812 5.87005V1.96589C1.32812 1.61424 1.61424 1.32812 1.96589 1.32812H5.87005C6.2217 1.32812 6.50781 1.61424 6.50781 1.96589V5.87005Z"
                            fill="white"
                            fill-opacity="0.91"
                          />
                          <path
                            d="M15.0078 0H11.1562C10.0578 0 9.16406 0.893695 9.16406 1.99219V5.84375C9.16406 6.94224 10.0578 7.83594 11.1562 7.83594H15.0078C16.1063 7.83594 17 6.94224 17 5.84375V1.99219C17 0.893695 16.1063 0 15.0078 0ZM15.6719 5.84375C15.6719 6.20991 15.374 6.50781 15.0078 6.50781H11.1562C10.7901 6.50781 10.4922 6.20991 10.4922 5.84375V1.99219C10.4922 1.62602 10.7901 1.32812 11.1562 1.32812H15.0078C15.374 1.32812 15.6719 1.62602 15.6719 1.99219V5.84375Z"
                            fill="white"
                            fill-opacity="0.91"
                          />
                          <path
                            d="M5.87005 9.16406H1.96589C0.881908 9.16406 0 10.046 0 11.13V15.0341C0 16.1181 0.881908 17 1.96589 17H5.87005C6.95403 17 7.83594 16.1181 7.83594 15.0341V11.13C7.83594 10.046 6.95403 9.16406 5.87005 9.16406ZM6.50781 15.0341C6.50781 15.3858 6.2217 15.6719 5.87005 15.6719H1.96589C1.61424 15.6719 1.32812 15.3858 1.32812 15.0341V11.13C1.32812 10.7783 1.61424 10.4922 1.96589 10.4922H5.87005C6.2217 10.4922 6.50781 10.7783 6.50781 11.13V15.0341Z"
                            fill="white"
                          />
                          <path
                            d="M15.0078 9.16406H11.1562C10.0578 9.16406 9.16406 10.0578 9.16406 11.1562V15.0078C9.16406 16.1063 10.0578 17 11.1562 17H15.0078C16.1063 17 17 16.1063 17 15.0078V11.1562C17 10.0578 16.1063 9.16406 15.0078 9.16406ZM15.6719 15.0078C15.6719 15.374 15.374 15.6719 15.0078 15.6719H11.1562C10.7901 15.6719 10.4922 15.374 10.4922 15.0078V11.1562C10.4922 10.7901 10.7901 10.4922 11.1562 10.4922H15.0078C15.374 10.4922 15.6719 10.7901 15.6719 11.1562V15.0078Z"
                            fill="white"
                          />
                        </svg>
                        {__("Balance Type")}
                      </div>
                      <input
                        disabled={true}
                        type="text"
                        className="form-controll input_box active"
                        value={item.using_bonus}
                      />
                    </div>

                    <div className="item">
                      <div className="input_label">
                        <svg
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.36969 7.43489H3.7175C3.4895 7.43489 3.30444 7.61995 3.30444 7.84795V11.1523C3.30444 11.3803 3.4895 11.5653 3.7175 11.5653C3.9455 11.5653 4.13056 11.3803 4.13056 11.1523V8.26096H5.36969C5.59769 8.26096 5.78275 8.07591 5.78275 7.84791C5.78275 7.61991 5.59769 7.43489 5.36969 7.43489Z"
                            fill="white"
                            fill-opacity="0.91"
                          />
                          <path
                            d="M4.54358 9.08703H3.7175C3.4895 9.08703 3.30444 9.27209 3.30444 9.50009C3.30444 9.72809 3.4895 9.9131 3.7175 9.9131H4.54358C4.77158 9.9131 4.95663 9.72805 4.95663 9.50005C4.95663 9.27205 4.77158 9.08703 4.54358 9.08703Z"
                            fill="white"
                            fill-opacity="0.91"
                          />
                          <path
                            d="M9.5 0C4.2618 0 0 4.2618 0 9.5C0 14.7382 4.2618 19 9.5 19C14.7382 19 19 14.7382 19 9.5C19 4.2618 14.7382 0 9.5 0ZM9.5 18.1739C4.71695 18.1739 0.826075 14.2831 0.826075 9.5C0.826075 4.71695 4.71695 0.826075 9.5 0.826075C14.2831 0.826075 18.1739 4.71695 18.1739 9.5C18.1739 14.2831 14.2831 18.1739 9.5 18.1739Z"
                            fill="white"
                          />
                          <path
                            d="M14.9761 5.11107C13.6337 3.43824 11.6378 2.47834 9.49991 2.47834C7.36199 2.47834 5.36617 3.43824 4.02376 5.11107C3.88084 5.28869 3.90895 5.54891 4.08739 5.6918C4.26501 5.83472 4.52523 5.80661 4.6673 5.62817C5.85272 4.15113 7.61396 3.30438 9.49991 3.30438C11.3859 3.30438 13.1471 4.15113 14.3317 5.62817C14.4135 5.72978 14.5333 5.78264 14.6539 5.78264C14.7456 5.78264 14.8365 5.7529 14.9124 5.69176C15.0909 5.54891 15.119 5.28869 14.9761 5.11107Z"
                            fill="white"
                          />
                          <path
                            d="M14.5754 13.7436C14.4119 13.5875 14.1492 13.5941 13.9922 13.7593C12.8068 15.0075 11.2116 15.6956 9.49995 15.6956C7.78828 15.6956 6.19313 15.0075 5.00852 13.7593C4.85239 13.5925 4.58969 13.5875 4.42531 13.7436C4.25846 13.9006 4.25265 14.1625 4.40963 14.3277C5.75204 15.7428 7.5595 16.5218 9.49999 16.5218C11.4405 16.5218 13.2479 15.7428 14.5912 14.3277C14.7481 14.1625 14.7415 13.9006 14.5754 13.7436Z"
                            fill="white"
                          />
                          <path
                            d="M8.00805 9.90243C8.61604 9.82394 9.08691 9.30269 9.08691 8.67402C9.08691 7.99083 8.53096 7.43489 7.84777 7.43489H7.0217C6.7937 7.43489 6.60864 7.61995 6.60864 7.84795V9.4968C6.60864 9.49847 6.60864 9.50095 6.60864 9.50257V11.1523C6.60864 11.3803 6.7937 11.5653 7.0217 11.5653C7.2497 11.5653 7.43476 11.3803 7.43476 11.1523V10.4972L8.38145 11.4447C8.46242 11.5248 8.56814 11.5653 8.67389 11.5653C8.77964 11.5653 8.88535 11.5249 8.96551 11.4439C9.1266 11.2828 9.1266 11.0209 8.96551 10.8599L8.00805 9.90243ZM7.43476 9.08708V8.261H7.84781C8.075 8.261 8.26087 8.44606 8.26087 8.67406C8.26087 8.90206 8.075 9.08712 7.84781 9.08712H7.43476V9.08708Z"
                            fill="white"
                          />
                          <path
                            d="M11.9783 10.7392H10.7392V8.26096H11.9783C12.2063 8.26096 12.3914 8.07591 12.3914 7.84791C12.3914 7.61991 12.2063 7.43489 11.9783 7.43489H10.3261C10.0981 7.43489 9.91309 7.61995 9.91309 7.84795V11.1523C9.91309 11.3803 10.0981 11.5653 10.3261 11.5653H11.9783C12.2063 11.5653 12.3914 11.3803 12.3914 11.1523C12.3914 10.9243 12.2063 10.7392 11.9783 10.7392Z"
                            fill="white"
                          />
                          <path
                            d="M11.1522 9.08703H10.3261C10.0981 9.08703 9.91309 9.27209 9.91309 9.50009C9.91309 9.72809 10.0981 9.9131 10.3261 9.9131H11.1522C11.3802 9.9131 11.5653 9.72805 11.5653 9.50005C11.5653 9.27205 11.3802 9.08703 11.1522 9.08703Z"
                            fill="white"
                          />
                          <path
                            d="M15.2825 10.7392H14.0434V8.26096H15.2825C15.5105 8.26096 15.6956 8.07591 15.6956 7.84791C15.6956 7.61991 15.5105 7.43489 15.2825 7.43489H13.6303C13.4023 7.43489 13.2173 7.61995 13.2173 7.84795V11.1523C13.2173 11.3803 13.4023 11.5653 13.6303 11.5653H15.2825C15.5105 11.5653 15.6956 11.3803 15.6956 11.1523C15.6956 10.9243 15.5105 10.7392 15.2825 10.7392Z"
                            fill="white"
                          />
                          <path
                            d="M14.4564 9.08703H13.6303C13.4023 9.08703 13.2173 9.27209 13.2173 9.50009C13.2173 9.72809 13.4023 9.9131 13.6303 9.9131H14.4564C14.6844 9.9131 14.8695 9.72805 14.8695 9.50005C14.8695 9.27205 14.6844 9.08703 14.4564 9.08703Z"
                            fill="white"
                          />
                        </svg>

                        {__("Freeround")}
                      </div>
                      <input
                        disabled={true}
                        type="text"
                        className="form-controll input_box active"
                        value={item.is_freeround}
                      />
                    </div>
                    <div className="item">
                      <div className="input_label">
                        <svg
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.9527 10.8489L12.2266 5.1853L13.566 0.574018C13.6042 0.440081 13.5851 0.287009 13.4894 0.172206C13.4129 0.0574019 13.279 0 13.1259 0C12.4945 0 12.1501 0.210473 11.8822 0.363545C11.6526 0.497482 11.4804 0.593152 11.0977 0.593152C10.715 0.593152 10.5428 0.497482 10.3132 0.363545C10.0453 0.210473 9.70091 0 9.06949 0C8.43807 0 8.09366 0.210473 7.82578 0.363545C7.59617 0.497482 7.42397 0.593152 7.04129 0.593152C6.65861 0.593152 6.48641 0.497482 6.2568 0.363545C5.98892 0.210473 5.64451 0 5.01309 0C4.87916 0 4.74522 0.0574019 4.64955 0.172206C4.57301 0.287009 4.53474 0.440081 4.57301 0.574018L5.91239 5.1853L1.14804 10.8489C0.401813 11.7482 0 12.8771 0 14.0252C0 16.7613 2.23867 19 4.97482 19H13.145C15.8812 19 18.1198 16.7613 18.1198 14.0252C18.1007 12.858 17.6989 11.7291 16.9527 10.8489ZM5.75932 1.1289C6.02719 1.28197 6.3716 1.49245 7.00302 1.49245C7.63444 1.49245 7.97885 1.28197 8.24673 1.1289C8.49547 0.994965 8.66768 0.880161 9.05035 0.880161C9.43303 0.880161 9.60524 0.975831 9.83484 1.10977C10.1027 1.26284 10.4471 1.47331 11.0977 1.47331C11.7291 1.47331 12.0735 1.26284 12.3414 1.10977C12.3988 1.0715 12.4371 1.05237 12.4753 1.03323L11.3464 4.95569H6.75428L5.62538 1.03323C5.66365 1.0715 5.72105 1.09063 5.75932 1.1289ZM13.1259 18.1007H4.97482C2.73615 18.1007 0.899295 16.283 0.899295 14.0252C0.899295 13.0685 1.24371 12.1501 1.83686 11.423L6.46727 5.87412H11.6143L16.2447 11.423C16.857 12.1501 17.1823 13.0685 17.1823 14.0252C17.2014 16.2638 15.3837 18.1007 13.1259 18.1007Z"
                            fill="white"
                            fill-opacity="0.91"
                          />
                          <path
                            d="M9.05039 9.94964C9.54787 9.94964 9.94968 10.3515 9.94968 10.8489C9.94968 11.0977 10.1602 11.3081 10.4089 11.3081C10.6576 11.3081 10.8681 11.0977 10.8681 10.8489C10.8681 10.007 10.2941 9.29908 9.5096 9.08861V8.57199C9.5096 8.32325 9.29913 8.11278 9.05039 8.11278C8.80165 8.11278 8.59118 8.32325 8.59118 8.57199V9.08861C7.80668 9.29908 7.23267 10.007 7.23267 10.8489C7.23267 11.8439 8.03629 12.6667 9.05039 12.6667C9.54787 12.6667 9.94968 13.0685 9.94968 13.566C9.94968 14.0634 9.54787 14.4652 9.05039 14.4652C8.55291 14.4652 8.15109 14.0634 8.15109 13.566C8.15109 13.3172 7.94062 13.1067 7.69188 13.1067C7.44314 13.1067 7.23267 13.3172 7.23267 13.566C7.23267 14.4078 7.80668 15.1158 8.59118 15.3263V15.8429C8.59118 16.0916 8.80165 16.3021 9.05039 16.3021C9.29913 16.3021 9.5096 16.0916 9.5096 15.8429V15.3263C10.2941 15.1158 10.8681 14.4078 10.8681 13.566C10.8681 12.571 10.0645 11.7482 9.05039 11.7482C8.55291 11.7482 8.15109 11.3464 8.15109 10.8489C8.15109 10.3515 8.55291 9.94964 9.05039 9.94964Z"
                            fill="white"
                            fill-opacity="0.91"
                          />
                        </svg>

                        {__("Cash balance before")}
                      </div>
                      <input
                        disabled={true}
                        type="text"
                        className="form-controll input_box active"
                        value={item.balance_before}
                      />
                    </div>
                    <div className="item">
                      <div className="input_label">
                        <svg
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.9527 10.8489L12.2266 5.1853L13.566 0.574018C13.6042 0.440081 13.5851 0.287009 13.4894 0.172206C13.4129 0.0574019 13.279 0 13.1259 0C12.4945 0 12.1501 0.210473 11.8822 0.363545C11.6526 0.497482 11.4804 0.593152 11.0977 0.593152C10.715 0.593152 10.5428 0.497482 10.3132 0.363545C10.0453 0.210473 9.70091 0 9.06949 0C8.43807 0 8.09366 0.210473 7.82578 0.363545C7.59617 0.497482 7.42397 0.593152 7.04129 0.593152C6.65861 0.593152 6.48641 0.497482 6.2568 0.363545C5.98892 0.210473 5.64451 0 5.01309 0C4.87916 0 4.74522 0.0574019 4.64955 0.172206C4.57301 0.287009 4.53474 0.440081 4.57301 0.574018L5.91239 5.1853L1.14804 10.8489C0.401813 11.7482 0 12.8771 0 14.0252C0 16.7613 2.23867 19 4.97482 19H13.145C15.8812 19 18.1198 16.7613 18.1198 14.0252C18.1007 12.858 17.6989 11.7291 16.9527 10.8489ZM5.75932 1.1289C6.02719 1.28197 6.3716 1.49245 7.00302 1.49245C7.63444 1.49245 7.97885 1.28197 8.24673 1.1289C8.49547 0.994965 8.66768 0.880161 9.05035 0.880161C9.43303 0.880161 9.60524 0.975831 9.83484 1.10977C10.1027 1.26284 10.4471 1.47331 11.0977 1.47331C11.7291 1.47331 12.0735 1.26284 12.3414 1.10977C12.3988 1.0715 12.4371 1.05237 12.4753 1.03323L11.3464 4.95569H6.75428L5.62538 1.03323C5.66365 1.0715 5.72105 1.09063 5.75932 1.1289ZM13.1259 18.1007H4.97482C2.73615 18.1007 0.899295 16.283 0.899295 14.0252C0.899295 13.0685 1.24371 12.1501 1.83686 11.423L6.46727 5.87412H11.6143L16.2447 11.423C16.857 12.1501 17.1823 13.0685 17.1823 14.0252C17.2014 16.2638 15.3837 18.1007 13.1259 18.1007Z"
                            fill="white"
                            fill-opacity="0.91"
                          />
                          <path
                            d="M9.05039 9.94964C9.54787 9.94964 9.94968 10.3515 9.94968 10.8489C9.94968 11.0977 10.1602 11.3081 10.4089 11.3081C10.6576 11.3081 10.8681 11.0977 10.8681 10.8489C10.8681 10.007 10.2941 9.29908 9.5096 9.08861V8.57199C9.5096 8.32325 9.29913 8.11278 9.05039 8.11278C8.80165 8.11278 8.59118 8.32325 8.59118 8.57199V9.08861C7.80668 9.29908 7.23267 10.007 7.23267 10.8489C7.23267 11.8439 8.03629 12.6667 9.05039 12.6667C9.54787 12.6667 9.94968 13.0685 9.94968 13.566C9.94968 14.0634 9.54787 14.4652 9.05039 14.4652C8.55291 14.4652 8.15109 14.0634 8.15109 13.566C8.15109 13.3172 7.94062 13.1067 7.69188 13.1067C7.44314 13.1067 7.23267 13.3172 7.23267 13.566C7.23267 14.4078 7.80668 15.1158 8.59118 15.3263V15.8429C8.59118 16.0916 8.80165 16.3021 9.05039 16.3021C9.29913 16.3021 9.5096 16.0916 9.5096 15.8429V15.3263C10.2941 15.1158 10.8681 14.4078 10.8681 13.566C10.8681 12.571 10.0645 11.7482 9.05039 11.7482C8.55291 11.7482 8.15109 11.3464 8.15109 10.8489C8.15109 10.3515 8.55291 9.94964 9.05039 9.94964Z"
                            fill="white"
                            fill-opacity="0.91"
                          />
                        </svg>

                        {__("Cash balance")}
                      </div>
                      <input
                        disabled={true}
                        type="text"
                        className="form-controll input_box active"
                        value={item.balance_after}
                      />
                    </div>
                  </div>
                </>
              ))}

              <div className="pagination">
                <Pagination data={pagination} onPageChange={onPageChange} />
              </div>
            </>
          ) : (
            <NoRecordsFound />
          )}
        </div>
      </div>
    </>
  );
}
