import React from "react";
import { __ } from "services/Translator";
import { Redirect, Route, Link, useParams } from "react-router-dom";
export default function ProviderText(props) {
  return (
    <div className="container-md">
      <div className="provider_text_area">
        {props.selectedProviders.includes("x2gaming") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("1X2 Gaming")}</h2>
              <p className="provider_text">
                {__(
                  "1X2 Gaming is a UK-based game provider that was founded in 2002. Initially focusing on virtual sports games, the company has since expanded its portfolio to include a range of online slot games. 1X2 Gaming's slot games are known for their unique themes, excellent graphics, and engaging gameplay. Their portfolio covers a wide range of themes, from classic fruit machines to modern video slots with unique bonus features. Some of their most popular games include Blood Queen, Rainbow Wilds, and Book of Merlin. One of the strengths of 1X2 Gaming is their commitment to mobile gaming, with all their games developed using HTML5 technology to ensure they are fully optimized for mobile devices. Players can enjoy 1X2 Gaming's games on their smartphones and tablets, as well as on desktop computers."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("3oaks") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("3 Oaks Gaming")}</h2>
              <p className="provider_text">
                {__(
                  "3 Oaks Gaming is a renowned game development company that specializes in creating innovative and engaging games for a global audience. Established in 2021, the company has been at the forefront of the gaming industry, producing top-notch games that cater to a diverse range of gaming preferences. With a team of experienced developers and designers, 3 Oaks Gaming is committed to delivering high-quality gaming experiences that keep players coming back for more. Whether you're a fan of action-packed shooters, immersive RPGs, slots, or casual puzzle games, They have something for everyone. Above on this page, you will find its impressive portfolio of 3 Oaks Gaming slot games."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("belatra") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Belatra Games")}</h2>
              <p className="provider_text">
                {__(
                  "Belatra Games is a leading game development company that has been providing top-notch gaming solutions to players around the world since 1993. With a team of talented developers, designers, and programmers, Belatra Games has become renowned for creating cutting-edge games that offer immersive gameplay, stunning graphics, and exciting features. The company has a vast portfolio of games, including video slots, poker, and electronic table games, that cater to a diverse range of player preferences. Belatra Games is committed to staying ahead of the curve when it comes to technology and design, and it shows in the quality of their games. Whether you're a fan of classic casino games or looking for something new and exciting, Belatra Games has you covered. Down below, you will find their impressive lineup of slot games."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("bsg") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Betsoft")}</h2>
              <p className="provider_text">
                {__(
                  "Betsoft is a prominent game development company that has been providing top-quality gaming solutions to players around the globe since 1999. The company is renowned for its impressive portfolio of visually stunning and immersive games that offer players an unparalleled gaming experience. Betsoft's team of talented developers and designers are dedicated to creating innovative games that push the boundaries of what is possible in the gaming industry. Their games range from classic slots to cutting-edge 3D slots, as well as a range of table games and video poker games. With a commitment to quality and innovation, Betsoft has become a go-to choice for online casino operators and players alike. Whether you're a seasoned player or a newcomer to the world of online gaming, Betsoft has something for everyone. Above on this page, you can delve deeper into their impressive range of slot games."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("bgaming") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("B Gaming")}</h2>
              <p className="provider_text">
                {__(
                  "B Gaming is a dynamic game development company that has been providing innovative gaming solutions since 2018. The company specializes in creating engaging games that offer a unique and thrilling experience for players around the world. B Gaming's team of talented developers and designers work tirelessly to develop games that are visually stunning, with cutting-edge graphics and sound effects that bring the game to life. Their game portfolio includes a wide range of games, from classic slots to video poker and table games, with something to suit all types of players. B Gaming slots are committed to providing a fair and secure gaming environment for its players, utilizing the latest technology to ensure that their games are provably fair. Above, you can explore B Gaming slots and their impressive range of games."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("bigtimegaming") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Big Time Gaming")}</h2>
              <p className="provider_text">
                {__(
                  "Big Time Gaming (BTG) is a leading game provider in the online gambling industry that was founded in 2011. The company is renowned for its high-quality online slot games that are known for their innovative features, excellent graphics, and engaging gameplay. One of the key features that sets BTG apart from other game providers is their patented Megaways™ mechanic, which has revolutionized the online slot industry. Megaways™ allows for a large number of potential paylines, often reaching up to 117,649, creating more opportunities for players to win big. BTG's portfolio of slot games is diverse and covers a wide range of themes, from classic fruit machines to modern video slots with unique bonus features. Some of their most popular games include Bonanza, Extra Chilli, and Who Wants to Be a Millionaire."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("boomerangstudios") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Boomerang Studios")}</h2>
              <p className="provider_text">
                {__(
                  "Boomerang Studios is a relatively new and exciting game provider in the iGaming industry that was founded in 2018. Despite being a new entrant, the provider has quickly gained recognition for its impressive portfolio of high-quality online slot games. Boomerang Studios' slots are known for their impressive graphics, innovative features, and captivating themes that are designed to keep players entertained and engaged. Their games cover a wide range of themes, from classic fruit machines to modern video slots with unique bonus features. Overall, Boomerang Studios is a promising game provider with a growing portfolio of impressive slot games. With its focus on innovation, mobile gaming, and responsible gaming practices, Boomerang Studios is a provider to watch in the online casino industry."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("booming") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Booming Games")}</h2>
              <p className="provider_text">
                {__(
                  "Booming Games is a dynamic game development company that has been providing top-notch gaming solutions to players around the world since 2014. The company has a passion for creating innovative and engaging games that offer players an unparalleled gaming experience. Booming Games' team of experienced developers and designers work tirelessly to create visually stunning games that are packed with exciting features, immersive gameplay, and high-quality graphics. Their game portfolio includes a vast range of games, from classic slots to modern video slots, table games, and scratch cards. Booming Games is dedicated to creating fair and secure gaming environments for its players, utilizing the latest technology to ensure that their games are provably fair. Above, you can explore Booming Games and their impressive range of games."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("booming") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Booming Games")}</h2>
              <p className="provider_text">
                {__(
                  "Booming Games is a dynamic game development company that has been providing top-notch gaming solutions to players around the world since 2014. The company has a passion for creating innovative and engaging games that offer players an unparalleled gaming experience. Booming Games' team of experienced developers and designers work tirelessly to create visually stunning games that are packed with exciting features, immersive gameplay, and high-quality graphics. Their game portfolio includes a vast range of games, from classic slots to modern video slots, table games, and scratch cards. Booming Games is dedicated to creating fair and secure gaming environments for its players, utilizing the latest technology to ensure that their games are provably fair. Above, you can explore Booming Games and their impressive range of games."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("booongo") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Booongo games")}</h2>
              <p className="provider_text">
                {__(
                  "Booongo Games is an innovative game development company that has been providing exceptional gaming solutions to players around the world since 2015. The company is known for its cutting-edge games that offer players an exciting and immersive gaming experience. Booongo Games' team of talented developers and designers are dedicated to creating games that are visually stunning, with high-quality graphics, animations, and sound effects. Their game portfolio includes a wide range of games, from classic slots to modern video slots, table games, and scratch cards. Booongo Games is committed to creating a fair and secure gaming environment for its players, utilizing the latest technology to ensure that their games are provably fair. Above on this page, you can explore Booongo and their impressive range of slot games."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("endorphina") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Endorphina")}</h2>
              <p className="provider_text">
                {__(
                  "Endorphina is a reputable game development company that has been providing high-quality gaming solutions to players around the world since 2012. The company has gained a reputation for creating visually stunning and innovative games that offer players an exceptional gaming experience. Endorphina's team of talented developers and designers work tirelessly to create games that are not only visually appealing but also packed with exciting features and immersive gameplay. Their game portfolio includes a vast range of games, from classic slots to modern video slots, table games, and scratch cards. Endorphina is dedicated to providing a fair and secure gaming environment for its players, utilizing the latest technology to ensure that their games are provably fair. You can see all of their slot games on this page."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("evolution") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Evolution Gaming")}</h2>
              <p className="provider_text">
                {__(
                  "Evolution Gaming is a leading provider of live dealer casino games, specializing in the development of innovative and engaging live casino solutions. The company was founded in 2006 and has quickly established itself as a major player in the industry, with a portfolio of live casino games that are available at top online casinos. Evolution Gaming's portfolio of live dealer games includes a range of classic casino games, such as blackjack, roulette, baccarat, and poker, as well as a selection of innovative and unique games, such as Lightning Roulette and Dream Catcher. Their live casino games are hosted by professional dealers in state-of-the-art studios, and offer a range of interactive features such as chat functionality and customizable options. In addition to their live dealer games, Evolution Gaming also offers a range of other casino solutions, including online slots and virtual sports betting."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("fazi") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Fazi")}</h2>
              <p className="provider_text">
                {__(
                  "Fazi is a renowned game development company that has been providing high-quality gaming solutions to players around the world since 1991. The company is known for creating innovative and engaging games that offer players an exciting and immersive gaming experience. Fazi's team of experienced developers and designers work tirelessly to create visually stunning games that are packed with exciting features, immersive gameplay, and high-quality graphics. Their game portfolio includes a wide range of games, from classic slots to modern video slots, table games, and electronic roulette machines."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("foxium") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Foxium")}</h2>
              <p className="provider_text">
                {__(
                  "Foxium is a leading provider of online casino games, specializing in the development of high-quality slot games with engaging gameplay, immersive themes, and innovative features. The company was founded in 2015 and has quickly established itself as a major player in the industry, with a portfolio of over 15 games that are available at top online casinos. Foxium's portfolio of slot games is known for its impressive graphics and visual effects, as well as its focus on storytelling and immersive gameplay. Their games often feature unique and unconventional themes, such as 'Astro Legends: Lyra and Erion' and 'Stumpy McDoodles', and offer a range of innovative features such as expanding wilds, cascading reels, and free spins. In addition to their slot games, Foxium also offers a range of other casino games, including table games and video poker."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("gameart") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("GameArt")}</h2>
              <p className="provider_text">
                {__(
                  "GameArt is a leading online casino game developer that specializes in creating high-quality slot games with engaging gameplay, stunning graphics, and innovative features. Founded in 2013, the company has rapidly grown to become a major player in the industry, with a portfolio of over 100 games that are available to players around the world. GameArt's portfolio of slot games is diverse, with a range of themes to appeal to different types of players. They are particularly known for their focus on creating games with a strong visual appeal, featuring intricate graphics and detailed animations. Additionally, they offer games with a range of innovative features, such as expanding wilds, bonus rounds, and free spins. In addition to their slot games, GameArt also offers a range of other casino games, including table games and video poker. They work with a range of online casino operators to offer their games to players around the world, and are licensed and regulated by several regulatory bodies."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("gamebeat") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Gamebeat")}</h2>
              <p className="provider_text">
                {__(
                  "Gamebeat is an innovative game development company that has been providing exceptional gaming solutions to players around the world since 2020. The company is known for its cutting-edge games that offer players an exciting and immersive gaming experience. Gamebeat's team of talented developers and designers are dedicated to creating games that are visually stunning, with high-quality graphics, animations, and sound effects. Their game portfolio includes a wide range of games, from classic slots to modern video slots, table games, and scratch cards. Gamebeat is committed to creating a fair and secure gaming environment for its players, utilizing the latest technology to ensure that their games are provably fair. Here you can explore Gamebeat and their impressive range of games."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("gameslab") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("GameLab")}</h2>
              <p className="provider_text">
                {__(
                  "GameLab is a relatively new slot provider in the online gaming industry, having been founded in 2019. However, the company has already established itself as a developer of high-quality and engaging slot games that offer players an immersive gaming experience. GameLab's portfolio of slot games is relatively small, but it is growing rapidly, with new titles being added on a regular basis. Their games feature a range of themes, from classic fruit machines to more modern video slots with innovative features such as expanding wilds, bonus rounds, and free spins. One of the key features of GameLab's slot games is their focus on creating games with simple, intuitive gameplay. Their games are easy to play and understand, making them accessible to players of all skill levels. Additionally, they work with a range of online casino operators to offer exclusive games that are only available on specific platforms."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("gamevy") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Gamevy")}</h2>
              <p className="provider_text">
                {__(
                  "Gamevy is a cutting-edge software company that specializes in creating innovative gaming experiences for online casinos, lotteries, and other gaming platforms. With a team of skilled developers and designers, Gamevy has quickly become a leader in the iGaming industry, delivering unique games that engage and entertain players of all types. Founded in 2013, the company has since gained a reputation for its creative approach to game design, often incorporating skill-based elements into its titles to create a more interactive and engaging player experience. Whether you're a seasoned gambler or a casual player, Gamevy's games are sure to provide you with a fun and exciting gaming experience."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("gamzix") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Gamzix")}</h2>
              <p className="provider_text">
                {__(
                  "Gamzix is a rapidly growing software company that focuses on developing high-quality online casino games. Founded in 2020, Gamzix has quickly established itself as a force to be reckoned with in the iGaming industry, thanks to its innovative game designs and cutting-edge technology. The company's portfolio includes a wide range of games, including video slots, table games, and video poker, all of which are designed to provide players with a thrilling and immersive gaming experience. With a team of talented developers and designers, Gamzix is constantly pushing the boundaries of what is possible in the world of online gaming. "
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("habanero") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Habanero Games")}</h2>
              <p className="provider_text">
                {__(
                  "Habanero Games is a leading software company in the iGaming industry, specializing in the development of high-quality casino games. With a team of experienced professionals, Habanero Games has been delivering innovative and engaging gaming experiences since its founding in 2012. The company's portfolio includes a wide range of games, from classic table games to immersive video slots, all of which are known for their stunning graphics, seamless gameplay, and exciting bonus features. Habanero Games is committed to creating games that cater to players of all skill levels and preferences, and its dedication to quality has earned it a loyal following of players around the world. Whether you're looking for a quick game to pass the time or a high-stakes gaming experience, Habanero Games has you covered."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("hacksaw") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Hacksaw")}</h2>
              <p className="provider_text">
                {__(
                  "Hacksaw Gaming is a relatively new player in the online casino industry, having been founded in 2018. However, they have quickly established themselves as a leading provider of online casino games, with a particular focus on scratch card and slot games. Hacksaw Gaming's portfolio of slot games is small but impressive, with a range of visually stunning and engaging titles that offer players an immersive gaming experience. They are known for their commitment to innovation, with games that feature unique and unconventional themes such as 'The Respinners' and 'Miami Multiplier'. They also offer games with high payout potential, such as 'Frutz' and 'Cubes 2'. One of the key features of Hacksaw Gaming's slot games is their focus on mobile gaming. All of their games are developed using HTML5 technology, which ensures that they are fully optimized for mobile devices. This means that players can enjoy Hacksaw Gaming's games on their smartphones and tablets, as well as on desktop computers."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("hollegames") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Holle Games")}</h2>
              <p className="provider_text">
                {__(
                  "Holle Games is a german software provider that offer their players a wide variety of online slots. All their games are innovative and high-quality, exactly as you should expect from a top software provider in the casino industry. We are provide to present their vast library of slot games and no matter what type of player you are, you will definitly find something for you. They offer slots with a good variation of volatility and RTP, wich makes their portfolio suitable for most players that enjoy a good slot."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("irondogstudio") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Irondog Studios")}</h2>
              <p className="provider_text">
                {__(
                  "Iron Dog Studios is a software company that specializes in creating high-quality, innovative casino games. Founded in 2017, the company has quickly established itself as a prominent player in the iGaming industry, known for its visually stunning games and unique gameplay mechanics. With a team of talented designers and developers, Iron Dog Studios focuses on delivering games that are both engaging and rewarding for players of all levels of experience. The company's portfolio includes a wide range of games, from classic table games to cutting-edge video slots, all of which are designed to provide players with an immersive and entertaining gaming experience."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("kagaming") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("KA Gaming")}</h2>
              <p className="provider_text">
                {__(
                  "KA Gaming is a rapidly growing software company that specializes in creating innovative and engaging online casino games. Founded in 2014, the company has quickly become a leader in the iGaming industry, with a reputation for developing visually stunning games that are both entertaining and rewarding. KA Gaming's portfolio includes a wide range of games, including video slots, table games, and video poker, all of which are designed to provide players with a thrilling and immersive gaming experience. With a team of experienced developers and designers, KA Gaming is constantly pushing the boundaries of what is possible in the world of online gaming, and its games are enjoyed by players around the world. Whether you're a casual player or a seasoned gambler, KA Gaming has something for everyone."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("mancala") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Mancala Gaming")}</h2>
              <p className="provider_text">
                {__(
                  "Mancala Gaming is a software company that focuses on developing innovative and entertaining online casino games. Founded in 2019, the company has quickly gained a reputation for its high-quality games, which are designed to provide players with an immersive and engaging gaming experience. Mancala Gaming's portfolio includes a wide range of games, from classic table games to exciting video slots, all of which are known for their stunning graphics, smooth gameplay, and exciting bonus features. With a team of talented designers and developers, Mancala Gaming is committed to pushing the boundaries of what is possible in the world of online gaming, and its games are enjoyed by players around the world."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("mascot") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Mascot Gaming")}</h2>
              <p className="provider_text">
                {__(
                  "Mascot Gaming is a dynamic software company that specializes in developing high-quality online casino games. Founded in 2010, the company has established itself as a leading player in the iGaming industry, known for its innovative games, stunning graphics, and engaging gameplay. Mascot Gaming's portfolio includes a diverse range of games, from classic table games to immersive video slots, all of which are designed to provide players with an exciting and rewarding gaming experience. With a team of talented developers and designers, Mascot Gaming is committed to pushing the boundaries of what is possible in the world of online gaming, and its games are enjoyed by players around the world. Whether you're looking for a quick game to pass the time or a high-stakes gaming experience, Mascot Gaming has something for everyone."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("merkur") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Merkur Gaming")}</h2>
              <p className="provider_text">
                {__(
                  "Merkur Gaming is a well-known software company that specializes in creating innovative and entertaining casino games for land-based and online casinos. With a history dating back to 1957, Merkur Gaming has a wealth of experience in the gaming industry, and its games are enjoyed by players around the world. The company's portfolio includes a wide range of games, from classic slot machines to cutting-edge video slots, all of which are designed to provide players with a thrilling and immersive gaming experience. With a focus on quality and innovation, Merkur Gaming is committed to delivering games that are both engaging and rewarding for players of all levels of experience. The company's games are known for their exceptional graphics, exciting bonus features, and user-friendly interfaces."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("netgame") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">
                {__("Netgame Entertainement")}
              </h2>
              <p className="provider_text">
                {__(
                  "Netgame Entertainment is a fast-growing software company that specializes in creating innovative and exciting online casino games. Established in 2012, the company has quickly established itself as a leading player in the iGaming industry, known for its visually stunning games, cutting-edge technology, and engaging gameplay. Netgame Entertainment's portfolio includes a wide range of games, from classic table games to immersive video slots, all of which are designed to provide players with a unique and thrilling gaming experience. With a team of talented developers and designers, Netgame Entertainment is dedicated to pushing the boundaries of what is possible in the world of online gaming, and its games are enjoyed by all players online."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("nolimit") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Nolimit City")}</h2>
              <p className="provider_text">
                {__(
                  "Nolimit City is a cutting-edge software company that specializes in developing innovative and entertaining online casino games. Founded in 2013, the company has quickly gained a reputation for its high-quality games, which are designed to provide players with an immersive and engaging gaming experience. Nolimit City's portfolio includes a wide range of games, from classic table games to exciting video slots, all of which are known for their stunning graphics, smooth gameplay, and exciting bonus features. With a team of experienced developers and designers, Nolimit City is dedicated to pushing the boundaries of what is possible in the world of online gaming, and its games are enjoyed by players around the world. Whether you're a casual player or a seasoned gambler, Nolimit City has something for everyone."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("onlyplay") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Onlyplay")}</h2>
              <p className="provider_text">
                {__(
                  "Onlyplay is a dynamic software company that specializes in developing innovative and engaging online gaming solutions. With a team of talented developers, designers, and project managers, Onlyplay slots is dedicated to delivering cutting-edge technology and top-notch customer service to its clients. Their range of products includes casino games, lotteries, and sports betting platforms, all designed to provide players with a thrilling and immersive gaming experience. With a focus on quality and innovation, Onlyplay is constantly pushing the boundaries of what is possible in the world of online gaming. No matter if you are a fan of slots, other videogames or live games, Onlyplay have something for everyone."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("pgsoft") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("PG Soft")}</h2>
              <p className="provider_text">
                {__(
                  "PG Soft is a leading online casino game developer that specializes in creating high-quality slot games with innovative features and engaging gameplay. The company was founded in 2015 and has quickly established itself as a major player in the industry, with a portfolio of over 200 games that are available to players around the world. One of the key features of PG Soft's slot games is their focus on incorporating cultural and historical elements into their games. They have developed a range of games that are inspired by Asian mythology, such as 'Fortune Gods' and 'Dragon Hatch'. These games feature detailed graphics and animations that bring the stories to life, creating an immersive and engaging experience for players. PG Soft's slot games also incorporate a range of innovative features, such as the 'All Ways' mechanic in their popular game 'Three Monkeys'. This mechanic allows players to win in any direction, increasing the chances of hitting a winning combination."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("playson") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Playson")}</h2>
              <p className="provider_text">
                {__(
                  "Playson is a renowned software company that specializes in creating high-quality online casino games. Founded in 2012, the company has quickly established itself as a leading player in the iGaming industry, known for its innovative games, cutting-edge technology, and engaging gameplay. Playson's portfolio includes a diverse range of games, from classic slots to immersive video slots, all of which are designed to provide players with an exciting and rewarding gaming experience. Playson slot games are characterized by their exceptional graphics, exciting bonus features, and user-friendly interfaces, making them a popular choice for online casinos around the world. With a focus on quality and innovation, Playson is committed to delivering games that are both entertaining and rewarding for players of all levels of experience. Whether you're a fan of classic games or the latest video slots, Playson has something for everyone."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("pragmaticplay") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Pragmatic Play")}</h2>
              <p className="provider_text">
                {__(
                  "Pragmatic Play is a leading software company that specializes in creating high-quality online casino games. Established in 2015, the company has quickly gained a reputation for its innovative games, stunning graphics, and engaging gameplay. Pragmatic Play's portfolio includes a wide range of games, from classic table games to immersive video slots, all of which are designed to provide players with an exciting and rewarding gaming experience. The company's games are known for their exceptional graphics, exciting bonus features, and user-friendly interfaces, making them a popular choice for online and mobile casinos around the world. With a team of experienced developers and designers, Pragmatic Play is dedicated to pushing the boundaries of what is possible in the world of online gaming, and its games are enjoyed by players of all levels of experience. Whether you're a casual player or a seasoned gambler, Pragmatic Play has something for everyone."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("pushgaming") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Push Gaming")}</h2>
              <p className="provider_text">
                {__(
                  "Push Gaming is a UK-based online casino game developer that has gained a reputation for creating high-quality and innovative slot games. The company was founded in 2010 and has since become a major player in the online casino industry, offering a range of games that are both visually stunning and engaging to play. One of the key features of Push Gaming's slot games is their focus on unique gameplay mechanics. They have developed a range of innovative features, such as the 'Power Symbols' in their hit game 'Jammin' Jars' and the 'Bonus Game' in their popular slot 'Fat Rabbit'. These mechanics add an extra level of excitement to the games and keep players coming back for more. Push Gaming's portfolio of slot games is diverse, with a range of themes to appeal to different types of players. They are also known for their commitment to mobile gaming, with all of their games developed using HTML5 technology to ensure that they are fully optimized for mobile devices"
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("redtiger") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Red Tiger")}</h2>
              <p className="provider_text">
                {__(
                  "Red Tiger Gaming is a leading slot provider in the online gaming industry, offering a wide range of high-quality games to players all over the world. Founded in 2014, the company has quickly established itself as one of the top providers of online casino games, particularly in the area of slot games. One of the key features of Red Tiger's slot games is their high-quality graphics and engaging gameplay. Their games are visually stunning, with detailed graphics and animations that bring the games to life. They also place a strong emphasis on creating games with engaging gameplay mechanics, such as the 'Daily Drop' and 'Must Drop' jackpots, which offer players the chance to win big prizes every day."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("reflexgaming") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Reflex Gaming")}</h2>
              <p className="provider_text">
                {__(
                  "Reflex Gaming is a dynamic software company that specializes in creating high-quality online and land-based casino games. Established in 2004, the company has quickly gained a reputation for its innovative and exciting games, stunning graphics, and engaging gameplay. Reflex Gaming's portfolio includes a wide range of games, from classic slots to immersive video slots, all of which are designed to provide players with an unforgettable gaming experience. The company's games are known for their exceptional quality, exciting bonus features, and user-friendly interfaces, making them a popular choice for both online and land-based casinos around the world. With a team of experienced developers and designers, Reflex Gaming is committed to pushing the boundaries of what is possible in the world of gaming, and its games are enjoyed by players of all levels of experience. Whether you're a fan of classic slots or the latest video slots, Reflex Gaming has something for everyone."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("relax") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Relax Gaming")}</h2>
              <p className="provider_text">
                {__(
                  "Relax Gaming is a relatively new player in the online casino industry, having been founded in 2010. However, they have quickly established themselves as a leading provider of online casino games, with a particular focus on slot games. Relax Gaming's portfolio of slot games is impressive, with a diverse range of titles that appeal to a wide variety of players. They have partnered with a range of well-known slot providers, including Big Time Gaming, Push Gaming, and Felt, to offer a selection of top-quality games. In addition to their partnerships, Relax Gaming has also developed a range of proprietary slot games that have been well-received by players and operators alike."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("retrogaming") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Retro Gaming")}</h2>
              <p className="provider_text">
                {__(
                  "Retro Gaming is a prominent online casino software provider that offers a wide range of services to online casino operators. The company has established itself as a trusted and reliable partner for operators who seek to offer their players the best possible gaming experience. Retro Gaming's slot provider services include a range of customization options, including the ability to create bespoke slot games tailored to the operator's specific requirements. They also offer a range of promotional tools, including free spins, tournaments, and jackpot games, which can help operators to attract and retain players."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("revolver") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Revolver Gaming")}</h2>
              <p className="provider_text">
                {__(
                  "Revolver Gaming is a relatively new game provider in the online gaming industry, established in 2010 in London, UK. Despite being a relatively young company, Revolver Gaming has quickly made a name for itself by creating some of the most engaging and visually stunning games in the industry. Their portfolio includes a variety of games, from classic slots to modern video slots, and they are known for their ability to blend unique themes with exciting gameplay mechanics. Revolver Gaming has partnerships with several online casinos, providing them with access to a wide audience. The company also prioritizes player safety and fair play, ensuring that all of their games are certified by reputable testing labs."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("slotmill") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Slotmill")}</h2>
              <p className="provider_text">
                {__(
                  "Slotmill is a cutting-edge software company that specializes in developing premium online casino games. Established in 2019, the company is relatively new to the industry but has quickly gained a reputation for its exceptional quality and innovative approach to game design. Slotmill's portfolio includes a range of games, from classic slots to immersive video slots, all of which are created using the latest technology and designed to provide players with an immersive and engaging gaming experience. The company's games are characterized by their stunning graphics, exciting bonus features, and user-friendly interfaces, making them a popular choice for online casinos around the world."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("spinomenal") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Spinomenal")}</h2>
              <p className="provider_text">
                {__(
                  "Spinomenal is an innovative software company that specializes in creating high-quality online casino games. Founded in 2014, the company has quickly gained a reputation for its exceptional quality and innovative approach to game development. Spinomenal's portfolio includes a wide range of games, from classic slots to immersive video slots, all of which are designed to provide players with an exciting and engaging gaming experience. The company's games are characterized by their exceptional graphics, exciting bonus features, and user-friendly interfaces, making them a popular choice for online casinos around the world. With a team of experienced developers and designers, Spinomenal is dedicated to pushing the boundaries of what is possible in the world of online gaming, and its games are enjoyed by players of all levels of experience. Whether you're a casual player or a seasoned gambler, Spinomenal has something for everyone."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("spribe") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Spribe")}</h2>
              <p className="provider_text">
                {__(
                  "Spribe is an innovative software company that specializes in creating unique and engaging online casino games. Founded in 2018, the company has quickly established itself as a leader in the industry, thanks to its commitment to creating games that are not only fun to play but also offer players the chance to win big. Spribe's portfolio includes a range of games, from classic slots to innovative multiplayer games, all of which are designed to provide players with an exciting and immersive gaming experience. The company's games are characterized by their exceptional graphics, innovative gameplay mechanics, and user-friendly interfaces, making them a popular choice for online casinos around the world. With a team of experienced developers and designers, Spribe is dedicated to pushing the boundaries of what is possible in the world of online gaming, and its games are enjoyed by players of all levels of experience."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("swintt") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Swintt")}</h2>
              <p className="provider_text">
                {__(
                  "Swintt is a leading developer of innovative and engaging slot games for online casinos. With a passion for creating unique gaming experiences, Swintt offers a diverse portfolio of high-quality titles that cater to players of all preferences. From classic fruit machines to cutting-edge video slots, Swintt's games are designed to provide hours of entertainment and excitement. The company is known for its commitment to fair play, responsible gaming, and exceptional customer service, making it a top choice among both players and casino operators."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("thunderkick") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Thunderkick")}</h2>
              <p className="provider_text">
                {__(
                  "Thunderkick is a well-established game developer that specializes in creating unique and exciting online slot games for the iGaming industry. Based in Stockholm, Sweden, the company has quickly gained a reputation for developing visually stunning and engaging games that offer innovative gameplay mechanics and features. Thunderkick's portfolio includes a diverse range of games, from classic slots to modern video slots, each designed to deliver a thrilling and entertaining gaming experience. With a commitment to creativity and innovation, Thunderkick continues to push the boundaries of online gaming and is a highly respected and sought-after developer among casino players."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("tomhorn") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Tom Horn Gaming")}</h2>
              <p className="provider_text">
                {__(
                  "Tom Horn Gaming is a dynamic and innovative game development studio that specializes in creating top-quality online slot games for the iGaming industry. With a team of experienced designers, developers, and mathematicians, Tom Horn Gaming is known for producing games that feature stunning graphics, engaging themes, and innovative gameplay mechanics. The company's portfolio of games includes a wide variety of titles, from classic fruit machines to modern video slots, each designed to provide an immersive and entertaining gaming experience for players. "
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("truelab") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Truelab")}</h2>
              <p className="provider_text">
                {__(
                  "TrueLab is a dynamic and fast-growing game development company that specializes in creating innovative and engaging online slot games for the iGaming industry. Based in Malta, TrueLab is known for its high-quality games that feature stunning graphics, captivating themes, and exciting gameplay mechanics. The company's team of experienced designers and developers are dedicated to producing games that provide an immersive and entertaining gaming experience for players. TrueLab's portfolio of games includes a wide range of titles, from classic slots to modern video slots, each with its unique twist on traditional gameplay. With a focus on innovation and creativity, TrueLab is quickly becoming a leading player in the online gaming industry, delivering exciting and entertaining games that are enjoyed by players around the world."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("turbogames") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Turbo Games")}</h2>
              <p className="provider_text">
                {__(
                  "Turbo Games is a highly respected and innovative slot game developer that has been creating exciting and engaging games for several years. Based in Malta, the company has established itself as a leading player in the online gaming industry, producing a range of popular titles that are loved by players around the world. Turbo Games prides itself on creating games that are visually stunning, with crisp graphics and engaging sound effects, as well as innovative gameplay features that keep players coming back for more. With a focus on innovation, quality, and player satisfaction, Turbo Games is a force to be reckoned with in the world of online slot gaming."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("wazdan") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Wazdan")}</h2>
              <p className="provider_text">
                {__(
                  "Wazdan is a leading game development company that specializes in creating innovative and exciting online slot games for the iGaming industry. Based in Malta, Wazdan has built a reputation for delivering high-quality games that feature stunning graphics, engaging themes, and unique gameplay mechanics. The company's team of experienced designers and developers are dedicated to pushing the boundaries of online gaming and delivering the ultimate player experience. Wazdan's slot portfolio of games includes a wide range of titles, from classic fruit machines to modern video slots, each with its own unique style and features. With a focus on innovation and technology, Wazdan is constantly developing new games and features that are designed to meet the evolving needs of players and operators alike. "
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("wizard") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Wizard")}</h2>
              <p className="provider_text">
                {__(
                  "Wizard Slots is a popular online casino game developer that focuses on creating exciting and engaging online slot games for players worldwide. Based in Malta, the company offers a vast collection of unique and innovative slot games, each with its own distinct theme and features. Wizard Slots prides itself on delivering games with outstanding graphics, stunning animations, and impressive sound effects, all designed to provide players with an immersive and thrilling gaming experience. The company's team of experienced designers and developers are dedicated to producing games that are not only entertaining but also fair and transparent. With a commitment to innovation and excellence, Wizard Slots is quickly becoming a leading player in the iGaming industry, delivering games that are enjoyed by players worldwide."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("yggdrasil") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Yggdrassil")}</h2>
              <p className="provider_text">
                {__(
                  "Yggdrasil is a highly innovative and rapidly growing game development company that specializes in creating top-quality online slot games for the iGaming industry. Based in Malta, Yggdrasil is known for its high-quality games that feature stunning graphics, captivating themes, and innovative gameplay mechanics. The company's portfolio of games includes a wide range of titles, from classic fruit machines to modern video slots, each with its own unique style and features. Yggdrasil's team of experienced designers and developers are dedicated to pushing the boundaries of online gaming and delivering games that provide an immersive and entertaining gaming experience for players. With a focus on innovation and technology, Yggdrasil is constantly developing new games and features that are designed to meet the evolving needs of players and operators alike. With a growing presence in the iGaming industry, Yggdrasil is a highly respected and sought-after game developer that continues to set new standards for online gaming."
                )}
              </p>
            </div>
          </>
        )}
        {props.selectedProviders.includes("zillion") && (
          <>
            <div className="single_area">
              <h2 className="provider_heading">{__("Zillion Games")}</h2>
              <p className="provider_text">
                {__(
                  "As a game development company, Zillion Games offers modern gambling solutions to their players. Currently, their team of experienced specialists is working hard on a rapidly growing portfolio of progressive and renovational casino titles available to slots fans. A brand new mechanic, coupled with a fresh and pleasant design, ensures the highest level of satisfaction and involvement. So, no matter what type of player you are, you will defenitly find a slot game from Zillion Games that you like and can enjoy. "
                )}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
