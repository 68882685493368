import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useParams } from "react-router-dom";
import auth from "services/auth";
import ls, { set } from "local-storage";
import AppContext from "contexts/AppContext";
import api from "services/api";
import emailValidate from "services/emailValidate";
import LanguageSwitcher from "elements/LanguageSwitcher";
import SupportSidebar from "elements/SupportSidebar";
import { __ } from "services/Translator";
import JackpotTicker from "elements/JackpotTicker";

const Footer = (props) => {
  // const __ = (text) => text;
  const [languages, setLanguages] = useState([]);
  const getLanguages = async () => {
    var response = await api.get("/languages");
    if (response.status === 200) {
      // setLanguages(
      //   response.data.map((val) => {
      //     return { label: val.language, value: val.code };
      //   })
      // );
      setLanguages(response.data);
    } else {
      props.showAlert("Warning!!", response.data.message);
    }
  };

  useEffect(() => {
    getLanguages();
    setTimeout(() => {
      try {
        // window.apg_fd182c80_f32b_4a62_a3d5_47644daba979.init();
        if (window.location.hostname === "blizzcasino.io") {
          window.xcm_35629cf1_7591_418d_a76f_563332a74c1e.init();
        } else {
          window.xcm_0fb5404e_463f_45b3_8268_7797d673d57b.init();
        }
      } catch (ex) {}
    }, 2000);
  }, []);

  return (
    <>
      <SupportSidebar {...props} />
      <footer>
        <section className="footer">
          <div className="footer_grids landing">
            <div className="container-md">
              <div className="links_section">
                <div className="single_block">
                  <ul>
                    <li>
                      <Link to="/">
                        {/* <img
                          className="footer_logo"
                          src={
                            require("assets/images/header/footer-logo.png")
                              .default
                          }
                        /> */}
                        <img
                          className="footer_logo"
                          src={
                            require("assets/images/logos/blizz-casino-logo-gradient.svg")
                              .default
                          }
                          alt="Blizz Casino Logo"
                        />
                      </Link>
                    </li>
                    <li className="casino_text"></li>
                  </ul>
                  <ul className="supported_currency_list">
                    <li>
                      <span className="currency_icon">
                        <svg className="svg_icon">
                          <use xlinkHref={"/icons.svg#icon-currency-btc"} />
                        </svg>
                      </span>
                    </li>
                    <li>
                      <span className="currency_icon">
                        <svg className="svg_icon">
                          <use xlinkHref={"/icons.svg#icon-currency-eth"} />
                        </svg>
                      </span>
                    </li>
                    <li>
                      <span className="currency_icon">
                        <svg className="svg_icon">
                          <use xlinkHref={"/icons.svg#icon-currency-ltc"} />
                        </svg>
                      </span>
                    </li>
                    <li>
                      <span className="currency_icon">
                        <svg className="svg_icon">
                          <use xlinkHref={"/icons.svg#icon-currency-doge"} />
                        </svg>
                      </span>
                    </li>
                    <li>
                      <span className="currency_icon">
                        <svg className="svg_icon">
                          <use xlinkHref={"/icons.svg#icon-currency-usdt"} />
                        </svg>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <img
                      className="other_mayment_logo"
                      src={
                        require("assets/images/footer/payment_methods.jpg")
                          .default
                      }
                    />
                  </div>
                </div>
                <div className="single_block linkEffect">
                  <ul>
                    <li className="uppercase list_heading">
                      {__("Information")}
                    </li>
                    <li>
                      <Link to="/terms">{__("Terms and Conditions")}</Link>
                    </li>
                    <li>
                      <Link to="/bonus-terms">{__("Bonus Terms")}</Link>
                    </li>
                    <li>
                      <Link to="/privacy">{__("Privacy policy")}</Link>
                    </li>
                    <li>
                      <Link to="/aml-policy">{__("AML Policy")}</Link>
                    </li>

                    <li>
                      <Link to="/legal">{__("Responsible Gaming")}</Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        onClick={() => {
                          props.setSupportSidebarOpen(true);
                        }}
                      >
                        {__("Support")}{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="/loyalty">{__("Loyalty")}</Link>
                    </li>
                    <li>
                      <Link to="/promotions">{__("Promotions")}</Link>
                    </li>
                    <li>
                      <a
                        rel="nofollow noopener noreferrer"
                        href="https://blizzaffiliates.io"
                        target="_blank"
                      >
                        {__("Affiliates")}
                      </a>
                    </li>
                    <li>
                      <Link to="/careers">{__("Careers")}</Link>
                    </li>

                    {props.initialSettings.is_blog_active != 0 && (
                      <li>
                        <Link to="/blogs">{__("Blogs")}</Link>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="single_block linkEffect">
                  <ul>
                    <li className="uppercase list_heading">{__("Services")}</li>
                    <li>
                      <Link to="/games/all"> {__("Casino")}</Link>
                    </li>
                    <li>
                      <Link to="/games/slots"> {__("Slot Games")}</Link>
                    </li>
                    <li>
                      <Link to="/games/live-casino"> {__("Live Casino")}</Link>
                    </li>
                    <li>
                      <Link to="/games/bonus-buy"> {__("Bonus Buys")}</Link>
                    </li>
                    <li>
                      <Link to="/games/jackpot"> {__("Jackpots")}</Link>
                    </li>
                    <li>
                      <Link to="/games/megaways"> {__("Megaways")}</Link>
                    </li>
                    {props.isRacesPageActive && (
                      <li>
                        <Link to="/races"> {__("Races")}</Link>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="single_block">
                  <ul>
                    <li className="socialLink">
                      <div className="social_and_language">
                        <ul className="social_links">
                          <li>
                            <a
                              rel="nofollow noopener noreferrer"
                              target="_blank"
                              href="https://www.facebook.com/blizzcasino"
                            >
                              <i className="fab fa-facebook"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              rel="nofollow noopener noreferrer"
                              target="_blank"
                              href="https://twitter.com/blizzcasino"
                            >
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              rel="nofollow noopener noreferrer"
                              target="_blank"
                              href="https://t.me/s/blizzcrypto"
                            >
                              <i class="fab fa-telegram"></i>
                            </a>
                          </li>
                          {/* <li>
                            <a  rel="nofollow noopener noreferrer"
                              target="_blank"
                              href="https://twitter.com/BlizzCasino"
                            >
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li> */}
                          <li>
                            <a
                              rel="nofollow noopener noreferrer"
                              target="_blank"
                              href="https://www.instagram.com/blizzcasino/"
                            >
                              <i className="fab fa-instagram"></i>
                            </a>
                          </li>
                          {/* <li>
                            <Link to="#">
                              <i className="fab fa-google-plus-g"></i>
                            </Link>
                          </li> 
                          <li>
                            <Link to="#">
                              <i className="fab fa-linkedin-in"></i>
                            </Link>
                          </li>*/}
                        </ul>
                        <div className="language_selection">
                          <LanguageSwitcher />
                        </div>
                      </div>

                      <div className="socialLinkContainger d-none">
                        {/* <Link to="#">
                          <i className="fab fa-facebook"></i>
                        </Link>*/}
                        <a
                          rel="nofollow noopener noreferrer"
                          target="_blank"
                          href="https://twitter.com/BlizzCasino"
                        >
                          <i className="fab fa-twitter"></i>
                        </a>
                        <a
                          target="_blank"
                          href="https://www.instagram.com/blizzcasino/"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                        {/* <Link to="#">
                          <i className="fab fa-google-plus-g"></i>
                        </Link> 
                        <Link to="#">
                          <i className="fab fa-linkedin-in"></i>
                        </Link>*/}
                      </div>
                    </li>
                  </ul>

                  <div className="footer_jackpot_ticker_area">
                    <div className="jackpot_title">{__("JACKPOTS TOTAL")}</div>
                    <JackpotTicker {...props} />
                  </div>
                </div>
              </div>

              {props.isMobileDeviceByViewPort && (
                <div className="license_area">
                  <div className="license_text">
                    {__("Blizz Casino is operated by Meta Bliss Group B.V.")}
                    <br />{" "}
                    {__(
                      "a company incorporated in Curacao with company registration number 159718 and having its registered address at Korporaalweg 10, Willemstad, Curaçao."
                    )}
                    <br />{" "}
                    {__(
                      "Blizz Casino is licensed and regulated by the Gaming Authority of Curacao under license number 8048/JAZ."
                    )}
                  </div>
                  <div className="license_logo">
                    {/* <div
                      className="curacao_license_area"
                      id="apg-fd182c80-f32b-4a62-a3d5-47644daba979"
                      data-apg-seal-id="fd182c80-f32b-4a62-a3d5-47644daba979"
                      data-apg-image-size="256"
                      data-apg-image-type="basic-light-large"
                    ></div> */}
                    <a
                      target="_blank"
                      href="https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJbXQ1VkZOWVUzVjRhQ3MyYkRaTGFISlBiRWN6ZWxFOVBTSXNJblpoYkhWbElqb2lZelJqUkdGb1RIUnJTMlJ2ZFV4UGExRlRNa1ZvZHowOUlpd2liV0ZqSWpvaU56UTRNVGM0TURVMk1qaGxZalJsT0RkaU9USmhZbU15TVROa01tRTROR1EyWmpFek1UVTFZV1V4TXpRek5tSTVaV1E1Tnpsak5qaGhOamszWm1ReVppSXNJblJoWnlJNklpSjk="
                    >
                      <img
                        style={{ width: "90px" }}
                        src="https://portal.gamingcontrolcuracao.org/uploads/documents/GCB_Temp_Seal.svg"
                      />
                    </a>
                    {window.location.hostname === "blizzcasino.io" ? (
                      <>
                        {/* <div
                          className="curacao_license_area"
                          id="xcm-35629cf1-7591-418d-a76f-563332a74c1e"
                          data-xcm-seal-id="35629cf1-7591-418d-a76f-563332a74c1e"
                          data-xcm-image-size="128"
                          data-xcm-image-type="basic-small"
                        ></div> */}
                        <div
                          className="curacao_license_area"
                          id="xcm-0fb5404e-463f-45b3-8268-7797d673d57b"
                          data-xcm-seal-id="0fb5404e-463f-45b3-8268-7797d673d57b"
                          data-xcm-image-size="128"
                          data-xcm-image-type="basic-small"
                        ></div>
                      </>
                    ) : (
                      <div
                        className="curacao_license_area"
                        id="xcm-0fb5404e-463f-45b3-8268-7797d673d57b"
                        data-xcm-seal-id="0fb5404e-463f-45b3-8268-7797d673d57b"
                        data-xcm-image-size="128"
                        data-xcm-image-type="basic-small"
                      ></div>
                    )}
                  </div>
                  <div className="cryptocasinos_approved_logo">
                    {/* <a  rel="nofollow noopener noreferrer" target="_blank" href="https://bit.ly/Blizzcryptos">
                      <img
                        src={
                          require("assets/images/footer/cryptocasinos_approved_logo.svg")
                            .default
                        }
                      />
                    </a> */}
                  </div>
                  {/* <div>
                    <img
                      style={{
                        maxWidth: "200px",
                        margin: "0 auto",
                        display: "block",
                      }}
                      src={
                        require("assets/images/footer/payment_methods.jpg")
                          .default
                      }
                    />
                  </div> */}
                  <div className="footer_copyright_text">
                    Copyright © {new Date().getFullYear()} Blizz.io - All rights
                    reserved.
                  </div>
                </div>
              )}

              {!props.isMobileDeviceByViewPort && (
                <div className="license_area">
                  <div className="license_logo">
                    {/* <div
                      className="curacao_license_area"
                      id="apg-fd182c80-f32b-4a62-a3d5-47644daba979"
                      data-apg-seal-id="fd182c80-f32b-4a62-a3d5-47644daba979"
                      data-apg-image-size="256"
                      data-apg-image-type="basic-light-large"
                    ></div> */}
                    <a
                      target="_blank"
                      href="https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJbXQ1VkZOWVUzVjRhQ3MyYkRaTGFISlBiRWN6ZWxFOVBTSXNJblpoYkhWbElqb2lZelJqUkdGb1RIUnJTMlJ2ZFV4UGExRlRNa1ZvZHowOUlpd2liV0ZqSWpvaU56UTRNVGM0TURVMk1qaGxZalJsT0RkaU9USmhZbU15TVROa01tRTROR1EyWmpFek1UVTFZV1V4TXpRek5tSTVaV1E1Tnpsak5qaGhOamszWm1ReVppSXNJblJoWnlJNklpSjk="
                    >
                      <img
                        style={{ width: "90px" }}
                        src="https://portal.gamingcontrolcuracao.org/uploads/documents/GCB_Temp_Seal.svg"
                      />
                    </a>
                    {window.location.hostname === "blizzcasino.io" ? (
                      <>
                        {/* <div
                        className="curacao_license_area"
                        id="xcm-35629cf1-7591-418d-a76f-563332a74c1e"
                        data-xcm-seal-id="35629cf1-7591-418d-a76f-563332a74c1e"
                        data-xcm-image-size="128"
                        data-xcm-image-type="basic-small"
                      ></div>

                       */}
                        <div
                          className="curacao_license_area"
                          id="xcm-0fb5404e-463f-45b3-8268-7797d673d57b"
                          data-xcm-seal-id="0fb5404e-463f-45b3-8268-7797d673d57b"
                          data-xcm-image-size="128"
                          data-xcm-image-type="basic-small"
                        ></div>
                      </>
                    ) : (
                      <div
                        className="curacao_license_area"
                        id="xcm-0fb5404e-463f-45b3-8268-7797d673d57b"
                        data-xcm-seal-id="0fb5404e-463f-45b3-8268-7797d673d57b"
                        data-xcm-image-size="128"
                        data-xcm-image-type="basic-small"
                      ></div>
                    )}
                  </div>
                  <div className="license_text">
                    {__(
                      "Blizz Casino is operated by Meta Bliss Group B.V., a company incorporated in Curacao with company registration number 159718 and having its registered address at Korporaalweg 10, Willemstad, Curaçao."
                    )}{" "}
                    <br />
                    {__(
                      "Blizz Casino is licensed and regulated by the Gaming Authority of Curacao under license number 8048/JAZ."
                    )}
                  </div>
                  <div className="cryptocasinos_approved_logo">
                    {/* <a
                      rel="nofollow noopener noreferrer"
                      target="_blank"
                      href="https://bit.ly/casinomates"
                    >
                      <img
                        className="brand_img"
                        style={{ marginTop: "-10px", height: "85px" }}
                        src={
                          require("../assets/images/footer/casinoamtes-ap2.png")
                            .default
                        }
                      />
                    </a> */}
                  </div>
                </div>
              )}
            </div>
          </div>

          <ul className="partner_footer_menu">
            <li>
              <a href="mailto:support@blizzcasino.io">
                <span className="partnerHeading">{__("Support")}:</span>{" "}
                <span className="partnerText">support@blizzcasino.io</span>
              </a>
            </li>
            <li>
              <a href="mailto:affiliates@blizzcasino.io">
                <span className="partnerHeading">{__("Partner")}: </span>{" "}
                <span className="partnerText">affiliates@blizzcasino.io</span>
              </a>
            </li>
            <li>
              <a href="mailto:info@blizzcasino.io">
                <span className="partnerHeading">{__("General")}:</span>{" "}
                <span className="partnerText">info@blizzcasino.io</span>
              </a>
            </li>
          </ul>

          <div className="footer_copyright">
            {/* <div className="curacao_license_area">
              <iframe
                style={{
                  width: "150px",
                  height: "50px",
                  border: "none",
                  marginLeft: "64px",
                  verticalAlign: "bottom",
                }}
                src="https://licensing.gaming-curacao.com/validator/?lh=e9f7b57748334a8ad515d52b96331ce4&template=tseal"
              ></iframe>
            </div> */}
            <div className="brands d-none d-lg-block">
              <ul>
                <li className="dotIgamingLogo">
                  <a
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                    href="https://dot-igaming.com"
                  >
                    <img
                      className="solidImg brand_img"
                      src={
                        require("../assets/images/common/dot-igaming-solid-white.svg")
                          .default
                      }
                    />
                    <img
                      className="hoverImg brand_img"
                      src={
                        require("../assets/images/common/dot-igaming.svg")
                          .default
                      }
                    />
                  </a>
                </li>
                <li>
                  <img
                    className="brand_img"
                    src={require("../assets/images/brands/pay-op.svg").default}
                  />
                </li>
                <li>
                  <img
                    className="brand_img"
                    src={
                      require("../assets/images/brands/pragmatic.svg").default
                    }
                  />
                </li>
                <li>
                  <img
                    className="brand_img"
                    src={
                      require("../assets/images/brands/evolution.svg").default
                    }
                  />
                </li>

                <li>
                  <img
                    className="brand_img"
                    src={
                      require("../assets/images/brands/netent-logo.svg").default
                    }
                  />
                </li>
                <li>
                  <img
                    className="brand_img"
                    src={
                      require("../assets/images/brands/microgaming-logo.svg")
                        .default
                    }
                  />
                </li>

                <li>
                  <img
                    className="brand_img"
                    src={
                      require("../assets/images/brands/hacksaw-gaming.png")
                        .default
                    }
                  />
                </li>
                <li>
                  <a
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                    href="https://www.begambleaware.org"
                  >
                    <img
                      className="brand_img"
                      style={{ marginTop: "-10px", height: "22.5px" }}
                      src={
                        require("../assets/images/brands/begambleawareorg_white.png")
                          .default
                      }
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      className="brand_img"
                      style={{ marginTop: "-10px", height: "35px" }}
                      src={
                        require("../assets/images/footer/new-brand.svg").default
                      }
                    />
                  </a>
                </li>
              </ul>
            </div>

            <div className="footer_copyright_text">
              Copyright © {new Date().getFullYear()} Blizz.io - All rights
              reserved.
            </div>
          </div>
        </section>
      </footer>
    </>
  );
};

export default Footer;
