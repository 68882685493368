import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import { __ } from "services/Translator";
import { Modal, Dropdown } from "react-bootstrap";
import InputBox from "elements/InputBox";

import moneyFormatter from "services/moneyFormatter";

function BalanceTransfer(props) {
  // const __ = (text) => text;

  // const showTipModal = props.showTipModal;
  // const setShowTipModal = props.setShowTipModal;

  const closeTipModal = () => {
    props.setShowTipModal(false);
  };

  const defaultForm = {
    receiverEmail: "",
    amount: 0,
  };

  // const [form, setForm] = useState(defaultForm);
  const [transferForm, setTransferForm] = useState({
    receiverEmail: "",
    amount: 0,
  });

  const updateForm = (field, value) => {
    setTransferForm({ ...transferForm, [field]: value });
    console.log("transferForm", field);
  };

  const updateInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    updateForm(field, value);
  };

  const transferBalance = async () => {
    props.setSpinner(true);
    var response = await api.post("/transfer-balance", transferForm);
    props.setSpinner(false);
    if (response.status === 200) {
      props.showAlert(null, response.data.message);
      setTransferForm({
        receiverEmail: "",
        amount: 0,
      });
    } else {
      props.showAlert(null, response.data.message, true);
    }
  };

  //Copied
  const [selectedDepositWallet, setSelectedDepositWallet] = useState("BTC");
  const fiatMethods = ["USD"];
  const userBalance = props.userBalance;
  const getUserBalance = props.getUserBalance;

  useEffect(() => {
    // console.log('asfasdfasdf', props.showTipModal)
    if (props.showTipModal && props.showTipModal !== true) {
      updateForm("receiverEmail", props.showTipModal); //Pre set a player login into this field
    }
  }, [props.showTipModal]);

  return (
    <>
      <Modal size="lg" show={!!props.showTipModal} onHide={closeTipModal}>
        <Modal.Body className="cashier_modal">
          <div onClick={closeTipModal} className="modal_close_reg small">
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.26872 8.99995L1.05492 3.59305C0.775612 3.3034 0.589405 2.86892 0.589405 2.43443C0.589405 1.99995 0.775612 1.56547 1.05492 1.27581C1.66009 0.648226 2.68423 0.648226 3.2894 1.27581L8.5032 6.68271L13.717 1.27581C14.3222 0.648226 15.3463 0.648226 15.9515 1.27581C16.2308 1.56547 16.417 1.99995 16.417 2.43443C16.417 2.86892 16.2308 3.3034 15.9515 3.59305L10.7377 8.99995L15.9515 14.4068C16.2308 14.6965 16.417 15.131 16.417 15.5655C16.417 15.9999 16.2308 16.4344 15.9515 16.7241C15.6722 17.0137 15.2532 17.2068 14.8342 17.2068C14.4153 17.2068 13.9963 17.0137 13.717 16.7241L8.5032 11.3172L3.2894 16.7241C2.68423 17.3517 1.66009 17.3517 1.05492 16.7241C0.775612 16.4344 0.589405 15.9999 0.589405 15.5655C0.589405 15.131 0.775612 14.6965 1.05492 14.4068L6.26872 8.99995Z"
                fill="#10294F"
              />
            </svg>
          </div>
          <div className="balance_transfer_area">
            <div className="form_area">
              <div className="input_item d-none">
                <div className="name">{__("Cryptocurrency")}</div>

                <Dropdown className="currency_select_menu">
                  <Dropdown.Toggle variant="success" className="right select_crypto" id="dropdown-basic1">
                    <span className="dipwid vaultCryptoIcon">
                      {moneyFormatter.currencyFullWithIcon(selectedDepositWallet)}
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="wallets_menu ">
                    {!!userBalance.wallets?.length &&
                      userBalance.wallets
                        .filter((item) => fiatMethods.indexOf(item.currency) === -1)
                        .map((item, index) => {
                          return (
                            <Dropdown.Item as="button" key={index}>
                              <div className="wallets_balance" onClick={() => setSelectedDepositWallet(item.currency)}>
                                {moneyFormatter.currencyFullWithIcon(item.currency)}
                              </div>
                            </Dropdown.Item>
                          );
                        })}
                  </Dropdown.Menu>
                </Dropdown>
                <div className="clearfix"></div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="input_item walletAddress">
                    <div className="name">
                      {__("Receiver Username")} <span className="requried_icon">*</span>
                    </div>
                    <input
                      type="text"
                      name="receiverEmail"
                      value={transferForm.receiverEmail}
                      onChange={updateInput}
                      className="form-control name_value"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input_item walletAddress">
                    <div className="name">
                      {__("Amount")} <span className="requried_icon">*</span>
                    </div>
                    <div class="input-group name_value" style={{ padding: 0 }}>
                      <div class="input-group-prepend">
                        <span class="input-group-text">{props.userBalance.currency}</span>
                      </div>
                      <input
                        type="text"
                        name="amount"
                        value={transferForm.amount}
                        onChange={updateInput}
                        className="form-control name_value"
                      />
                    </div>
                  </div>
                </div>
                <div className="clearfix"></div>
                <div className="login_button">
                  <button type="button" className="common_btn" onClick={transferBalance}>
                    {__("Transfer")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BalanceTransfer;
