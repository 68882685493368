import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useParams, useLocation } from "react-router-dom";
import api from "services/api";
import DOMPurify from "dompurify";
import CustomerReviews from "elements/CustomerReviews";
import { Helmet } from "react-helmet";
import { __ } from "services/Translator";

export default function Page(props) {
  const isAuthenticated = props.isAuthenticated;
  const [paramId, setParamId] = useState(null);
  const [pageContent, setPageContent] = useState({});

  const location = useLocation();

  const pageUidMaps = {
    terms: "terms_conditions",
    "bonus-terms": "bonus_terms_conditions",
    privacy: "privacy_policy",
    "aml-policy": "aml_policy",
    legal: "responsible_gaming",
    "vip-program": "loyalty_and_vip_program",
  };

  const pageTitles = {
    terms: __("Blizz Casino | Terms and Conditions"),
    "bonus-terms": __("Blizz Casino | All you need to know about the terms of accepting our Bonuses"),
    privacy: __("Blizz Casino | Your Privacy is our Priority - More details on that here"),
    "aml-policy": __("Blizz Casino | Our Anti-Money Laundering Policy in full"),
    legal: __("Blizz Casino | All the Legal requirements of holding an account with us"),
    "vip-program": __("Blizz Casino | Become a VIP and get all the Rewards that go with it!"),
  };

  const metaDescriptions = {
    terms: __("Blizz Casino | Terms and Conditions"),
    "bonus-terms": __(
      "Blizz Casino! Read this section before accepting our Bonuses as it explains everything you need to know about them!"
    ),
    privacy: __(
      "Blizz Casino! Details of what data we need from you and why and how we handle it - your Privacy is our Priority!"
    ),
    "aml-policy": __(
      "Blizz Casino! Full information on how we ensure funds don't come from criminal practices and how we are legally obliged to help prevent money laundering"
    ),
    legal: __(
      "Blizz Casino! Details on your legal rights for holding an account with us, what we expect from you and more with the legal bit"
    ),
    "vip-program": __(
      "Blizz Casino! We value all our players of course, become a ViP though and get extra rewards, bonuses and more!"
    ),
  };

  const getResults = async (pageUid) => {
    var response = await api.get("/get-content-page", {
      page_uid: pageUid,
    });
    if (response.status === 200) {
      setPageContent(response.data.content_data);
    } else {
      props.showAlert(null, response.data.message, true);
    }
  };

  useEffect(() => {
    var paramId = location.pathname.substr(1);
    setParamId(paramId);
    var pageUid = pageUidMaps[paramId];
    getResults(pageUid);
  }, [location.pathname]);

  const doPrintPage = () => {
    var mywindow = window.open("", "PRINT", "height=400,width=600");

    mywindow.document.write("<html><head><title>" + document.title + "</title>");
    mywindow.document.write("</head><body >");
    mywindow.document.write("<h1>" + document.title + "</h1>");
    mywindow.document.write(document.getElementById("pageContent").innerHTML);
    mywindow.document.write("</body></html>");

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;
  };

  return (
    <>
      <Helmet>
        <title>{paramId ? pageTitles[paramId] : ""}</title>
        <meta name="description" content={paramId ? metaDescriptions[paramId] : ""} />
      </Helmet>
      <section className="about">
        <div className="container-md">
          <div className="terms_content">
            <div className="page_title">{pageContent?.page_title}</div>
            {paramId === "terms" && (
              <>
                <div className="last_updated">{__("Last updated, June 01st, 2023")}</div>
                <button onClick={doPrintPage} className="common_btn print_page_button">
                  {__("Print")}
                </button>
              </>
            )}
            <div
              className="paragraph white"
              id="pageContent"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(pageContent.content),
              }}
            ></div>

            {/*<CustomerReviews
            variant="wide"
            isAuthenticated={props.isAuthenticated}
          />*/}
          </div>
        </div>
      </section>
    </>
  );
}
