// var configEnv = "development";
// var configEnv = "staging";
var configEnv = "production";

var config = {
  //##########Live config##########
  production: {
    appUrl: "https://blizz.io",
    apiUrl: "https://api.blizz.io/api/v1",
    staticUrl: "https://api.blizz.io/api/v1",
    liveChatUrl: "https://missioncontrol9.blizz.io/public/livechat/php/app.php?widget-inline",
    fileSourceUrl: "https://missioncontrol9.blizz.io/public",
    socketUrl: "wss://api.blizz.io:7002",
    debug: false,
    // onRamperApiKey: "pk_prod_sNFdbDfDyMmQG55tIe6RdIGvCrufY7wLdN4edFcA0OY0", //DO not copy, only for blizzcasino old apikey
    onRamperApiKey: "pk_prod_01GY065H8AASNBNY8H0E2R4AP2", //DO not copy, only for blizzcasino
    payOpPublicKey: "application-2952732d-9201-418c-aaa9-f96774b2f04d",
  },

  //#########Stage config##########
  staging: {
    appUrl: "https://blizzapi.cbstagings.com",
    apiUrl: "https://blizzapi.cbstagings.com/api/v1",
    staticUrl: "https://blizzapi.cbstagings.com/api/v1",
    liveChatUrl: "https://blizzboss.cbstagings.com/public/livechat/php/app.php?widget-inline",
    fileSourceUrl: "https://blizzboss.cbstagings.com/public",
    socketUrl: "wss://blizzapi.cbstagings.com:7002",
    debug: false,
    // onRamperApiKey: "pk_prod_sNFdbDfDyMmQG55tIe6RdIGvCrufY7wLdN4edFcA0OY0", //DO not copy, only for blizzcasino old apikey
    onRamperApiKey: "", //DO not copy, only for blizzcasino
    payOpPublicKey: "", //Its the production key, there is no staging
  },

  //##########Local config##########
  development: {
    appUrl: "http://localhost:3000",
    apiUrl: "http://casinoapi.local.host/api/v1",
    staticUrl: "http://casinoapi.local.host/api/v1",
    liveChatUrl: "http://casinoadmin.local.host/public/livechat/php/app.php?widget-inline",
    fileSourceUrl: "http://casinoadmin.local.host/public",
    socketUrl: "ws://localhost:7002",
    debug: true,
    // onRamperApiKey: "pk_prod_sNFdbDfDyMmQG55tIe6RdIGvCrufY7wLdN4edFcA0OY0", //DO not copy, only for blizzcasino old apikey
    onRamperApiKey: "pk_prod_01GY065H8AASNBNY8H0E2R4AP2", //DO not copy, only for blizzcasino
    payOpPublicKey: "application-2952732d-9201-418c-aaa9-f96774b2f04d", //Its the production key, there is no staging
  },
};

var finalConfig = config[configEnv];

finalConfig.defaultLanguage = {
  code: "en",
  language: "English",
  flag_path: finalConfig.fileSourceUrl + "/images/flags/svg/gb.svg",
};

export default finalConfig;
