import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import PromotionBanner from "elements/PromotionBanner";
import { Button } from "react-bootstrap";
import Flickity from "react-flickity-component";
import DOMPurify from "dompurify";
import api from "services/api";
import { __ } from "services/Translator";
import { Helmet } from "react-helmet";

export default function MonthlyRaffleLottery(props) {
  // const __ = (text) => text;
  const flkty = useRef(null);

  const nextLoad = () => {
    flkty.current.next();
  };

  const prevLoad = () => {
    flkty.current.previous();
  };

  const isAuthenticated = props.isAuthenticated;
  const [promoPage, setPromoPage] = useState({});
  const [pageContent, setPageContent] = useState({});
  const [promotions, setPromotions] = useState([]);

  const getResults = async () => {
    var response = await api.get("/promotions");

    if (response.status === 200) {
      setPromoPage(response.data.page);
      setPageContent(response.data.content_data);
      setPromotions(response.data.promotions);
    } else {
      props.showAlert(null, response.data.message, true);
    }
  };

  const toggleExpanded = (index) => {
    var promotionsClone = JSON.parse(JSON.stringify(promotions));
    promotionsClone[index].expanded = !promotionsClone[index].expanded;
    setPromotions(promotionsClone);
  };

  useEffect(() => {
    getResults();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {__(
            "Blizz Casino | Get Bonuses on your first 2 Deposits, see what else is available here!"
          )}
        </title>
        <meta
          name="description"
          content={__(
            "Blizz Casino! See all the latest fun Promotions we run which give you extra play for your buck - Check back regularly for all the latest news!"
          )}
        />
      </Helmet>

      <div className="promotions">
        <section className="promotions_banner_v1">
          <div className="banner_img">
            <img
              className="promotions_banner_img"
              src={
                require("assets/images/v1/banners/monthly-raffle-banner.png")
                  .default
              }
              alt="Promotions Banner"
            />
          </div>
        </section>

        <div className="promotions_heading v1">
          {__("Monthly Raffle Lottery")}
        </div>
        <div className="container-md">
          <div className="monthly_raffle">
            <div className="item_left">
              <div className="single_promotion_v1">
                <div className="single_promotion_content">
                  <div className="promotions_img">
                    <img
                      className="promotions_banner_img"
                      src={
                        require("assets/images/v1/promotions/monthly-raffle.svg")
                          .default
                      }
                      alt="Promotions Banner"
                    />
                  </div>
                  <div className="promotions_text_section">
                    <div className="title">{__("Monthly Bonus")}</div>
                    <div className="title colored">
                      {__("Blizz $10k Raffle")}
                    </div>

                    <div className="single_promotion_link margin_25">
                      {/* <Link
                        className="common_btn_v1 width_100 blue_bg_white_text"
                        to="#"
                      >
                        {__("Get The Deal")}
                      </Link> */}
                      {props.isAuthenticated ? (
                        <Link
                          className="common_btn_v1 width_100 blue_bg_white_text"
                          onClick={() => props.setOpenWalletModalTab("deposit")}
                        >
                          {__("Get the Deal!")}
                        </Link>
                      ) : (
                        <Link
                          className="common_btn_v1 width_100 blue_bg_white_text"
                          onClick={() => {
                            props.setModalSignUpPropsData(true);
                            setTimeout(function () {
                              props.setModalSignUpPropsData(false);
                            }, 500);
                          }}
                        >
                          {__("Get the Deal!")}
                        </Link>
                      )}
                    </div>
                    <div className="more_links justify-content-center">
                      <Link className="tag" to="/bonus-terms">
                        {__("Terms")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item_dashed"></div>
            </div>
            <div className="item_right">
              <div className="paragraph">
                We are delighted to introduce another great way to pay back to
                our valued players – the Blizz $10K Raffle! Every month, we will
                give away prizes to 100 lucky players including a top prize of
                $2,500. All you need to do in order to enter is play as you
                normally would. For every $50 you deposit throughout the month,
                you will get 1 Raffle
              </div>
              <div className="more_links margin_top_25">
                {/* <Link className="more" to="#">
                  {__("Read more")}
                </Link> */}
              </div>
            </div>
          </div>

          <div className="monthly_raffle_bottom">
            <div className="bottom_title">Prize breakdowns are as follows:</div>
            <div className="tags">
              <Link>
                <span className="index">1st</span>
                <span className="value">$2,500</span>
              </Link>
              <Link>
                <span className="index">2nd </span>
                <span className="value">$1,700</span>
              </Link>
              <Link>
                <span className="index">3rd </span>
                <span className="value"> $1,200 </span>
              </Link>
              <Link>
                <span className="index">4th-10th </span>
                <span className="value">$200 </span>
              </Link>
              <Link>
                <span className="index">11th – 20th</span>
                <span className="value">$100</span>
              </Link>
              <Link>
                <span className="index">21st – 40th </span>
                <span className="value">$50 </span>
              </Link>
              <Link>
                <span className="index">41st – 100th </span>
                <span className="value">$20</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
