import React from "react";
import NoRecordsFoundImage from "assets/images/no-records-found.png";
import { __ } from "services/Translator";
export default function NoRecordsFound(props) {
  const content = props.content ? props.content : "Sorry, No records found";

  const contentStyle = props.relativePosition
    ? {
        fontSize: 17,
        textAlign: "center",
        color: "white",
      }
    : {
        fontSize: 17,
        top: "40%",
        left: "20%",
        width: "60%",
        textAlign: "center",
        position: "absolute",
        color: "white",
      };

  return (
    <div className="no_records_found" style={{ position: "relative" }}>
      <div
        style={{
          maxWidth: "500px",
          width: "100%",
          height: "auto",
          margin: "0 auto",
          display: "block",
          position: "relative",
        }}
      >
        {/* <img
          src={NoRecordsFoundImage}
          style={{
            width: "100%",
            height: "auto",
          }}
        /> */}
        <div style={contentStyle}>{content}</div>
      </div>
    </div>
  );
}
