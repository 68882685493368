import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useParams, useLocation } from "react-router-dom";
import api from "services/api";
import DOMPurify from "dompurify";
import CustomerReviews from "elements/CustomerReviews";
import { Helmet } from "react-helmet";
import { __ } from "services/Translator";

export default function CustomBonusTerms(props) {
  const isAuthenticated = props.isAuthenticated;
  const params = useParams();
  const [pageContent, setPageContent] = useState({});

  const location = useLocation();

  const pageTitle = __("Blizz Casino | All you need to know about the terms of accepting our Bonuses");

  const metaDescription = __(
    "Blizz Casino! Read this section before accepting our Bonuses as it explains everything you need to know about them!"
  );

  const getResults = async (promoId) => {
    var response = await api.get("/promotions");
    var promoId = params.promotion_id;
    if (response.status === 200) {
      for (var i in response.data.promotions) {
        var data = response.data.promotions[i];
        if (data.promotion_id == promoId) {
          setPageContent(data);
        }
      }
    } else {
      props.showAlert(null, response.data.message, true);
    }
  };

  useEffect(() => {
    getResults();
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <section className="about">
        <div className="container-md">
          <div className="terms_content">
            <div className="page_title">{__("Bonus Terms")}</div>

            <div
              className="paragraph white"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(pageContent.custom_terms),
              }}
            ></div>

            {/*<CustomerReviews
            variant="wide"
            isAuthenticated={props.isAuthenticated}
          />*/}
          </div>
        </div>
      </section>
    </>
  );
}
