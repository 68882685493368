import React, { useState, useEffect } from "react";
import ls from "../services/ls";
import api from "../services/api";
import { __ } from "services/Translator";

import { Modal, Button, Alert } from "react-bootstrap";

const GeoRestriction = (props) => {
  const isAuthenticated = props.isAuthenticated;

  const ping = async () => {
    var response = await api.get("/geo-ping");
    if (response.status === 403) {
      props.showAlert("Warning!!", response.data.message, true, () => {
        ls.set("geoexception", "User has accepted the exception");
      });
    }
  };

  useEffect(() => {
    ping();
  }, []);

  return <></>;
};

export default GeoRestriction;
