import React, { useState, useEffect, useRef } from "react";
import { Button, ProgressBar } from "react-bootstrap";
import LoadMore from "elements/LoadMore";
import GameItem from "elements/games_elements/GameItem";
import SearchBox from "elements/SearchBox";
import { Redirect, Route, Link, useParams, useHistory } from "react-router-dom";
import { Badge, Table, Modal, Collapse } from "react-bootstrap";
import api from "services/api";
import { __ } from "services/Translator";

export default function CategoryGames(props) {
  const params = useParams();
  const category = params.category;
  const history = useHistory();
  const providers = props.providers;
  const selectedProviders = props.selectedProviders;
  const setSelectedProviders = props.setSelectedProviders;

  const [providersModal, setProvidersModal] = useState(false);
  const closeAllModals = () => {
    setProvidersModal(false);
  };
  const toggleProviersModal = () => {
    setProvidersModal(!providersModal);
  };
  const toggleSelectedProvider = (providerId) => {
    var providersClone = JSON.parse(JSON.stringify(selectedProviders));
    if (providersClone.indexOf(providerId) === -1) {
      providersClone.push(providerId);
    } else {
      providersClone.splice(providersClone.indexOf(providerId), 1);
    }
    setSelectedProviders(providersClone);
    history.push("/games/all");
  };

  const clearAllProviders = () => {
    setSelectedProviders([]);
  };
  // const __ = (text) => text;

  const [searchStr, setSearchStr] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const searchTextBox = useRef(null);

  // expand searcbar
  const [expandSearchBar, setExpandSearchBar] = useState(true);

  const toggleExpandedSearchBar = (force) => {
    if (expandSearchBar) {
      setSearchSuggestions([]);
      if (force === true) {
        setSearchStr("");
        setExpandSearchBar(false);
      } else {
        if (searchStr.length === 0) {
          setExpandSearchBar(false);
        }
      }
    } else {
      setExpandSearchBar(true);
      searchTextBox.current.focus();
    }
  };

  const getSearchSuggestions = async () => {
    if (searchStr.length >= 2) {
      var response = await api.get("/games/search-suggestions", {
        query: searchStr,
        // providers: props.selectedProviders,
        providers: [],
        extensiveInfo: true,
      });
      if (response.status === 200) {
        setSearchSuggestions(response.data);
      }
    } else {
      setSearchSuggestions([]);
    }
  };

  useEffect(() => {
    getSearchSuggestions();
  }, [searchStr]);

  const doSearchByGameName = (text) => {
    props.setSearchStr(text);
    toggleExpandedSearchBar(true);
    props.setSelectedProviders([]);
    history.push("/games/search");
  };

  const bigIndex = 1;

  const [counter, setCounter] = useState(0);

  return (
    <>
      <div className={"container-md " + (props.className ? props.className : "")}>
        <div className="category_games_header">
          <div className="leftSide">{props.categoryName}</div>
          <div className="rightSide">
            <div className="gameLoadingInfo text-white">
              {__("You've viewed")} {props.gameLoaded} {__("of")} {props.totalGame} {__("games")}
            </div>
          </div>
        </div>

        <div className="game_list v1_format">
          {props.gameList.map((game, index) => (
            <>
              {[0, 6, 11].indexOf(index) !== -1 && props.calledFrom == "home" ? (
                <>
                  <GameItem
                    game={game}
                    isAuthenticated={props.isAuthenticated}
                    key={index}
                    className={"game_" + index + (" big_games " + "bg_" + index)}
                    gameType="custom"
                    index={index}
                    calledFrom={props.calledFrom}
                    isMobileDevice={props.isMobileDevice}
                    pageNum={props.pageNum}
                  />
                </>
              ) : (
                <>
                  <GameItem
                    game={game}
                    isAuthenticated={props.isAuthenticated}
                    key={index}
                    className={"game_" + index}
                    gameType="regular"
                  />
                </>
              )}
            </>
          ))}
        </div>

        {props.showPagination && (
          <>
            <LoadMore data={props.pagination} onPageChange={props.onPageChange} />

            <div className="gameLoadingProgress">
              <div className="gameLoadingInfo">
                {__("You've viewed")} {props.gameLoaded} {__("of")} {props.totalGame} {__("games")}
                {/* <div className="gameLoaded">{props.gameLoaded}</div>
                <div className="totalGame"> {props.totalGame}</div> */}
              </div>

              <ProgressBar variant="success" now={(props.gameLoaded / props.totalGame) * 100} />
            </div>
          </>
        )}
      </div>

      <Modal size="lg" show={providersModal} onHide={closeAllModals}>
        <Modal.Body className="providers_modal">
          <div onClick={closeAllModals} className="modal_close">
            <i className="fal fa-times"></i>
          </div>

          <div className="modal_headline">{__("Filter By Providers")}</div>
          <div className="providers_list">
            <Link
              onClick={clearAllProviders}
              className={"common_btn small " + (selectedProviders.length === 0 ? "active" : "inactive")}
            >
              {__("All Providers")}
            </Link>

            {providers.map((item, index) => (
              <Link
                onClick={() => toggleSelectedProvider(item.provider_id)}
                key={index}
                className={
                  "common_btn small " +
                  (item.name.length > 12 ? "smallText " : "") +
                  (selectedProviders.indexOf(item.provider_id) !== -1 ? "active" : "inactive")
                }
              >
                {item.name}
              </Link>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
